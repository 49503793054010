import React from 'react';
import { useTheme } from '@emotion/react';
import { Share } from 'tabler-icons-react';
import { Wrapper } from '@components/ShareButton/style';

const ShareButton = () => {
  const theme = useTheme();
  return (
    <Wrapper type="button" className="share-button">
      <Share stroke={theme.icons.primary.color} />
    </Wrapper>
  );
};

export default ShareButton;
