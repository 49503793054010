import styled from '@emotion/styled';
import { ContentWrapper } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled(ContentWrapper)`
  .date-range-picker {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -36px;
  }

  @media only screen and ${breakpoint.xl} {
    padding-top: 50px;
  }

  @media only screen and ${breakpoint.md} {
    padding-top: 20px;
  }
`;

export const ButtonWrapper = styled.section`
  display: flex;
  justify-content: center;
`;

export const NoResults = styled.section`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.font.default};
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  font-weight: ${({ theme }) => theme.defaults.fontWeight.light};
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;

  svg {
    display: block;
    margin-bottom: 16px;
    opacity: 0.5;
  }
`;
