import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import {
  Header,
  InnerContainer,
  Logo,
  PageContainer,
  StyledSubmit,
  SubmitContainer,
} from '@containers/Unsubscribe/styles';
import logo from '@app/assets/logo.png';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import Preloader, { POSITIONS } from '@components/Preloader';
import statraf from '@app/statrafApi';
import { STATRAF_ENDPOINT } from '@utils/statrafEvents';

const UnsubscribePage = () => {
  const {
    search: {
      type,
      token,
      object_id: objectId,
      type_label: typeLabel,
      object_name: objectName,
    }
  } = useCustomSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const unsubscribe = async () => {
    setIsLoading(true);
    let user = null;
    try {
      const response = await API.post(apiRoutes.unsubscribe, {
        type,
        token,
        objectId,
      });
      if(response?.data?.status === 'error' || response?.data?.errors?.length) {
        if (response?.data?.errors?.length) {
          toast.error(response.data.errors.join('.'));
        } else {
          toast.error('Something went wrong. Please try again later.');
        }
      } else {
        user = response.data.user;
        toast.success('You have successfully unsubscribed.');
      }
    } catch {
      toast.error('Something went wrong. Please try again later.');
    } finally {
      if(user) {
        try {
          await statraf.post(STATRAF_ENDPOINT, {
            user_id: user.id,
            user_email: user.email,
            object_id: objectId,
            path: location.pathname,
            location: window.location.href,
            action: 'unsubscribe',
            type,
          });
        } catch {
          /* eslint-disable no-console */
          console.error('Error sending event to Statraf');
        }
      }
      setIsLoading(false);
    }
  }

  const header = useMemo(() =>
    type === 'all' ?
      `You are now unsubscribing from all email notifications.` :
      `You are now unsubscribing from the ${typeLabel}:`,
    [type])

  return (
    <PageContainer>
      <InnerContainer>
        <Logo alt="logo" src={logo} />
        <Header>{header}</Header>
        <Header>{objectName}</Header>
        <SubmitContainer>
          <StyledSubmit style={{ width: '100%', height: 40 }} onClick={unsubscribe}>
            Confirm
          </StyledSubmit>
          {isLoading ? <Preloader display={POSITIONS.HORIZONRAL} /> : null}
        </SubmitContainer>
      </InnerContainer>
    </PageContainer>
  );
};

export default UnsubscribePage;