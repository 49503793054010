import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 30px 15px;
  width: 100%;
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.content};
  transition: background-color 0.3s;
  box-shadow: 0px -2px 8px 4px rgba(41, 35, 92, 0.03);
  border-radius: 3px;
  padding: 32px 112px 56px 112px;
  max-width: 640px;

  @media screen and ${breakpoint.lg} {
    padding: 24px 56px 28px 56px;
  }

  @media screen and ${breakpoint.md} {
    padding: 24px 18px 28px 18px;
  }
`;
export const TopSection = styled.div`
  margin-bottom: 30px;

  @media screen and ${breakpoint.md} {
    margin-bottom: 28px;
  }
`;
export const Form = styled.form`
  width: 100%;

  textarea {
    resize: none;
    height: 136px;
  }
`;
export const Header = styled.h2`
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  line-height: 22px;
  color: #080712;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.font.default};
`;
export const Text = styled.p`
  color: #080712;
  font-weight: 300;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.default};
`;
export const Button = styled.button`
  width: 100%;
  background: #6793ff;
  border-radius: 3px;
  text-transform: uppercase;
  border: none;
  color: #fff;
  height: 42px;
  cursor: pointer;
  margin-top: 10px;
`;
