import { authStore, userStore } from '@stores';
import statraf from '@app/statrafApi';

export const STATRAF_ENDPOINT = 'collect.json';
// const { NODE_ENV } = process.env;

export const statrafSendLocationEvent = (path) => {
  if (!authStore.isAuthenticated || !userStore.user) {
    return;
  }
  const { externalId, email } = userStore.user || {};
  statraf.post(STATRAF_ENDPOINT, {
    user_id: externalId,
    user_email: email,
    path,
    location: window.location.href,
  });
};