import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Mail as MailIcon } from 'tabler-icons-react';
import routes from '@routes';
import SubscriptionWrapper from '@components/SubscriptionWrapper';
import {
  MailTo,
  FooterText,
  Spacer,
  Text,
  Section,
  ConfirmButton,
  ButtonWrapper,
  Divider,
  Header,
} from '@containers/Settings/CancelSubscription/styles';

function SubscriptionInfo({
  settingsStore: { subscriptions, isLoading, cancelSubscription },
  userStore: { fetchUser },
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const subscription = useMemo(() => {
    if (id > 0) {
      return subscriptions[id - 1];
    }
    return undefined;
  }, [id, subscriptions]);

  if (!subscription && !isLoading.subscriptions) {
    navigate(routes.settings);
  }

  const handleUnsubscribe = async () => {
    await cancelSubscription();
    fetchUser();
    navigate(routes.settings);
  };

  return (
    <SubscriptionWrapper isLoading={isLoading.subscriptions}>
      <>
        <Section>
          <Header bigSize>Cancel Subscription</Header>
          <Text>
            You’ll still have full access until your current subscription
            period. You can reactivate it at any time.
          </Text>
        </Section>
        <Section>
          <Header>By cancelling your subscription</Header>
          <Text>We’ll stop any payments you have immediately.</Text>
          <Text>
            You’ll still have full access until your current subscription
            period...
          </Text>
          <Text>You can still read our free “UK Headlines” Briefing</Text>
        </Section>
        <ButtonWrapper>
          <ConfirmButton onClick={handleUnsubscribe}>
            Confirm Cancellation
          </ConfirmButton>
        </ButtonWrapper>

        <Text>If you also want to delete all your details please:</Text>
        <Divider />
        <Section>
          <MailTo href={routes.contact}>
            <Spacer>
              <FooterText>Contact us</FooterText>
              <MailIcon size={27} strokeWidth={1.5} />
            </Spacer>
          </MailTo>
        </Section>
      </>
    </SubscriptionWrapper>
  );
}

export default inject('settingsStore', 'userStore')(observer(SubscriptionInfo));
