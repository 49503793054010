export const addTargetProperty = (body) => {
  const bodyDiv = document.createElement('div');

  bodyDiv.innerHTML = body.trim();
  const anchors = bodyDiv.getElementsByTagName('a');
  for (let i = 0; i < anchors.length; i += 1) {
    anchors[i].setAttribute('target', '_blank');
  }

  return bodyDiv.innerHTML;
};
