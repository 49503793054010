import React, { useMemo } from 'react';
import {
  Bar,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  BarChart as Chart,
  ResponsiveContainer,
  Rectangle,
} from 'recharts';
import styled from '@emotion/styled';
import {
  aggregateData,
  dateTickFormatter,
  nonZeroKeys,
} from '@utils/chartHelpers';
import ChartTooltip from '@components/ChartTooltip';

const ChartContainer = styled.div`
  width: 100%;
  padding-bottom: 10px;
  height: 400px;
`;

const BarChart = ({
  isMobile,
  data,
  keys,
  dataKey,
  xAxisLabel,
  dateFormat,
  yAxisLabel,
  chartType,
  dateFormatTooltip,
  aggregateResults,
  useCompanyColor,
  maxBarWidth,
}) => {
  const filteredKeys = useMemo(
    () => nonZeroKeys({ keys, data }) || [],
    [keys, data],
  );

  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height={400}>
        <Chart
          margin={{
            left: isMobile ? -16 : 0,
          }}
          data={aggregateResults ? aggregateData(data) : data}
        >
          <CartesianGrid strokeDasharray="4 3" stroke="rgba(0,0,0,.1)" />
          <XAxis
            tick={{ fill: 'black' }}
            height={50}
            dataKey={dataKey}
            stroke="#aaaaaa"
            tickFormatter={(tick) =>
              dateTickFormatter(tick, dataKey, dateFormat)
            }
            label={{
              value: xAxisLabel,
              offset: 10,
              position: 'insideBottom',
              fill: '#aaaaaa',
            }}
          />
          <YAxis
            tick={{ fill: 'black' }}
            stroke="#aaaaaa"
            label={{
              value: yAxisLabel,
              angle: -90,
              offset: 10,
              position: 'center',
              fill: '#000000',
              dx: -20,
            }}
            allowDecimals={false}
            domain={[0, chartType === 'stacked100' ? 100 : 'auto']}
            allowDataOverflow
            type="number"
          />
          <Tooltip
            wrapperStyle={{ zIndex: 9 }}
            content={
              <ChartTooltip
                isPercent={chartType === 'stacked100'}
                dateFormat={dataKey === 'date' && dateFormatTooltip}
              />
            }
            cursor={{ fill: '#e0e0e0aa' }}
          />
          {(aggregateResults
            ? [{ name: 'Total', color: '#3C475A', companyColor: '#3C475A' }]
            : filteredKeys
          ).map((key) => {
            if (chartType.includes('stacked')) {
              return (
                <Bar
                  shape={(props) => {
                    const newProps = props;
                    const barWidth = maxBarWidth || 60;
                    if (newProps.width > barWidth) {
                      newProps.x += newProps.width / 2 - barWidth / 2;
                      newProps.width = barWidth;
                    }
                    return <Rectangle {...newProps} />;
                  }}
                  dataKey={key.name}
                  stackId="a"
                  fill={useCompanyColor ? key.companyColor : key.color}
                />
              );
            }
            return (
              <Bar
                key={`bar-chart-bar_${key.name}`}
                dataKey={key.name}
                fill={useCompanyColor ? key.companyColor : key.color}
                shape={(props) => {
                  const newProps = props;
                  if (newProps.width > maxBarWidth) {
                    newProps.x += newProps.width / 2 - maxBarWidth / 2;
                    newProps.width = maxBarWidth;
                  }
                  return <Rectangle {...newProps} />;
                }}
              />
            );
          })}
        </Chart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

BarChart.propTypes = {};

export default BarChart;
