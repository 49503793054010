import { runInAction, reaction, makeAutoObservable } from 'mobx';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';
import { authStore } from '@stores';
import { log, TYPE } from '@utils/logger';

export class NewsStore {
  initialized = false;

  isLoading = false;

  loadingMore = false;

  error = null;

  sections = [];

  links = {};

  showNewContentBanner = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => ({ isAuthenticated: authStore.isAuthenticated }),
      ({ isAuthenticated }) => {
        if (!isAuthenticated) {
          this.clear();
        }
      },
    );
  }

  setShowNewContentBanner = (value) => {
    this.showNewContentBanner = value;
  };

  checkForNewContent = async (type) => {
    try {
      const {
        data: { sections },
      } = await API.get(apiRoutes.myNews(type), {
        params: { limit: 1 },
      });

      const { publicationDate, hashtagId } = sections[0];

      const { publicationDate: publicationDateTop, hashtagId: hashtagIdTop } =
        this.sections[0];

      if (
        publicationDate !== publicationDateTop ||
        hashtagId !== hashtagIdTop
      ) {
        this.showNewContentBanner = true;
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
  };

  fetchNews = async (type = '', params = {}) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const { data } = await API.get(apiRoutes.myNews(type), { params });

      runInAction(() => {
        this.sections = data.sections;
        this.links = data.links;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.initialized = true;
      });
    }
  };

  mergeSection = (sections) => {
    const lastSectionIndex = this.sections.length - 1;
    const lastSection = this.sections[lastSectionIndex];
    const [firstNewSection] = sections;

    const newSections = [...this.sections];
    // if first new section should be included to the last of existing
    if (
      lastSection.sectionTitle === firstNewSection.sectionTitle &&
      lastSection.publicationDate === firstNewSection.publicationDate
    ) {
      const updatedLastSection = {
        ...lastSection,
        data: [...lastSection.data, ...firstNewSection.data],
      };

      newSections[newSections.length - 1] = updatedLastSection;
      sections.shift();
    }
    this.sections = [...newSections, ...sections];
  };

  loadMore = async () => {
    runInAction(() => {
      this.loadingMore = true;
    });

    try {
      const { data } = await API.get(this.links.next);
      runInAction(() => {
        this.links = data.links;
        this.mergeSection(data.sections);
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loadingMore = false;
      });
    }
  };

  searchNews = async (params) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const { data } = await API.get(apiRoutes.search, { params });

      runInAction(() => {
        this.sections = data.sections;
        this.links = data.links;
      });
    } catch (error) {
      this.error = error;
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.initialized = true;
      });
    }
  };

  clear = () => {
    log('@@@ clearing NewsStore', null, TYPE.INFO);
    this.news = [];
    this.sections = [];
    this.links = {};
    this.error = null;
    this.isLoading = false;
  };
}

export default new NewsStore();
