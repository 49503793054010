import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { WorldUpload as BaseWorldUpload } from 'tabler-icons-react';
import { breakpoint } from '@styles/breakpoints';

export const ContentWrapper = styled.div`
  padding: 56px 144px;
  position: relative;
  min-height: 100%;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1600px) {
    padding: 52px 32px;
  }

  @media screen and ${breakpoint.lg} {
    padding: 16px;
    padding-top: 60px;
  }

  @media screen and ${breakpoint.md} {
    padding: 0;
    height: 100vh;
    padding-bottom: 20px;
  }
`;

export const CompanyBanner = styled.div`
  margin-top: 100px;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  padding: 32px;
  margin-bottom: 56px;
  background-color: ${({ color }) => color};

  @media screen and ${breakpoint.lg} {
    background-color: ${({ color }) => color};
    padding: 0;
  }

  ${({ color }) =>
    color
      ? css`
          background-color: ${color};
        `
      : null}

  @media screen and ${breakpoint.lg} {
    margin-top: 140px;
  }

  @media screen and ${breakpoint.md} {
    margin-top: 80px;
  }
`;

export const TopSection = styled.div`
  display: flex;
  padding-bottom: ${({ sections }) => sections?.length && 32}px;

  @media screen and ${breakpoint.lg} {
    display: block;
    padding: 32px;
  }

  @media screen and ${breakpoint.lg} {
    padding: 32px 16px 24px 16px;
  }
`;
export const Info = styled.div`
  flex: 1;
  padding-right: 15px;

  img {
    height: max-content;
    max-width: 450px;
    max-height: 84px;
    padding: 5px;
  }

  @media screen and ${breakpoint.xl} {
    img {
      max-width: 250px;
      max-height: 50px;
    }
  }

  @media screen and ${breakpoint.lg} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    img {
      max-width: 170px;
      max-height: 45px;
    }
  }

  @media screen and ${breakpoint.md} {
    img {
      max-width: 125px;
      max-height: 30px;
    }
  }
`;
export const Name = styled.p`
  font-weight: ${({ theme }) => theme.defaults.fontWeight.regular};
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  line-height: 22px;
  margin-right: 10px;

  @media screen and ${breakpoint.lg} {
    margin-top: 0;
  }
`;
export const Notifications = styled.div`
  border-left: ${({ theme }) => `1px solid ${theme.colors.white}`};
  padding: 0 36px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;

  @media screen and ${breakpoint.xl} {
    padding: 0 0 0 36px;
  }

  @media screen and ${breakpoint.lg} {
    border-left: none;
    border-top: ${({ theme }) => `1px solid ${theme.colors.white}`};
    padding: 0;
    padding-top: 30px;
  }

  @media screen and ${breakpoint.xs} {
    display: none;
  }
`;

export const Notification = styled.div`
  display: flex;
  align-items: center;
  padding-right: 50px;

  .custom-switch {
    flex-direction: row-reverse;
    width: 100%;

    label {
      color: ${({ theme }) => theme.colors.white};
      font-weight: ${({ theme }) => theme.defaults.fontWeight.bold};
      font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
      line-height: 18px;
      margin-left: 28px;
      flex: 1;
    }
  }

  @media screen and ${breakpoint.md} {
    padding-right: 0;
  }

  @media screen and ${breakpoint.xs} {
    flex-direction: column;
    justify-content: space-between;
    min-width: 80px;

    ${({ active }) =>
      active
        ? css`
            svg {
              // filter: invert(1);
              fill: black;
              background-color: white;
            }
          `
        : null}
  }
`;

export const Sections = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.colors.white}`};
  padding-top: 32px;
  display: flex;
  grid-column-gap: 32px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    height: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scroll.default};
  }

  @media screen and ${breakpoint.lg} {
    border-top: none;
    background-color: ${({ theme }) => theme.colors.content};
    transition: background-color 0.3s;
    padding-top: 0;
    padding: 8px 20px;
    grid-column-gap: 0px;
    box-shadow: 0px -2px 8px 4px rgba(41, 35, 92, 0.03);
  }
`;

export const Section = styled.button`
  background-color: transparent;
  border: ${({ theme }) => `1px solid ${theme.colors.white}`};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  padding: 8px 32px;
  font-weight: ${({ theme }) => theme.defaults.fontWeight.bold};
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  line-height: 18px;
  transition: all 0.3s;
  white-space: pre;

  ${({ active, theme }) =>
    active
      ? css`
          background-color: ${theme.colors.white};
          color: #000;
        `
      : null};

  @media screen and ${breakpoint.lg} {
    color: black;
    padding: 8px 12px;
    font-weight: ${({ theme }) => theme.defaults.fontWeight.regular};
    background-color: ${({ theme }) => theme.colors.content};
    border-color: ${({ theme }) => theme.colors.content};
    color: ${({ theme }) => theme.font.default};
    font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
    line-height: 22px;

    ${({ active, theme }) =>
      active
        ? css`
            &:after {
              content: '';
              display: block;
              background-color: ${theme.font.default};
              width: 100%;
              height: 2px;
            }
          `
        : null};
  }
`;

export const NewsContainer = styled.div`
  @media screen and ${breakpoint.md} {
    padding: 0 15px;
  }
`;
export const MobileNotifications = styled.div`
  display: none;
  text-align: center;
  text-transform: lowercase;
  border-top: ${({ theme }) => `1px solid ${theme.colors.white}`};
  padding-top: 20px;
  justify-content: space-evenly;

  svg {
    border: ${({ theme }) => `1px solid ${theme.colors.white}`};
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 4px;
  }

  & div:first-of-type {
    svg {
      border: none;
      width: 26px;
      height: 26px;
      padding: 0;
      transform: scale(1.2);
      margin-top: 2px;
    }
  }

  @media screen and ${breakpoint.xs} {
    display: flex;
  }
`;

export const CompanyNameContainer = styled.a`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.defaults.fontWeight.bold};
  font-size: 10px;
  line-height: 18px;
  max-width: max-content;
  max-height: 25px;
  margin-top: 32px;

  @media screen and ${breakpoint.md} {
    max-height: unset;
    margin-top: 8px;
    max-width: 100px;
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.defaults.fontWeight.bold};
  font-size: 10px;
  line-height: 18px;
  max-width: 75px;
  margin-top: 10px;

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const NoRecentPublications = styled.p`
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  line-height: 22px;
  font-weight: 400;
  color: ${({ theme }) => theme.font.default};
`;

export const SearchContainer = styled.div`
  background-color: ${({ theme }) => theme.control.background};
  position: fixed;
  display: flex;
  top: 52px;
  left: 0px;
  width: 100%;
  transition: top 0.4s;
  padding: 10px;
  z-index: 99;
  flex-wrap: wrap;

  .date-range-picker {
    width: unset;
    display: flex;
  }

  ${({ searchBarHidden }) =>
    searchBarHidden === true
      ? css`
          top: -50px;
        `
      : css`
          top: 56px;
        `}

  ${({ isCalendarExpended }) =>
    isCalendarExpended === true
      ? css`
          padding-bottom: 45px;

          .date-range-picker {
            margin-top: 10px;
            width: 100%;
            justify-content: center;
          }
        `
      : null}
`;

export const PreloaderWrapper = styled.div`
  padding: 56px 0;
  position: relative;
  min-height: 100%;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.mainBackground};

  .preloader {
    background-color: ${({ theme }) => theme.colors.mainBackground};
  }

  img {
    height: 100%;
  }
`;

export const LoadMoreSection = styled.section`
  display: flex;
  justify-content: center;
`;

export const WorldUpload = styled(BaseWorldUpload)`
  margin: auto 0;
  @media screen and ${breakpoint.md} {
    min-width: max-content;
  }
`;
