import React from 'react';
import PropTypes from 'prop-types';
import {
  AvailableCompaniesList,
  CompaniesList,
} from '@components/CompaniesDialog/styles';
import CompanyButton from '@components/CompaniesDialog/components/CompanyButton';

const RawCompaniesList = ({ companies }) => (
  <AvailableCompaniesList className="raw">
    <CompaniesList>
      {companies.map(({ id, name, color }) => (
        <CompanyButton
          key={`company-button-${id}`}
          id={id}
          label={name}
          companyColor={color}
        />
      ))}
    </CompaniesList>
  </AvailableCompaniesList>
);

RawCompaniesList.propTypes = {
  companies: PropTypes.array.isRequired,
};

export default RawCompaniesList;
