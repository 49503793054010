import styled from '@emotion/styled';
import { Checks, X } from 'tabler-icons-react';
import { css } from '@emotion/react';
import { Scrollbars } from 'react-custom-scrollbars';
import { breakpoint } from '@styles/breakpoints';

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 243px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 32px;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  height: calc(90vh - 120px);

  @media screen and ${breakpoint.md} {
    height: calc(${({ height }) => height}px - 130px);
    overflow-y: auto;
    &.filters {
      height: calc(${({ height }) => height}px - 190px);
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  max-width: 277px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  text-transform: ${({ noUppercase }) => (noUppercase ? 'none' : 'uppercase')};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  height: 40px;
  cursor: pointer;

  &.save {
    background: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      background-color: rgba(103, 147, 255, 0.9);
    }
  }

  &.border {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.secondary};

    &:hover {
      background-color: rgba(103, 147, 255, 0.05);
    }
  }

  &.small {
    width: max-content;
  }

  &.padding {
    padding: 10px;
    @media screen and ${breakpoint.sm} {
      padding: 1px 6px;
    }
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
`;
export const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SelectedCompaniesHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  margin: 32px 0;
`;

export const SelectedCompaniesLabel = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.defaults.fontSizeXXL}px;
  line-height: 40px;
  font-weight: 700;
`;

export const ClearAllCompaniesButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: max-content;
  gap: 7px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.secondary : theme.colors.inputLabel};
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  padding: 0 10px;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  margin-left: 17px;
  font-weight: 400;
  visibility: visible;
  transition: visible 0.3s ease-in-out;

  &.collapse {
    visibility: collapse;
    opacity: 0;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media screen and ${breakpoint.md} {
    width: max-content;
    margin-left: 0;
  }
`;

export const ClearIcon = styled(X)`
  margin: auto 0;
`;

export const CheckIcon = styled(Checks)`
  margin: auto 0;
`;

export const CompaniesList = styled.div`
  display: flex;
  width: 95%;
  gap: 16px;
  flex-wrap: wrap;
  visibility: visible;
  margin-top: 10px;
  transition: all 0.1s ease-in-out;
  opacity: 1;
  margin-left: 10px;

  &.collapse {
    visibility: collapse;
    opacity: 0;
    height: 0;
    margin-top: 0px;
  }

  &.margin-bottom {
    margin-bottom: 16px;
  }

  &.no-left-margin {
    margin-left: 0;
  }
`;

export const StyledCompanyButton = styled.div`
  display: flex;
  overflow-y: hidden;
  justify-content: space-between;
  background: ${({ theme, companyColor, selected }) =>
    selected ? companyColor : theme.colors.white};
  border: 1px solid ${({ companyColor }) => companyColor};
  border-radius: 9px;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.tertiary};
  height: 34px;
  cursor: pointer;
  line-height: 34px;
  padding: 0 12px;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  transition: box-shadow 0.3s ease-in-out;
  user-select: none;
  touch-action: none;

  &:hover {
    box-shadow: 0 0 5px ${({ companyColor }) => companyColor};
  }

  &.selected-list {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.tertiary};
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`;

export const ClearIconCompany = styled(X)`
  margin: auto 0 auto 15px;
  color: ${({ theme }) => theme.colors.white};
`;

export const AvailableCompaniesList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 30px;

  &.raw {
    border-top: 1px solid #e1e9ff;
    margin-top: 32px;
  }
`;

export const CompaniesGroupContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 16px;
  border-top: 1px solid #e1e9ff;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out;
  padding-left: 10px;
  &.hover {
    &:hover {
      background-color: rgba(103, 147, 255, 0.05);
    }
  }
`;

export const CompaniesGroupHeader = styled.div`
  display: flex;
  box-sizing: content-box;
  //margin-bottom: 14px;
  padding-top: 16px;
  height: 40px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  user-select: none;
  cursor: pointer;
`;

export const GroupLabel = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and ${breakpoint.md} {
    max-width: 250px;
  }

  @media screen and ${breakpoint.xs} {
    font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  }
`;

export const CollapseIcon = styled.div`
  margin-left: auto;
  display: inline-flex;
  padding-top: 7px;
  color: ${({ theme }) => theme.colors.primary};
  svg {
    transition: all 0.3s ease-in-out;
  }

  ${({ isExpanded }) =>
    isExpanded
      ? css`
          svg {
            transform: rotate(180deg);
          }
        `
      : null};
`;

export const GroupContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 40px;
  &.show {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  &.hide {
    box-sizing: border-box;
    overflow: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    margin-left: -16px;
    margin-top: -16px;
  }

  @media screen and ${breakpoint.md} {
    justify-content: space-between;
    &.reverse {
      flex-direction: row-reverse;
    }
  }
`;

// mobile styles

export const MobileHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 10px;

  &.collapse {
    display: none;
  }
`;

export const MobileButtonsContainer = styled.div`
  display: flex;
  height: 40px;
  gap: 10px;
  justify-content: space-between;
`;

export const MobileIconButton = styled.button`
  width: max-content;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  height: 40px;
  cursor: pointer;
  &:hover {
    background-color: rgba(103, 147, 255, 0.05);
  }
  &.right {
    margin-left: auto;
  }

  &.no-border {
    border: none;
  }
`;

export const DropdownsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const ScrollableContent = styled(Scrollbars)`
  div {
    overflow-x: hidden !important;
  }
`;
