import React, { useMemo, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { inject, observer } from 'mobx-react';
import UnsubscribePage from '@containers/Unsubscribe';
import Trial from '@containers/Trial';
import Briefings from '@containers/Briefings';
import ContactUs from '@containers/ContactUs';
import MyNews from '@containers/MyNews';
import Settings from '@containers/Settings';
import SignInPage from '@containers/SignIn';
import MagicLinkPage from '@containers/MagicLink';
import Logout from '@containers/Logout';
import Company from '@containers/Company';
import NotFound from '@containers/NotFound';
import ForgotPassword from '@containers/ForgotPassword';
import Companies from '@containers/Companies';
import Search from '@containers/Search';
import ResetPasswordPage from '@containers/ResetPassword';

import ProtectedRoute from '@components/ProtectedRoute';
import UnauthenticatedRoute from '@components/UnauthenticatedRoute';
import Layout from '@components/Layout';
import SearchBar from '@components/SearchBar';

import GlobalStyle from '@styles/globalStyles';
import ThemesProvider from '@styles/theme';
import routes from '@routes';
import Dashboard from '@containers/Dashboard';
import DashboardSettings from '@components/DashboardSettings';
import CompaniesDialog from '@components/CompaniesDialog';
import Article from '@containers/Article';
import SubscriptionInfo from '@containers/Settings/SubscriptionInfo';
import CancelSubscription from '@containers/Settings/CancelSubscription';
import PaymentMethod from '@containers/PaymentMethod';
import { removeIntercomCookies } from '@utils/cookies';
import CookieBanner from '@components/CookieBanner';
import Analytics from '@components/Analytics';

moment.updateLocale('en', {
  relativeTime: {
    ss: '%ds',
    mm: '%dm',
    hh: '%dh',
    dd: '%dd',
  },
});

const App = ({
  userStore: { isDashboardEnabled },
  companyStore: { company },
}) => {
  const { pathname } = useLocation();

  const placeholderForMyNews = useMemo(() => {
    switch (pathname) {
      default:
        return 'Search My News';
      case '/my-news/following':
        return 'Search My News';
      case '/my-news/briefings':
        return 'Search My Briefings';
      case '/my-news/coverages':
        return 'Search My Coverage';
    }
  }, [pathname]);

  useEffect(() => {
    removeIntercomCookies();
  }, []);

  return (
    <ThemesProvider>
      <GlobalStyle />
      <CookieBanner />
      <Analytics />
      <>
        <Routes>
          <Route
            exact
            path={routes.auth.signIn}
            element={
              <UnauthenticatedRoute>
                <SignInPage />
              </UnauthenticatedRoute>
            }
          />
          <Route
            exact
            path={routes.auth.magicLink}
            element={
              <UnauthenticatedRoute>
                <MagicLinkPage />
              </UnauthenticatedRoute>
            }
          />
          <Route
            exact
            path={routes.myNews()}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel="My News"
                  navBarleftSide={
                    <SearchBar
                      placeholder={placeholderForMyNews}
                      extendedSearch
                    />
                  }
                >
                  <MyNews />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.search}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel="My News"
                  navBarleftSide={
                    <SearchBar placeholder="Search My News" extendedSearch />
                  }
                >
                  <Search />
                </Layout>
              </ProtectedRoute>
            }
          />
          {isDashboardEnabled ? (
            <Route
              exact
              path={routes.dashboard}
              element={
                <ProtectedRoute>
                  <Layout
                    mobileLabel="Dashboard"
                    navBarleftSide={<DashboardSettings />}
                  >
                    <Dashboard />
                  </Layout>
                </ProtectedRoute>
              }
            />
          ) : null}
          <Route
            exact
            path={routes.settings}
            element={
              <ProtectedRoute>
                <Layout mobileLabel="Settings">
                  <Settings />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.singleCompany()}
            exact
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel="Company"
                  navBarleftSide={
                    <SearchBar
                      placeholder={
                        company.name === undefined
                          ? 'Search'
                          : `Search ${company.name}`
                      }
                      extendedSearch
                    />
                  }
                >
                  <Company />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.contact}
            exact
            element={
              <ProtectedRoute>
                <Layout mobileLabel="Contact us">
                  <ContactUs />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.article()}
            exact
            element={
              <ProtectedRoute>
                <Layout mobileLabel="Contact us">
                  <Article />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.auth.logout}
            exact
            element={
              <ProtectedRoute>
                <Logout />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.trial}
            element={
              <ProtectedRoute>
                <Layout mobileLabel="Trial" navBarleftSide={null}>
                  <Trial />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.company()}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel="Companies"
                  navBarleftSide={
                    <SearchBar placeholder="Search Companies" withoutButton />
                  }
                >
                  <Companies />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.briefing()}
            element={
              <ProtectedRoute>
                <Layout
                  mobileLabel="Briefings"
                  navBarleftSide={
                    !pathname.includes('/briefings/all') ? (
                      <SearchBar
                        placeholder="Search all Briefings"
                        extendedSearch
                      />
                    ) : null
                  }
                >
                  <Briefings />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.subscriptionInfo()}
            element={
              <ProtectedRoute>
                <Layout mobileLabel="Subscription info">
                  <SubscriptionInfo />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.cancelSubscription()}
            element={
              <ProtectedRoute>
                <Layout mobileLabel="Cancel subscription">
                  <CancelSubscription />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <Layout mobileLabel="404 Not found">
                  <NotFound />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.auth.forgotPassword}
            element={<ForgotPassword action="password-reset" />}
          />
          <Route path={routes.secureLinkLogin} element={<ForgotPassword />} />
          <Route
            path={routes.auth.resetPassword}
            exact
            element={<ResetPasswordPage />}
          />
          <Route
            path={routes.auth.setPassword}
            element={<ResetPasswordPage action="accept-invitation" />}
          />
          <Route
            exact
            path={routes.paymentMethod}
            element={
              <ProtectedRoute>
                <Layout mobileLabel="Add or remove payment methods">
                  <PaymentMethod />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={routes.deleteCard}
            element={<Navigate to={routes.paymentMethod} />}
          />
          <Route
            exact
            path={routes.updateCard}
            element={<Navigate to={routes.paymentMethod} />}
          />
          <Route
            path={routes.unsubscribe}
            element={<UnsubscribePage />}
          />
          <Route path="*" element={<div>404 Not found</div>} />
        </Routes>
        {pathname.includes(routes.dashboard) ? (
          <Routes>
            <Route
              path={routes.dashboardDialog}
              element={<CompaniesDialog />}
            />
          </Routes>
        ) : null}
        <ToastContainer
          autoClose={8000}
          position="bottom-center"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
        />
      </>
    </ThemesProvider>
  );
};

export default inject('userStore', 'companyStore')(observer(App));
