const lightModeTheme = {
  colors: {
    primary: '#29235C',
    secondary: '#6793FF',
    tertiary: '#544F7D',
    quaternary: '#5276CC',
    quinary: '#ecebed',
    mainBackground: '#fafbfc',
    navbar: '#FFFFFF',
    sidebar: '#29235C',
    disabled: '#C5C2C8',
    white: '#FFFFFF',
    content: '#ffffff',
    company: '#fafbfc',
    companyButton: '#ffffff',
    companyButtonHover: '#eeeeee',
    inputLabel: '#00202d',
    error: '#E5005C',
    400: '#544F7D',
    900: '#080712',
    follow: '#6793FF',
    unfollow: '#E5005C',
  },
  font: {
    default: '#29235C',
    secondary: '#544F7D',
    tertiary: '#5276CC',
    dark: '#080712',
  },

  control: {
    background: '#ffffff',
    center: '#6793FF',
  },
  opacity: {
    preloader: 0.8,
  },
  scroll: {
    default: '#29235C',
    secondary: '#ffffff',
  },
  border: {
    default: '#e1e9ff',
    dark: '#080712',
  },
  imageFilter: {
    invert: 1,
    preloaderBrightness: 0,
  },
  buttons: {
    primary: {
      background: '#6793FF',
      textColor: '#FFFFFF',
      borderColor: '#6793FF',
      hover: '#85a5f2',
    },
    secondary: {
      background: '#FFFFFF',
      textColor: '#6793FF',
      borderColor: '#6793FF',
      hover: '#85a5f2',
    },
  },
  icons: {
    default: '#29235C',
    hover: '#eeeeee',
    primary: {
      color: '#6793FF',
    },
  },
  inputs: {
    secondary: {
      default: '#FFFFFF',
      background: '#F0F4FF',
      borderColor: '#F0F4FF',
      placeholder: '#7F7B9D',
      icon: '#6793FF',
    },
  },
  datepicker: {
    dayHover: '#6793FFb8',
    dayHoverText: '#FFFFFF',
    active: '#6793FF',
    selected: '#6793FF',
  },
  box: {
    default: {
      background: '#FFFFFF',
    },
  },
};

export default lightModeTheme;
