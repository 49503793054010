import { runInAction, makeAutoObservable } from 'mobx';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';

export class CompaniesStore {
  isInitialLoad = true;

  fetchPending = false;

  error = null;

  companies = [];

  counts = {};

  companiesGroups = {};

  filtered = [];

  companiesQuery = '';

  processedCompanies = [];

  targetType = 'all';

  constructor() {
    makeAutoObservable(this);
  }

  fetchCompanies = async (type, companyId, fetchOnly = false) => {
    this.fetchPending = true;

    if (type === null) {
      type = this.targetType;
    } else {
      this.targetType = type;
    }

    // checking if user fetched before clicked sector
    try {
      if (
        Object.keys(this.companiesGroups).length === 0 ||
        !this.companiesGroups[type]
      ) {
        let route;

        switch (type) {
          case 'all': {
            route = apiRoutes.companies;
            break;
          }
          case 'following': {
            route = apiRoutes.followedCompanies;
            break;
          }
          default: {
            route = apiRoutes.sector(type);
            break;
          }
        }

        const { data } = await API.get(route);

        this.companiesGroups[type] = {
          ...data,
        };

        if (type !== this.targetType) {
          return;
        }
      }

      runInAction(() => {
        if (fetchOnly) {
          return;
        }
        if (type === 'all') {
          this.companies = this.companiesGroups[type].companies.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
        } else {
          this.companies = this.companiesGroups[type].companies;
        }
        this.counts = this.companiesGroups[type].counts;
        this.filterCompanies(this.companiesQuery);
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.isInitialLoad = false;
        this.fetchPending = false;
        this.processedCompanies = this.processedCompanies.filter(
          (x) => x !== companyId,
        );
      });
    }
  };

  handleFollow = async (id, value) => {
    // updating states
    this.companies = this.companies.map((x) =>
      x.id === id ? { ...x, follow: !x.follow } : x,
    );

    this.counts = value
      ? { ...this.counts, followed: this.counts.followed - 1 }
      : { ...this.counts, followed: this.counts.followed + 1 };
    this.processedCompanies.push(id);
    await API.put(apiRoutes.updateSingleNotification(id), { follow: !value });
    this.companiesGroups = {};
    this.fetchCompanies(null, id);
  };

  filterCompanies = (query) => {
    const searchThrough = "all";

    runInAction(async () => {
      this.companiesQuery = query;
      if (query === '') {
        this.filtered = [];
      } else {
        if (this.targetType !== 'all' || !this.companiesGroups[searchThrough]) {
          await this.fetchCompanies('all', undefined, true);
        }
        this.filtered = this.companiesGroups[searchThrough].companies.filter((x) =>
          x.name
            .toLowerCase()
            .replace(/\s/g, '')
            .replace(/\./g, '')
            .replace("'", '')
            .replace('-', '')
            .includes(
              query
                .toLowerCase()
                .replace(/\s/g, '')
                .replace(/\./g, '')
                .replace("'", ''),
            ),
        );
      }
    });
  };
}

export default new CompaniesStore();
