export default {
  signIn: '/auth/sign_in',
  magicLink: '/auth/sign_in_using_magic_link',
  sectors: '/v2/companies/sectors',
  briefing: (id) => `contents/${id}`,
  briefingTypes: '/v2/briefings/types',
  myNews: (type = '') => `/my-news/${type}`,
  search: `/v2/search`,
  userInfo: '/user/info',
  followingCompanies: '/dashboard/companies',
  changePassword: '/auth/change_password',
  following: '/v2/hashtags/followed',
  companies: '/v2/companies/all?limit=1000',
  followedCompanies: '/v2/companies/followed?limit=1000',
  sector: (id) => `/v2/companies/sectors/${id}?limit=1000`,
  me: '/user/info',
  updateMe: '/user/update',
  updateSingleNotification: (id) => `/hashtags/${id}`, // single briefing follow too
  contactUs: '/contact/contact_us',
  singleCompany: (id) => `/hashtags/${id}`,
  companySections: (id) => `/v2/companies/${id}/sections`,
  companyNews: (id) => `contents/for_children/${id}`,
  specificSection: (companyId, sectionId) =>
    `v2/companies/${companyId}/sections/${sectionId}/publications`,
  article: (id) => `articles?contentId=${id}`,
  forgotPassword: '/auth/send_magic_link',
  dashboard: {
    companies: 'dashboard/companies',
    plots: {
      totalCoverage: 'dashboard/plots/publications-by-companies',
      topSources: 'dashboard/plots/publications-by-sentiment-for-newspapers',
      topJournalists: 'dashboard/plots/top-authors',
      sentimentAnalysis:
        'dashboard/plots/publications-by-sentiment-for-companies',
    },
    fullReport: 'reports/content_publications_report',
  },
  resetPassword: '/auth/reset_password',
  changePasswordUsingToken: '/auth/change_password_using_token',
  setPasswordUsingInvitation: '/auth/set_password_using_invitation',
  paymentCards: '/stripe/cards',
  cancelStripeSubscription: 'stripe/unsubscribe',
  logout: '/auth/sign_out',
  stripeCard: (id) => `/stripe/cards/${id}`,
  stripeCards: '/stripe/cards',
  stripeCardDefault: (id) => `/stripe/cards/default/${id}`,
  unsubscribe: '/v2/unsubscribe',
};
