import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Logout, Settings, User } from 'tabler-icons-react';
import userIcon from '@assets/images/icons/user.svg';
import useBreakpoint from '@root/hooks/useBreakpoint';
import {
  Dropdown,
  Menu,
  MenuItem,
  FullName,
  Icon,
  UserIconContainer,
  UserIcon,
} from '@components/UserMenu/style';
import routes from '@routes';
import DarkModeSwitch from '@components/DarkModeSwitch';

const UserMenu = ({
  fullName,
  allowToHide,
  lastName,
  firstName,
  userEmail,
  isDashboard,
  sidebarStore: { setMobileMenuOpened },
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const theme = useTheme();

  const MENU_ITEMS = useMemo(
    () => [
      {
        label: 'Settings',
        path: routes.settings,
        icon: (
          <Settings strokeWidth={1} size={24} color={theme.icons.default} />
        ),
      },
      {
        label: 'Logout',
        path: routes.auth.logout,
        icon: <Logout strokeWidth={1} size={24} color={theme.icons.default} />,
      },
    ],
    [theme],
  );

  const onClickHandler = useCallback(
    (ev) => {
      ev.stopPropagation();
      if (isMobile) setMobileMenuOpened(false);
      setIsOpened(!isOpened);
    },
    [isOpened],
  );

  const closeMenu = useCallback(() => {
    setIsOpened(false);
  }, []);

  useEffect(() => {
    window.addEventListener('click', closeMenu);

    return () => {
      window.removeEventListener('click', closeMenu);
    };
  }, []);

  const renderIcon = () => {
    let name = '';
    if (firstName?.length && lastName?.length) {
      name =
        firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    }

    if (firstName?.length && !lastName?.length) {
      name = firstName.charAt(0).toUpperCase();
    }

    if (!firstName?.length && lastName?.length) {
      name = lastName.charAt(0).toUpperCase();
    }

    if (isMobile) {
      return (
        <UserIconContainer className="user-menu">
          <UserIcon>
            <User size={16} />
          </UserIcon>
        </UserIconContainer>
      );
    }

    if (firstName?.length || lastName?.length) {
      return (
        <Icon>
          <p>{name}</p>
        </Icon>
      );
    }

    return <img src={userIcon} alt="" />;
  };

  return (
    <Dropdown
      type="button"
      onClick={onClickHandler}
      isOpened={isOpened}
      allowToHide={allowToHide}
      className={isDashboard ? 'dashboard' : 'user-menu'}
    >
      {renderIcon()}

      <Menu>
        <FullName>
          {!firstName?.length && !lastName?.length ? userEmail : fullName}
        </FullName>
        {isMobile && (
          <MenuItem>
            <DarkModeSwitch offColor="#ffffff" />
            <p>Dark Mode</p>
          </MenuItem>
        )}
        {MENU_ITEMS.map((item) => (
          <MenuItem key={`user-menu_${item.label}`}>
            <Link to={item.path}>
              {item.icon}
              {item.label}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
};

UserMenu.propTypes = {
  fullName: PropTypes.string.isRequired,
};

export default inject('sidebarStore')(observer(UserMenu));
