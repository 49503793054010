import React from 'react';
import {
  Container,
  CircleSvg,
  LegendContainer,
  List,
  ListItem,
  InfoCircleIcon,
} from '@components/CustomChartLegend/style';
import { UNDISCLOSED_TOOLTIP } from '@root/constants/dashboard';
import { StyledTooltip } from '@containers/Dashboard/styles';

const CustomChartLegend = ({ showSentiment, payload, tooltipId }) => (
  <Container>
    <LegendContainer className={showSentiment ? null : 'hidden'}>
      <List>
        {payload.map((data, index) => (
          <ListItem key={`custom-legent-list-item_${index}`}>
            <CircleSvg width="14" height="14" viewBox="0 0 32 32" version="1.1">
              <circle cx="12" cy="12" r="12" stroke="none" fill={data.color} />
            </CircleSvg>
            <span>{data.value}</span>
            {data.value === 'Undisclosed' ? (
              <div
                style={{ display: 'inline-block' }}
                id={tooltipId || 'undisclosed-tooltip'}
              >
                <StyledTooltip />
                <InfoCircleIcon
                  data-tip={UNDISCLOSED_TOOLTIP}
                  data-place="bottom"
                  size={18}
                />
              </div>
            ) : null}
          </ListItem>
        ))}
      </List>
    </LegendContainer>
  </Container>
);

export default CustomChartLegend;
