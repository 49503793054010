import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';

import Input, { INPUT_TYPES } from '@components/Input';

import {
  SectionHeader,
  SectionContainer,
  Form,
  EmailWrapper,
  Label,
  Email,
  Button,
} from '@containers/Settings/styles';

const PersonalData = ({
  userStore: { user },
  sidebarStore: { menuCollapsed },
}) => {
  const updateUser = async (data) => {
    await API.post(apiRoutes.updateMe, data)
      .then(() => {
        toast.success('Data successfully changed!');
      })
      .catch(() => {
        toast.error('Something went wrong!');
      });

    reset(data);
  };

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      title: user.title || '',
      company: user.company || '',
    },
  });

  const onSubmit = (data) => {
    updateUser(data);
  };

  useEffect(() => {
    reset({
      firstName: user.firstName,
      lastName: user.lastName,
      title: user.title,
      company: user.company,
    });
  }, [user]);

  return (
    <SectionContainer>
      <SectionHeader>Personal data</SectionHeader>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="firstName"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              inputType={INPUT_TYPES.TEXT}
              onChange={onChange}
              value={value}
              label="First Name"
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              inputType={INPUT_TYPES.TEXT}
              onChange={onChange}
              value={value}
              label="Last Name"
            />
          )}
        />
        <EmailWrapper>
          <Label>Email</Label>
          <Email>{user.email}</Email>
        </EmailWrapper>
        <Controller
          name="title"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              inputType={INPUT_TYPES.TEXT}
              onChange={onChange}
              value={value}
              label="Job Title"
            />
          )}
        />
        <Controller
          name="company"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              inputType={INPUT_TYPES.TEXT}
              onChange={onChange}
              value={value}
              label="Company"
            />
          )}
        />

        <Button disabled={!isDirty} menuCollapsed={menuCollapsed} type="submit">
          save changes
        </Button>
      </Form>
    </SectionContainer>
  );
};

export default inject('userStore', 'sidebarStore')(observer(PersonalData));
