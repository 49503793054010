import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Content, Header, Wrapper } from '@components/ContentBox/style';
import Text, { TAGS } from '@components/Text';

const ContentBox = ({ title, children, badge, alt }) => (
  <Wrapper badge={badge}>
    <Header>
      <ReactTooltip />
      <span data-tip={alt} data-place="top">
        <Text tag={TAGS.H2}>{title}</Text>
      </span>
    </Header>
    <Content>{children}</Content>
  </Wrapper>
);

ContentBox.propTypes = {
  badge: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

ContentBox.defaultProps = {
  badge: null,
};

export default ContentBox;
