export const generateAlphabetWithIds = (collection, labelKey, idKey) => {
  const numbers = [...Array(10).keys()].map((e) => `${e}`);
  return collection.reduce((a, item) => {
    let letter = item[labelKey][0].toUpperCase();
    if (numbers.indexOf(letter) !== -1) {
      letter = '0-9';
    }
    if (!a[letter]) {
      a[letter] = item[idKey];
    }
    return a;
  }, {});
};
