import React from 'react';
import { useNavigate } from 'react-router';
import { Mail as MailIcon } from 'tabler-icons-react';
import routes from '@routes';
import {
  MailTo,
  Spacer,
  Text,
  CancelButton,
  ButtonWrapper,
} from '@containers/Settings/SubscriptionInfo/components/BottomSection/styles';

const BottomSection = ({ id, cancelAtPeriodEnd }) => {
  const navigate = useNavigate();
  return (
    <>
      <MailTo href={routes.contact}>
        <Spacer>
          <Text>Contact us</Text>
          <MailIcon size={27} strokeWidth={1.5} />
        </Spacer>
      </MailTo>
      {!cancelAtPeriodEnd && (
        <ButtonWrapper>
          <CancelButton onClick={() => navigate(routes.cancelSubscription(id))}>
            Cancel Subcription
          </CancelButton>
        </ButtonWrapper>
      )}
    </>
  );
};

export default BottomSection;
