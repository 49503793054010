import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { inject, observer } from 'mobx-react';
import { statrafSendLocationEvent } from '@utils/statrafEvents';

const Analytics = ({ authStore: isGoogleAnalyticsInitialized }) => {
  const location = useLocation();

  useEffect(() => {
    statrafSendLocationEvent(location.pathname);
    if (
      process.env.REACT_APP_ENV !== 'development' &&
      isGoogleAnalyticsInitialized
    ) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);

  return null;
};

export default inject('authStore')(observer(Analytics));
