export const groupBy = (collection, field) =>
  collection.reduce((r, e) => {
    const label = e[field];
    if (!r[label]) r[label] = [e];
    else r[label].push(e);
    return r;
  }, {});

export const groupByTags = (data, tags) => {
  let groupedData = [];
  const groupedByTags = groupBy(data, 'briefing_section');
  tags.forEach((tag) => {
    const tagData = groupedByTags[tag];
    if (tagData) {
      groupedData = groupedData.concat(tagData);
    }
  });
  return groupedData;
};

export const getTags = (briefingTypes, briefingTypeId) => {
  const briefingType = briefingTypes.find(({ id }) => id === briefingTypeId);
  if (briefingType && briefingType.tags.length > 0) {
    return briefingType.tags;
  }
  return null;
};

export const groupSectionsDataByTags = (section, briefingTypes) => {
  if (
    section &&
    section.contentHashtagType?.includes('briefing') &&
    section.data &&
    section.data[0].briefing_section &&
    section.briefing_section !== '' &&
    section.data[0].hashtags
  ) {
    const groupedSection = section;
    const tags = getTags(briefingTypes, section.data[0].hashtags[0]);
    if (tags) {
      groupedSection.data = groupByTags(section.data, tags);
    }
    return groupedSection;
  }
  return section;
};
