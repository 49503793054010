const routes = {
  auth: {
    signIn: '/',
    logout: '/logout',
    magicLink: '/magic-link/:token',
    resetPassword: '/change-password/:token',
    forgotPassword: '/forgot-password',
    setPassword: '/set-password/:token',
  },
  unsubscribe: '/unsubscribe',
  briefing: (briefingId) =>
    briefingId ? `/briefings/${briefingId}` : '/briefings/:briefingId',
  company: (companyId) =>
    companyId ? `/companies/${companyId}` : '/companies/:companyId',
  contact: '/contact-us',
  dashboard: '/dashboard',
  dashboardDialog: '/dashboard/dialog',
  myNews: (type) => (type ? `/my-news/${type}` : `/my-news/:type`),
  search: `/search`,
  settings: '/settings',
  singleCompany: (companyId, type) =>
    companyId ? `/company/${companyId}/${type}` : '/company/:companyId/:type',
  secureLinkLogin: '/secure-link-login',
  article: (id) =>
    id
      ? `/client/section/content/${id}/article`
      : '/client/section/content/:id/article',
  subscriptionInfo: (id) =>
    id
      ? `/settings/subscription-info/${id}`
      : '/settings/subscription-info/:id',
  paymentMethod: '/settings/payment-method',
  cancelSubscription: (id) =>
    id
      ? `/settings/cancel-subscription/${id}`
      : '/settings/cancel-subscription/:id',
  trial: '/trial',
  deleteCard: '/delete-card',
  updateCard: '/update-card',
};

export const MY_NEWS_TYPES = {
  FOLLOWING: 'following',
  BRIEFINGS: 'briefings',
  COVERAGES: 'coverages',
};

export default routes;
