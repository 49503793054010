import { runInAction, makeAutoObservable } from 'mobx';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';

export class BriefingsStore {
  initialized = false;

  isLoading = false;

  error = false;

  briefingTypes = [];

  briefings = { sections: [], links: {} };

  briefingsGroups = {};

  showNewContentBanner = false;

  links = {};

  loadingMore = false;

  loadingBriefingTypeId = null;

  briefingTitle = null;

  constructor() {
    makeAutoObservable(this);
  }

  setShowNewContentBanner = (value) => {
    this.showNewContentBanner = value;
  };

  checkForNewContent = async (id) => {
    try {
      const {
        data: { sections },
      } = await API.get(apiRoutes.briefing(id), {
        params: { limit: 1 },
      });

      const { id: newId, date } = sections[0].data[0];
      if (newId && date && !this.briefingsGroups[id].sections[0]) {
        this.showNewContentBanner = true;
      }
      const { id: idTop, date: dateTop } =
        this.briefingsGroups[id].sections[0].data[0];

      if (newId !== idTop || date !== dateTop) {
        this.showNewContentBanner = true;
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
  };

  fetchBriefings = async (id, reload = false) => {
    if (id === 'all' && this.briefingTypes.length) {
      return;
    }

    this.isLoading = true;

    let apiUrl = apiRoutes.briefingTypes;
    if (id !== 'all') {
      apiUrl = apiRoutes.briefing(id);
    }

    try {
      if (
        !this.briefingsGroups[id] ||
        Object.keys(this.briefingsGroups).length === 0 ||
        reload
      ) {
        const { data } = await API.get(apiUrl);

        runInAction(() => {
          if (data.sections === undefined || data.sections.length === 0) {
            data.sections = [];
            data.links = {};
          }
          if (id === 'all') {
            this.briefingTypes = data.items;
          } else {
            this.briefings = data;
          }
        });

        this.briefingsGroups[id] = {
          ...data,
        };
      }
      runInAction(() => {
        this.briefings = this.briefingsGroups[id];
        this.links = this.briefings.links;
      });
      if (this.briefingTypes.length === 0) {
        await this.fetchBriefingTypes();
      }
      if (id === 'all') {
        this.briefingTitle = null;
      } else {
        const currentBriefingType = this.briefingTypes.find(
          (x) => x.id.toString() === id,
        );
        if (currentBriefingType !== undefined) {
          this.briefingTitle = currentBriefingType.name;
        } else {
          this.briefingTitle = null;
        }
      }
    } catch (error) {
      this.error = error;
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.initialized = true;
      });
    }
  };

  fetchBriefingTypes = async () => {
    try {
      const { data } = await API.get(apiRoutes.briefingTypes);
      this.briefingTypes = data.items;
    } catch (error) {
      this.error = error;
    } finally {
      this.loadingBriefingTypeId = null;
    }
  };

  handleFollow = async (id, value) => {
    try {
      this.loadingBriefingTypeId = id;
      await API.put(apiRoutes.updateSingleNotification(id), { follow: !value });
    } catch (error) {
      this.error = error;
    } finally {
      this.loadingBriefingTypeId = null;
      await this.fetchBriefingTypes();
    }
  };

  mergeBriefings = (data, id) => {
    const dataBriefings = data.sections;
    const lastBriefingIndex = this.briefings.sections.length - 1;
    const lastBriefing = this.briefings.sections[lastBriefingIndex];
    const [firstNewBriefing] = dataBriefings;

    const newBriefings = [...this.briefings.sections];

    // if first new section should be included to the last of existing
    if (
      lastBriefing.sectionTitle === firstNewBriefing.sectionTitle &&
      lastBriefing.publicationDate === firstNewBriefing.publicationDate
    ) {
      const updatedLastBriefing = {
        ...lastBriefing,
        data: [...lastBriefing.data, ...firstNewBriefing.data],
      };

      newBriefings[newBriefings.length - 1] = updatedLastBriefing;
      dataBriefings.shift();
    }
    this.briefings.sections = [...newBriefings, ...dataBriefings];
    this.briefings.links = newBriefings.length ? data.links : {};
    this.briefingsGroups[id] = this.briefings;
    this.links = this.briefings.links;
  };

  loadMore = async (id) => {
    runInAction(() => {
      this.loadingMore = true;
    });

    try {
      const { data } = await API.get(this.links.next);
      runInAction(() => {
        this.mergeBriefings(data, id);
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loadingMore = false;
      });
    }
  };
}

export default new BriefingsStore();
