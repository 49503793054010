import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ChartTooltip from '@components/ChartTooltip';
import { formatName } from '@utils/chartHelpers';

const VerticalBarChart = ({
  chartData,
  keyNames,
  dataKey,
  id,
  maxBarHeight,
  marginBottom,
  marginLeft,
}) => (
  <>
    <ResponsiveContainer id={id} width="100%" height={450}>
      <BarChart
        data={chartData}
        margin={{
          top: 5,
          right: 10,
          bottom: marginBottom || 0,
          left: marginLeft || 0,
        }}
        layout="vertical"
      >
        <CartesianGrid strokeDasharray="4 3" stroke="rgba(0,0,0,.1)" />
        <XAxis
          label={{
            value: 'Number of Articles',
            fill: '#000000',
            position: 'bottom',
            offset: -5,
          }}
          tick={{ fill: 'black' }}
          type="number"
          stroke="#aaaaaa"
          allowDecimals={false}
        />
        <YAxis
          tick={{ fill: 'black' }}
          type="category"
          dataKey={dataKey}
          stroke="#aaaaaa"
          width={120}
        />
        <Tooltip content={<ChartTooltip />} cursor={{ fill: '#e0e0e0aa' }} />
        {keyNames.map((key) => (
          <Bar
            key={`bar-chart-bar_${key.name}`}
            dataKey={key.name}
            name={formatName(key.label || key.name)}
            stackId="a"
            fill={key.color}
            shape={(props) => {
              const newProps = props;
              const maxHeight = maxBarHeight || 60;
              if (newProps.height > maxHeight) {
                newProps.y += newProps.height / 2 - maxHeight / 2;
                newProps.height = maxHeight;
              }
              return <Rectangle {...newProps} />;
            }}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  </>
);

export default VerticalBarChart;
