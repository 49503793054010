import * as yup from 'yup';

const passwordComplexity = (value) => {
  if (value.length < 6) return 'Password needs to be 6 characters or longer.';
  if (value.match(new RegExp(/[a-z]/)) === null) return 'Password needs to contain at least one lower case character (a-z).';
  if (value.match(new RegExp(/[A-Z]/)) === null) return 'Password needs to contain at least one upper case character (A-Z).';
  if (value.match(new RegExp(/\d/)) === null) return 'Password needs to contain at least one number (0-9).';
  if (value.match(new RegExp(/[`~!@#$%^&*()\-_=+[{\]};:'"\\|,<.>/?]/)) === null) {
    return 'Password needs to contain at least one special character (e.g. !, @, & or #).';
  }
  if (value.match(new RegExp(/\s/)) !== null) return "Password can't contain whitespace";
  return null;
};

export const newPasswordValidationSchema = () => {
  const validationSchema = yup.object().shape({
      newPassword: yup.string().required('New password is required'),
      reNewPassword: yup.string().required('Repeat your new password'),
    }).test({name: 'global-ok', test: async (aValue, ctx) => {
      const value = await aValue;
      const passwordMessage = passwordComplexity(value.newPassword);
      if (passwordMessage !== null) {
        return ctx.createError({
          message: passwordMessage,
          path: 'newPassword'});
      }
      if (value.newPassword !== value.reNewPassword) {
        return ctx.createError({
          message: 'Passwords don\'t match',
          path: 'reNewPassword'});
      }
      const reNewRefs = validationSchema.byName.reNewPassword;
      if (reNewRefs !== undefined) {
          const reNewInput = await reNewRefs.input.current;
          const reNewErr = await reNewRefs.err.current;
          reNewInput.classList.remove('invalid');
          reNewErr.innerText = '';
      }
      return true;
    }});
    validationSchema.byName = {};
    return validationSchema;
};