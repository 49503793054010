import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CompaniesBox from '@containers/Companies/components/CompaniesBox';

const Companies = () => {
  const { companyId } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });

  return <CompaniesBox type={companyId} />;
};

export default Companies;
