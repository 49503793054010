import React from 'react';
import PropTypes from 'prop-types';
import CompaniesSortGroup from '@components/CompaniesDialog/components/CompaniesSortGroup';
import { AvailableCompaniesList } from '@components/CompaniesDialog/styles';

const GroupedCompaniesList = ({ groupedCompanies }) => {
  const labels = Object.keys(groupedCompanies).sort();
  return (
    <AvailableCompaniesList>
      {labels.map((label) => (
        <CompaniesSortGroup
          key={`company-group-${label}`}
          label={label}
          companies={groupedCompanies[label]}
        />
      ))}
    </AvailableCompaniesList>
  );
};

GroupedCompaniesList.propTypes = {
  groupedCompanies: PropTypes.object.isRequired,
};

export default GroupedCompaniesList;
