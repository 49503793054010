import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import DesktopCompaniesDialog from '@components/CompaniesDialog/components/DesktopCompaniesDialog';
import MobileCompaniesDialog from '@components/CompaniesDialog/components/MobileCompaniesDialog';
import useBreakpoint from '@root/hooks/useBreakpoint';

const CompaniesDialog = () => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  if (isMobile) {
    return <MobileCompaniesDialog />;
  }

  return <DesktopCompaniesDialog />;
};

CompaniesDialog.propTypes = {
  dashboardStore: PropTypes.shape({
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default inject('dashboardStore')(observer(CompaniesDialog));
