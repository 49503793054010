import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import routes from '@routes';

const Logout = ({ authStore: { logout } }) => {
  useEffect(() => {
    logout();
  }, []);

  return <Navigate to={routes.auth.signIn} replace />;
};

export default inject('authStore')(observer(Logout));
