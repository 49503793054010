import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Preloader from '@components/Preloader';
import {
  SectionContainer,
  SectionHeader,
  Card,
  CardTitle,
  CardNumber,
  Button,
} from '@containers/PaymentMethod/styles';

const PaymentMethod = ({
  paymentMethodStore: {
    cards,
    fetchCards,
    loadedCards,
    makeCardDefault,
    deleteCard,
  },
}) => {
  useEffect(() => {
    (async () => {
      await fetchCards();
    })();
  }, [cards]);

  if (cards?.length === 0) {
    return null;
  }

  return (
    <SectionContainer>
      <SectionHeader>Currently available</SectionHeader>
      {loadedCards ? (
        cards.map((card, idx) => (
          <Card key={`payment-card-${idx}`}>
            <CardTitle>
              Credit card ending <CardNumber>*{card.last4}</CardNumber>
            </CardTitle>
            {(card.isSource && (
              <>
                <Button
                  className={card.default || 'unset'}
                  onClick={
                    card.default || (() => makeCardDefault({ id: card.id }))
                  }
                >
                  {card.default ? 'Default' : 'Make Default'}
                </Button>
                <Button
                  className="delete"
                  onClick={() => deleteCard({ id: card.id })}
                >
                  Delete
                </Button>
              </>
            )) || (
              <Button disabled="true" className="pseudo">
                Managed by Stripe
              </Button>
            )}
          </Card>
        ))
      ) : (
        <Preloader />
      )}
    </SectionContainer>
  );
};

export default inject('paymentMethodStore')(observer(PaymentMethod));
