import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import routes, { MY_NEWS_TYPES } from '@routes';

const UnauthenticatedRoute = inject('authStore')(
  observer(({ authStore, children }) => {
    if (authStore.isAuthenticated) {
      return <Navigate to={routes.myNews(MY_NEWS_TYPES.FOLLOWING)} replace />;
    }

    return children;
  }),
);

UnauthenticatedRoute.propTyose = {
  authStore: PropTypes.shape({ isAuthenticated: PropTypes.bool }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default UnauthenticatedRoute;
