import React from 'react';
import { inject, observer } from 'mobx-react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import Input, { INPUT_TYPES } from '@components/Input';
import {
  ForgotPassword,
  ForgotPasswordContainer,
  FormContainer,
  Logo,
  PageContainer,
  StyledForm,
  StyledSubmit,
  SubmitContainer,
} from '@containers/SignIn/styles';
import logo from '@app/assets/logo.png';
import routes from '@routes';

const SignInPage = ({ authStore: { login, authErrors } }) => {
  const validationSchema = yup.object().shape({
    email: yup.string().email('Email is invalid').required('Email is required'),
    password: yup.string().required('Password is required'),
  });

  const resolver = yupResolver(validationSchema);
  const {
    handleSubmit,
    formState: { errors: formError },
    control,
  } = useForm({ resolver });

  let errors = {};

  if (formError && Object.keys(formError).length) {
    errors = formError;
  }

  if (!Object.keys(formError).length && Object.keys(authErrors).length) {
    errors = authErrors;
  }

  return (
    <PageContainer>
      <FormContainer>
        <Logo alt="logo" src={logo} />
        <StyledForm onSubmit={handleSubmit((data) => login(data))} action="">
          <Controller
            name="email"
            defaultValue=""
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                autoComplete
                name={name}
                inputType={INPUT_TYPES.TEXT}
                onChange={onChange}
                value={value}
                label="Email address"
                error={errors[name]}
              />
            )}
          />
          <Controller
            name="password"
            defaultValue=""
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                autoComplete
                name={name}
                inputType={INPUT_TYPES.PASSWORD}
                onChange={onChange}
                value={value}
                label="Password"
                error={errors[name]}
              />
            )}
          />
          <ForgotPasswordContainer>
            <Link
              to={routes.auth.forgotPassword}
              style={{ marginRight: '1em' }}
            >
              <ForgotPassword>Forgot password?</ForgotPassword>
            </Link>
            <Link to={routes.secureLinkLogin}>
              <ForgotPassword>Log in with secure link</ForgotPassword>
            </Link>
          </ForgotPasswordContainer>
          <SubmitContainer>
            <StyledSubmit style={{ width: '100%', height: 40 }} type="submit">
              Log in
            </StyledSubmit>
          </SubmitContainer>
        </StyledForm>
      </FormContainer>
    </PageContainer>
  );
};

export default inject('authStore')(observer(SignInPage));
