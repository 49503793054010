import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import routes from '@routes';
import {
  Container,
  ImgContainer,
  ImgSmall,
  ImgBig,
  ImageWrapper,
  Flex,
  Text,
  SmallImageWrapper,
  ContactBox,
  ContactText,
  LinkWrapper,
} from '@containers/Trial/style';
import useBreakpoint from '@root/hooks/useBreakpoint';
import CompaniesTrialBackBright from '@assets/images/trial-back-bright.png';
import CompaniesTrialFrontBright from '@assets/images/trial-front-bright-hsbc.png';
import CompaniesTrialBackDark from '@assets/images/trial-back-dark.png';
import CompaniesTrialFrontDark from '@assets/images/trial-front-dark-hsbc.png';
import BriefingsTrialBackBright from '@assets/images/briefings-trial-back-bright.png';
import BriefingsTrialFrontBright from '@assets/images/briefings-trial-front-bright.png';
import BriefingsTrialBackDark from '@assets/images/briefings-trial-back-dark.png';
import BriefingsTrialFrontDark from '@assets/images/briefings-trial-front-dark.png';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const images = {
  companies: {
    light: {
      front: CompaniesTrialFrontBright,
      back: CompaniesTrialBackBright
    },
    dark: {
      front: CompaniesTrialFrontDark,
      back: CompaniesTrialBackDark
    }
  },
  briefings: {
    light: {
      front: BriefingsTrialFrontBright,
      back: BriefingsTrialBackBright
    },
    dark: {
      front: BriefingsTrialFrontDark,
      back: BriefingsTrialBackDark
    }
  },
}

const headlines = {
  companies: 'Stay up-to-date with all the companies you monitor.',
  briefings: 'Stay well informed with our Briefings service.',
}

function Trial({ authStore: { darkMode } }) {
  let { search: { type } } = useCustomSearchParams();
  if (!type) {
    type = 'companies';
  }
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.LG);

  const mode = darkMode ? 'dark' : 'light'

  const trialImageFront = images[type][mode].front;
  const trialImageBack = images[type][mode].back;

  return (
    <Flex>
      <Container>
        <ContactBox isMobile={isMobile}>
          <Text>{headlines[type]}</Text>
          <LinkWrapper isMobile={isMobile}>
            <Link to={routes.contact}>
              <ContactText bigger>Contact us</ContactText>
              <ContactText>to find out more.</ContactText>
            </Link>
          </LinkWrapper>
        </ContactBox>
        <ImgContainer>
          <ImageWrapper>
            <ImgBig src={trialImageFront} />
          </ImageWrapper>
          <SmallImageWrapper>
            <ImgSmall src={trialImageBack} />
          </SmallImageWrapper>
        </ImgContainer>
      </Container>
    </Flex>
  );
}

export default inject('authStore')(observer(Trial));
