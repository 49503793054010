import moment from 'moment';

export const CHART_COLORS = [
  '#2f4f4f',
  '#556b2f',
  '#8b4513',
  '#191970',
  '#8b0000',
  '#808000',
  '#778899',
  '#008000',
  '#3cb371',
  '#bc8f8f',
  '#cd853f',
  '#9acd32',
  '#00008b',
  '#32cd32',
  '#daa520',
  '#7f007f',
  '#8fbc8f',
  '#ff4500',
  '#00ced1',
  '#ff8c00',
  '#deb887',
  '#8a2be2',
  '#dc143c',
  '#00bfff',
  '#0000ff',
  '#da70d6',
  '#ff00ff',
  '#1e90ff',
  '#db7093',
  '#fa8072',
  '#dda0dd',
  '#ff1493',
  '#7b68ee',
];

export const PRESETS = [
  {
    id: '1D',
    label: '1D',
    value: '1D',
    from: moment().subtract(1, 'day').startOf('day'),
    to: moment(),
  },
  {
    id: '1W',
    label: '1W',
    value: '1W',
    from: moment().subtract(7, 'days').startOf('day'),
    to: moment(),
  },
  {
    id: '1M',
    label: '1M',
    value: '1M',
    from: moment().subtract(30, 'days'),
    to: moment(),
  },
  {
    id: '6M',
    label: '6M',
    value: '6M',
    from: moment().startOf('day').subtract(6, 'months'),
    to: moment(),
  },
  {
    id: 'YTD',
    label: 'YTD',
    value: 'YTD',
    from: moment().startOf('year'),
    to: moment(),
  },
  {
    id: '1Y',
    label: '1Y',
    value: '1Y',
    from: moment().subtract(1, 'year'),
    to: moment(),
  },
  {
    id: '2Y',
    label: '2Y',
    value: '2Y',
    from: moment().subtract(2, 'years'),
    to: moment(),
  },
  { id: 'Custom', label: 'Custom', value: 'Custom', from: null, to: null },
];

export const SORT_OPTIONS = [
  { id: 'bySector', value: 'bySector', label: 'Sector' },
  { id: 'alphabetically', value: 'alphabetically', label: 'Name' },
];

export const CHART_TYPES = [
  { id: 'line', value: 'line', label: 'Line' },
  { id: 'bar', value: 'bar', label: 'Column' },
  { id: 'stacked', value: 'stacked', label: 'Stacked' },
  { id: 'stacked100', value: 'stacked100', label: 'Stacked 100%' },
  { id: 'pie', value: 'pie', label: 'Pie' },
];

export const INTERVALS = [
  { id: 'day', value: 'day', label: 'Day' },
  { id: 'week', value: 'week', label: 'Week' },
  { id: 'month', value: 'month', label: 'Month' },
  { id: 'total', value: 'total', label: 'Total' },
];

export const SENTIMENTS = [
  { name: 'negative', color: '#732844' },
  { name: 'slightly_negative', color: '#cf7485' },
  { name: 'neutral', color: 'lightgrey' },
  { name: 'slightly_positive', color: '#5ca074' },
  { name: 'positive', color: '#4b6428' },
  { name: 'undisclosed', color: '#3c475a' },
];

export const SENTIMENTS_ORDER = [
  'slightly_negative',
  'negative',
  'neutral',
  'positive',
  'slightly_positive',
];

export const BESPOKE_TOOLTIP =
  'As standard, our analysts use a set of editorial guidelines when curating coverage to ensure consistency across every company we track.' +
  ' If we are doing additional bespoke tracking for you (such as sector or topic searches)' +
  ' then you can switch on this option to include that content within the coverage totals.';

export const SENTIMENT_TOOLTIP =
  'Our analysts follow set guidelines when marking a sentiment score to ensure subjectivity and consistency.' +
  ' Sentiment scores are given in relation to the specific company for which the article has been uploaded.' +
  ' For more information on the guidelines we use, feel free to get in touch.';

export const UNDISCLOSED_TOOLTIP =
  'Due to copyright reasons, we are not able to show sentiment data for Financial Times content';

export const SHOW_AGGREGATES_TOOLTIP = 'Show aggregate of selected companies';
export const COMPANY_COLORS_TOOLTIP = 'Use random colours for companies';
