import React from 'react';
import { inject, observer } from 'mobx-react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input, { INPUT_TYPES } from '@components/Input';
import {
  SectionContainer,
  SectionHeader,
  Form,
  Button,
  Box,
  StyledMaskedInput,
  Label,
  InputWrapper,
  ErrorMessage,
} from '@containers/PaymentMethod/styles';


const NewPaymentMethod = ({
  paymentMethodStore: {
    addCard,
  }
}) => {
  const validationSchema = yup.object().shape({
    fullName: yup.string().trim().required('Name is required'),
    cardNumber: yup.string().trim().test(
      'is-card-number',
      'Card number should have 16 digits',
      (value) => value.search(/^[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}\s?[0-9]{4}$/) !== -1),
    expiryDate: yup.string().trim().test(
      'expiration-day',
      'Expiration date in MM/YY format is required',
      (value) => {
        const pos = value.search(/\/|\s/);
        if (pos === -1) {
          return false;
        }
        const month = parseInt(value.substring(0, pos), 10);
        const year = parseInt(value.substring(pos + 1), 10);
        if (!Number.isInteger(month) || !Number.isInteger(year)) {
          return false;
        }
        if (month < 1 || month > 12 || year < 0 || year > 100) {
          return false;
        }
        return true;
      }),
      securityCode: yup.string().trim().test(
        'is-security-code',
        'Security code should have 3 digits',
        (value) => value.search(/^[0-9]{3}$/) !== -1),
      });

  const resolver = yupResolver(validationSchema);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ resolver });

  const onSubmit = async (data) => {
    data.expiryDate = data.expiryDate.replace(/\s/, '/');
    addCard({ data });
  };

  return (
    <SectionContainer>
      <SectionHeader>Add a new method</SectionHeader>
      <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <Box>
          <Controller
            name="fullName"
            defaultValue=""
            control={control}
            mb={0}
            render={({ field: { name, onChange, value } }) => (
              <Input
                autoComplete
                name={name}
                inputType={INPUT_TYPES.TEXT}
                onChange={onChange}
                value={value}
                label="Full Name (as it appears on the card)"
                error={errors[name]}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="cardNumber"
            defaultValue=""
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <InputWrapper>
                <Label className="custom-input-label">Card Number</Label>
                <StyledMaskedInput
                  name={name}
                  onChange={onChange}
                  value={value}
                  error={errors[name]}
                  mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                  className={ errors[name] ? 'invalid' : '' }
                />
                <ErrorMessage>{ errors[name]?.message || '' }</ErrorMessage>
              </InputWrapper>
            )}
          />
        </Box>
        <Box>
          <Controller
            name="expiryDate"
            defaultValue=""
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <InputWrapper>
                <Label className="custom-input-label">Expiration Date (MM/YY)</Label>
                <StyledMaskedInput
                  name={name}
                  onChange={onChange}
                  value={value}
                  error={errors[name]}
                  mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                  className={ errors[name] ? 'invalid' : '' }
                />
                <ErrorMessage>{ errors[name]?.message || '' }</ErrorMessage>
              </InputWrapper>
            )}
          />
          <Controller
            name="securityCode"
            defaultValue=""
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <InputWrapper>
                <Label className="custom-input-label">Security code</Label>
                <StyledMaskedInput
                  name={name}
                  onChange={onChange}
                  value={value}
                  error={errors[name]}
                  mask={[/\d/, /\d/, /\d/]}
                  className={ errors[name] ? 'invalid' : '' }
                />
                <ErrorMessage>{ errors[name]?.message || '' }</ErrorMessage>
              </InputWrapper>
            )}
          />
        </Box>
        <Button type="submit">
          Confirm
        </Button>
      </Form>
    </SectionContainer>
  );
};

export default inject('paymentMethodStore')(observer(NewPaymentMethod));