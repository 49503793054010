import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { ArrowLeft, ArrowsSort, Check, Search, Star } from 'tabler-icons-react';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@components/Modal/Dialog';
import Input, { INPUT_TYPES, THEMES } from '@components/Input';
import {
  ContentContainer,
  MobileHeaderContainer,
  MobileButtonsContainer,
  MobileIconButton,
  DropdownsContainer,
} from '@components/CompaniesDialog/styles';
import DashboardDropdown from '@components/DashboardSelect';
import GroupedCompaniesList from '@components/CompaniesDialog/components/GroupedCompaniesList';
import SelectedCompaniesList from '@components/CompaniesDialog/components/SelectedCompaniesList';
import RawCompaniesList from '@components/CompaniesDialog/components/RawCompaniesList';
import useDebounce from '@root/hooks/useDebounce';
import { SORT_OPTIONS } from '@root/constants/dashboard';
import CompanyGroups from '@components/CompaniesDialog/components/CompanyGroups';
import { history } from '@app/history';
import { enable, disable } from '@utils/scrollLock';

const MobileCompaniesDialog = ({
  dashboardStore: {
    companies,
    dashboardUserSettings,
    dialogSelectedCompanies,
    searchCompanies,
    searchQuery,
    setSearchQuery,
    saveDialogSelection,
    setShouldReloadCharts,
    setShouldReloadTotalCoverage,
    clearDialogSelectionCompaniesObjects,
  },
}) => {
  const [sortBy, setSortBy] = useState('bySector');
  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    enable();
    return disable();
  }, []);

  useEffect(
    () => () => history.action === 'POP' ? reloadCharts(false) : null,
    [],
  );

  const reloadCharts = (value) => {
    setShouldReloadCharts(value);
    setShouldReloadTotalCoverage(value);
  };

  const closeHandler = () => {
    reloadCharts(false);
    navigate('/dashboard');
  };

  const saveHandler = () => {
    saveDialogSelection();
    reloadCharts(true);
    navigate('/dashboard');
  };

  useDebounce(searchQuery, 300, searchCompanies);

  const theme = useTheme();

  return (
    <Dialog closeHandler={closeHandler} isOpen>
      <MobileHeaderContainer>
        <MobileButtonsContainer>
          <MobileIconButton
            className="no-border"
            onClick={() => {
              closeHandler();
            }}
          >
            <ArrowLeft />
          </MobileIconButton>
          <Input
            maxWidth={250}
            name="Companies search"
            inputType={INPUT_TYPES.TEXT}
            onChange={setSearchQuery}
            value={searchQuery}
            placeholder="Search companies"
            icon={
              <Search
                className="input-container__icon"
                stroke={theme.inputs.secondary.icon}
                size={22}
              />
            }
            inputTheme={THEMES.SECONDARY}
          />
          <MobileIconButton
            className="right"
            onClick={() => setShowFilters(!showFilters)}
          >
            <Star />
          </MobileIconButton>
          <MobileIconButton onClick={saveHandler}>
            <Check />
          </MobileIconButton>
        </MobileButtonsContainer>
        <MobileHeaderContainer className={showFilters ? null : 'collapse'}>
          <CompanyGroups />
        </MobileHeaderContainer>
      </MobileHeaderContainer>
      <ContentContainer
        height={window.innerHeight}
        className={showFilters ? 'filters' : null}
      >
        <SelectedCompaniesList
          isMobile
          clearAll={() => {
            clearDialogSelectionCompaniesObjects();
            dialogSelectedCompanies.clear();
          }}
        />
        {dashboardUserSettings.type === 'advisory' ? (
          <DropdownsContainer>
            <DashboardDropdown
              id="sort-select"
              name="Sort"
              // prefix="Sort by: "
              onChange={setSortBy}
              value={sortBy}
              options={SORT_OPTIONS}
              placeholder="Sort"
              icon={<ArrowsSort size={18} />}
            />
          </DropdownsContainer>
        ) : null}
        {dashboardUserSettings && dashboardUserSettings.type === 'advisory' ? (
          <GroupedCompaniesList groupedCompanies={companies[sortBy]} />
        ) : null}
        {dashboardUserSettings && dashboardUserSettings.type === 'corporate' ? (
          <RawCompaniesList companies={companies.raw} />
        ) : null}
      </ContentContainer>
    </Dialog>
  );
};

MobileCompaniesDialog.propTypes = {
  dashboardStore: PropTypes.shape({
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default inject('dashboardStore')(observer(MobileCompaniesDialog));
