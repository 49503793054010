import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { stringify } from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { Search, X } from 'tabler-icons-react';
import {
  Form,
  MultipleInputs,
  SingleInput,
  Border,
} from '@components/SearchBar/style';
import Button, { BUTTON_TYPES } from '@components/Button';
import Select from '@components/Select';
import Input, { INPUT_TYPES, THEMES } from '@components/Input';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const searchTypes = [
  { label: 'All', value: ['title', 'description', 'author', 'source'] },
  { label: 'Author', value: ['author'] },
  { label: 'Content', value: ['title', 'description'] },
  { label: 'Source', value: ['source'] },
];

const SearchBar = ({
  placeholder,
  withoutButton,
  extendedSearch,
  companiesStore: { filterCompanies },
  newsStore: { isLoading },
  sidebarStore: {
    searchLastPath,
    setSearchLastPath,
    lastPlaceholder,
    setLastPlaceholder,
  },
}) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { dirtyFields },
    reset,
    setValue,
  } = useForm({
    defaultValues: { text: '' },
  });
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const theme = useTheme();
  const { companyId } = useParams();
  const { search, setSearch } = useCustomSearchParams();
  const { pathname, search: searchQuery } = useLocation();
  const navigate = useNavigate();
  const text = watch('text');
  const fields = watch('fields');

  useEffect(() => {
    let timeout;
    if (withoutButton) {
      timeout = setTimeout(() => {
        if (pathname.includes('companies')) {
          filterCompanies(text);
        }
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [text]);

  const resetInput = () => {
    reset();
    if (extendedSearch && searchLastPath) {
      navigate(searchLastPath);
    }
  };

  useEffect(
    () =>
      pathname.includes('search') ? setValue('text', search?.text) : reset(),
    [pathname],
  );

  useEffect(() => {
    if (!pathname.includes('search')) {
      setSearchLastPath(pathname);
    }
  }, [pathname]);

  useEffect(() => {
    if (!pathname.includes('search')) {
      setLastPlaceholder(placeholder);
    }
  }, [pathname, placeholder]);

  const onSubmit = async (data) => {
    let { type, follow } = search;
    const hashtagId = companyId === undefined ? search.hashtagId : companyId;
    follow = pathname.includes('my-news') || follow ? true : undefined;

    if (pathname.includes('briefings')) {
      type = 'briefing';
    }

    if (pathname.includes('companies') || pathname.includes('coverages')) {
      type = 'section';
    }

    if (pathname.includes('company') || searchQuery.includes('hashtagId')) {
      setSearch({
        hashtagId,
        type,
        ...data,
      });
      navigate(`/search?${stringify({ hashtagId, type, ...data })}`);
    } else {
      setSearch({
        type,
        follow,
        ...data,
      });
      navigate(`/search?${stringify({ type, follow, ...data })}`);
    }
  };

  const onEnterDownSearchMobile = (e) => {
    if (e.key === 'Enter' && isMobile) {
      onSubmit({ text, fields });
    }
  };

  const disableEnter = (e) => e.keyCode === 13 && e.preventDefault();

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      extendedSearch={extendedSearch}
      action=""
    >
      {extendedSearch ? (
        <MultipleInputs>
          <Controller
            control={control}
            name="fields"
            defaultValue={['title', 'description', 'author', 'source']}
            render={({ field: { name, onChange, value } }) => (
              <>
                <Select
                  id="fields"
                  name={name}
                  onChange={onChange}
                  value={value}
                  options={searchTypes}
                  disableSearch
                  defaultValue={{
                    label: 'All',
                    value: ['title', 'description', 'author', 'source'],
                  }}
                />
              </>
            )}
          />
          <Controller
            name="text"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <Input
                  name={name}
                  inputType={INPUT_TYPES.TEXT}
                  onChange={onChange}
                  value={value}
                  placeholder={lastPlaceholder || placeholder}
                  noMargin
                  onKeyDown={onEnterDownSearchMobile}
                  withoutButton={withoutButton}
                  maxWidth={416}
                  inputTheme={THEMES.SECONDARY}
                  icon={
                    <Search
                      className="input-container__icon"
                      stroke={theme.inputs.secondary.icon}
                      size={22}
                    />
                  }
                />
                {dirtyFields.text && (
                  <X
                    onClick={resetInput}
                    stroke={theme.colors.primary}
                    size={22}
                    className="reset"
                  />
                )}
              </>
            )}
          />
          <Border />
        </MultipleInputs>
      ) : (
        <SingleInput>
          <Controller
            name="text"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <Input
                  name={name}
                  inputType={INPUT_TYPES.TEXT}
                  onChange={onChange}
                  value={value}
                  placeholder={lastPlaceholder || placeholder}
                  noMargin
                  onKeyDown={disableEnter}
                  withoutButton={withoutButton}
                  maxWidth={416}
                  inputTheme={THEMES.SECONDARY}
                  icon={
                    <Search
                      className="input-container__icon"
                      stroke={theme.inputs.secondary.icon}
                      size={22}
                    />
                  }
                />
                {dirtyFields.text && (
                  <X
                    onClick={resetInput}
                    stroke={theme.colors.primary}
                    size={22}
                    className="reset"
                  />
                )}
              </>
            )}
          />
        </SingleInput>
      )}

      {!withoutButton ? (
        <Button
          type={BUTTON_TYPES.SUBMIT}
          disabled={isLoading || !text?.length}
        >
          SEARCH
        </Button>
      ) : null}
    </Form>
  );
};

export default inject(
  'companiesStore',
  'newsStore',
  'sidebarStore',
)(observer(SearchBar));
