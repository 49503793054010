import React from 'react';
import {
  Cell,
  Pie,
  PieChart as Chart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import PieChartTooltip from '@components/PieChartTooltip';

const Container = ({ id, width, useAspect, children, ref }) => {
  if (useAspect) {
    return (
      <ResponsiveContainer id={id} width={width} aspect={1} ref={ref}>
        {children}
      </ResponsiveContainer>
    );
  }
  return (
    <ResponsiveContainer id={id} width={width} height={400} ref={ref}>
      {children}
    </ResponsiveContainer>
  );
};

const PieChart = ({
  chartData,
  dataKey,
  companyNames,
  useCompanyColors,
  id,
  width = '100%',
  useAspect,
  formatLabel,
  customTooltip,
  tooltipData,
}) => {
  const colors = {};
  if (customTooltip) {
    companyNames.forEach((item) => {
      colors[item.name] = useCompanyColors ? item.companyColor : item.color;
    });
  }
  return (
    <>
      <Container id={id} width={width} useAspect={useAspect}>
        <Chart>
          <Pie
            dataKey={dataKey}
            isAnimationActive={false}
            fill="#8884d8"
            data={chartData}
            legend
            startAngle={90}
            endAngle={450}
            innerRadius="75%"
            outerRadius="100%"
          >
            {chartData.map((entry, index) => {
              const companyItem =
                companyNames &&
                companyNames.find((company) => company.name === entry.name);
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    companyItem &&
                    companyItem[useCompanyColors ? 'companyColor' : 'color']
                  }
                />
              );
            })}
          </Pie>
          {customTooltip ? (
            <Tooltip
              content={
                <PieChartTooltip chartData={tooltipData} colors={colors} />
              }
            />
          ) : (
            <Tooltip
              formatter={(value, name) =>
                formatLabel ? [value, formatLabel(name)] : [value, name]
              }
            />
          )}
        </Chart>
      </Container>
    </>
  );
};

export default PieChart;
