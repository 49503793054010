import styled from '@emotion/styled';

export const PasswordInstructionsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.quinary};
  color: #7f7b9d;
  padding: 10px;
  margin-bottom: 10px;
`;

export const PasswordInstructionsTitle = styled.h3`
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  margin-bottom: 10px;
`;

export const PasswordInstructionsList = styled.ul`
  list-style-type: disc;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  padding-left: 20px;
`;

export const PasswordInstructionsListItem = styled.li`
  margin-bottom: 0.1em;
`;

export const Normal = styled.span`
  :after {
    content: ' ';
  }
`;

export const NonBreaking = styled(Normal)`
  white-space: pre;
`;

export const Important = styled(Normal)`
  font-weight: bold;
`;

export const TeleType = styled.span`
  font-family: monospace;
`;
