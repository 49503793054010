import React from 'react';
import { Link } from 'react-router-dom';
import {
  PaymentMethodContainer,
  ContentWrapper,
  PaymentMethodWrapper,
  PaymentMethodHeader,
  Row,
} from '@containers/PaymentMethod/styles';
import routes from '@routes';
import PaymentMethodsList from './components/PaymentMethodsList';
import NewPaymentMethod from './components/NewPaymentMethod';

const PaymentMethod = () => (
  <PaymentMethodContainer>
    <ContentWrapper>
      <PaymentMethodWrapper>
        <PaymentMethodHeader>Payment methods</PaymentMethodHeader>
        <Link to={routes.settings}>{'\u276E Settings'}</Link>
        <Row>
          <PaymentMethodsList />
          <NewPaymentMethod />
        </Row>
      </PaymentMethodWrapper>
    </ContentWrapper>
  </PaymentMethodContainer>
);

export default PaymentMethod;
