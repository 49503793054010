import { css } from '@emotion/react';

import styled from '@emotion/styled';

import { breakpoint } from '@styles/breakpoints';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const ArticleWrapper = styled.div`
  padding-bottom: 40px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.content};
  transition: background-color 0.3s;

  &::first-of-type {
    padding-top: 24px;
  }

  & + & {
    border-top: 1px solid #e1e9ff;
    padding-top: 32px;
    margin-top: 32px;

    .share-button {
      top: 32px;
    }
  }
`;

export const Source = styled.div`
  display: flex;
  padding-bottom: 8px;
  line-height: 22px;

  .text {
    color: ${({ theme }) => theme.font.tertiary};
  }
`;

export const Title = styled.div`
  h2 {
    font-size: ${({ theme }) => theme.defaults.fontSizeXXL}px;
    line-height: 33px;
    display: block;
    margin-bottom: 5px;
    color: ${({ theme }) => theme.font.secondary};
  }
`;

export const Published = styled.div`
  margin-bottom: 24px;
  .text {
    font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
    color: #7f7b9d;
  }

  .separator {
    padding: 0 15px;
  }
`;

export const Description = styled.div`
  margin-bottom: 16px;
  .text {
    font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
    line-height: 24px;
    color: ${({ theme }) => theme.font.default};
  }
`;

export const ReadMore = styled.a`
  text-decoration: underline;
  color: #6793ff;
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
`;

export const Analyst = styled.p`
  color: #7f7b9d;
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
`;

export const Section = styled.section`
  ${({ enableToScroll }) =>
    enableToScroll === true
      ? css`
          padding-top: 30px;

          :first-child {
            padding-top: 100px;
          }

          @media only screen and ${breakpoint.md} {
            padding-top: 30px;
          }
        `
      : null}
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.p`
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  color: ${({ theme }) => theme.colors.primary};
  line-height: 24px;
  color: ${({ theme }) => theme.font.default};
  p {
    margin: 12px 0;
  }
  a {
    color: #6793ff;
  }
  table {
    table-layout: fixed;
  }
  td {
    width: 130px;
  }
  strong {
    font-weight: 700;
  }
  i {
    font-style: italic;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  ul {
    padding-left: 17px;
    list-style-type: disc;
  }
`;

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.sidebar};
  border-radius: 3px;
  margin-left: auto;
  padding: 2px 10px;
  margin-right: 30px;
  width: max-content;
  height: 26px;

  @media only screen and ${breakpoint.md} {
    width: 100px;
  }

  @media only screen and ${breakpoint.sm} {
    width: 110px;
  }
`;

export const LexisNexisLogo = styled.img`
  scale: 0.5;
`;

export const LexisNexisLogoContainer = styled.div`
  margin-top: -20px;
  height: 20px;
  width: 20px;
  margin-right: 20px;
  margin-left: auto;
`;

export const ReadMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
