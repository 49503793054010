import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';
import ChevronIcon from '@assets/images/icons/chevron.svg';

export const Menu = styled.ul`
  overflow: hidden;
  max-height: 0;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  min-width: 190px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.colors.content};
  border-radius: 3px;
  overflow: hidden;

  @media screen and ${breakpoint.sm} {
    min-width: 180px;
    max-width: calc(100vw - 10px);
    overflow-wrap: break-word;
  }
`;

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;

  &:first-of-type {
    padding: 3px 0;
  }

  a,
  p {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.font.default};
    text-align: left;
    padding: 10px 0;
    transition: background-color 0.3s ease-in-out;
    width: 100%;

    > svg {
      margin-right: 18px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.icons.hover};
    }
  }
`;

export const Dropdown = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  z-index: 9999;
  color: ${({ theme }) => theme.font.default};

  &:after {
    content: '';
    width: 14px;
    height: 12px;
    display: block;
    background: transparent url(${ChevronIcon}) no-repeat 50% 50% / contain;
    margin-left: 10px;
    transition: transform 0.3s ease-in-out;
    transform: rotate(${({ isOpened }) => (isOpened ? 180 : 0)}deg);
  }

  @media screen and ${breakpoint.md} {
    &:after {
      all: unset;
    }
  }

  ${({ isOpened }) =>
    isOpened
      ? css`
          ${Menu} {
            box-shadow: 0px 1px 9px 3px rgb(41 35 92 / 20%);
            max-height: 300px;
            padding: 16px;
          }
        `
      : null}

  ${({ allowToHide }) =>
    allowToHide === true
      ? css`
          margin-left: 20px;
        `
      : null}
  
  &.dashboard {
    margin-top: 24px;
  }
`;

export const FullName = styled.p`
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  line-height: 22px;
  text-align: left;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: ${({ theme }) => `1px solid ${theme.border.default}`};
`;

export const Icon = styled.div`
  background-color: #29235c;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  height: 24px;
  width: 24px;
`;

export const UserIconContainer = styled.div`
  align-items: center;
  padding: 16px;

  @media screen and ${breakpoint.lg} {
    padding: 4px;
  }
`;
