import React, { useMemo } from 'react';
import xlsx from 'json-as-xlsx';
import { Button, ExcelIcon } from '@containers/Dashboard/styles';

const DATE_KEY = 'date';
const DATE_FORMAT = 'd-mmm-yy';

const ExcelDownload = ({
  data,
  name,
  keys,
  rowKey,
  formatLabel,
  isPieChart,
}) => {
  const settings = useMemo(
    () => ({
      fileName: `briefcase-dashboard-${name}`,
      extraLength: 3,
      writeOptions: {},
    }),
    [name],
  );

  const columns = useMemo(() => {
    let excelColumns = [];
    if (isPieChart) {
      excelColumns = [
        { label: 'Name', value: 'name' },
        { label: 'Value', value: 'value' },
      ];
    } else if (keys) {
      if (keys.label === DATE_KEY) {
        excelColumns.push({
          label: formatLabel ? formatLabel(rowKey) : rowKey,
          value: rowKey,
          format: DATE_FORMAT,
        });
      } else {
        excelColumns.push({
          label: formatLabel ? formatLabel(rowKey) : rowKey,
          value: rowKey,
        });
      }
      keys.forEach((key) => {
        excelColumns.push({
          label: formatLabel ? formatLabel(key.name) : key.name,
          value: key.name,
        });
      });
    }
    return excelColumns;
  }, [isPieChart, keys, formatLabel, rowKey, data]);

  const content = useMemo(
    () =>
      data?.map((obj) =>
        obj?.date ? { ...obj, date: new Date(obj.date) } : obj,
      ),
    [data],
  );

  const handleExcelDownload = () => {
    const excelData = [
      {
        sheet: name,
        columns,
        content,
      },
    ];
    xlsx(excelData, settings);
  };

  return (
    <Button onClick={handleExcelDownload}>
      Excel
      <ExcelIcon stroke-width={1} stroke="#6793FF" />
    </Button>
  );
};

export default ExcelDownload;
