import React, { useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ChartAreaLine, ChartInfographic } from 'tabler-icons-react';
import { toBlob } from 'html-to-image';
import { saveAs } from 'file-saver';
import {
  DropdownsContainer,
  SwitchesContainer,
  TotalCoverageSettingsContainer,
} from '@containers/Dashboard/components/TotalCoverage/style';
import BarChart from '@components/BarChart';
import { mapCompaniesColors } from '@utils/chartHelpers';
import LineChart from '@components/LineChart';
import PieChart from '@components/PieChart';
import {
  CHART_TYPES,
  COMPANY_COLORS_TOOLTIP,
  INTERVALS,
  SHOW_AGGREGATES_TOOLTIP,
} from '@root/constants/dashboard';
import DashboardDropdown from '@components/DashboardSelect';
import Switch from '@components/Switch';
import Preloader from '@components/Preloader';
import { ChartContainer, ChartTitle } from '@containers/Dashboard/styles';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { DashboardExportDropdown } from '@components/DashboardExportDropdown';

const CHART_MAP = {
  bar: 'bar',
  stacked100: 'bar',
  stacked: 'bar',
  line: 'line',
  pie: 'pie',
};

const TotalCoverage = ({
  dashboardStore: {
    totalCoverageChartData,
    selectedCompanies,
    totalCoverageInterval: interval,
    setTotalCoverageInterval: setInterval,
    totalCoverageChartType: chartType,
    setTotalCoverageChartType: setChartType,
    isLoadingTotalCoverage,
    selectedCompaniesObjects,
  },
  sidebarStore: { menuCollapsed },
}) => {
  const [randomizeColors, setRandomizeColors] = useState(false);
  const [showAggregate, setShowAggregate] = useState(false);

  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const chartId = 'total-coverage-chart';

  const settingsInColumn =
    !menuCollapsed && matchBreakpoint(BREAKPOINTS.LG) && !isMobile
      ? 'column'
      : '';

  const keyNames = useMemo(
    () => mapCompaniesColors(selectedCompaniesObjects),
    [selectedCompaniesObjects],
  );

  const data = useMemo(() => {
    if (chartType === 'stacked100') {
      return totalCoverageChartData.percentData;
    }
    if (interval === 'total' && chartType !== 'pie') {
      return totalCoverageChartData.rawData;
    }

    if (chartType === 'pie') {
      return totalCoverageChartData.pieData;
    }
    return totalCoverageChartData.data;
  }, [
    totalCoverageChartData,
    chartType,
    interval,
    isLoadingTotalCoverage,
    selectedCompanies,
  ]);

  const noData = useMemo(
    () => !data || (data && data.length === 0) || selectedCompanies.size === 0,
    [data, selectedCompanies],
  );

  if (isLoadingTotalCoverage) {
    return (
      <ChartContainer className="empty">
        <Preloader />
      </ChartContainer>
    );
  }

  const saveImage = async () => {
    const name = `briefcase-dashboard-${chartId}.png`;
    const chart = document.getElementById(chartId);

    const chartBackground = chart.style.backgroundColor;
    chart.style.backgroundColor = 'white';

    const blob = await toBlob(chart, {});
    saveAs(blob, name);

    chart.style.backgroundColor = chartBackground;
  };

  return (
    <ChartContainer>
      <div id={chartId}>
        {!isMobile ? <ChartTitle>Total Coverage</ChartTitle> : null}
        {noData ? (
          <ChartContainer className="empty">
            No data, please use filters
          </ChartContainer>
        ) : (
          <>
            {CHART_MAP[chartType] === 'bar' ? (
              <BarChart
                isMobile={isMobile}
                chartType={chartType}
                data={data}
                keys={keyNames}
                dataKey={interval === 'total' ? 'name' : 'date'}
                useCompanyColor={!randomizeColors}
                aggregateResults={showAggregate}
                yAxisLabel={
                  chartType === 'stacked100'
                    ? 'Percentage of articles'
                    : 'Number of articles'
                }
                dateFormat={interval === 'month' ? 'MMM' : 'DD-MM'}
                dateFormatTooltip={interval === 'month' ? 'MMMM YYYY' : 'DD-MM'}
              />
            ) : null}
            {CHART_MAP[chartType] === 'line' ? (
              <LineChart
                isMobile={isMobile}
                chartType={chartType}
                data={data}
                keys={keyNames}
                dataKey={interval === 'total' ? 'name' : 'date'}
                useCompanyColor={!randomizeColors}
                aggregateResults={showAggregate}
                yAxisLabel={
                  chartType === 'stacked100'
                    ? 'Percentage of articles'
                    : 'Number of articles'
                }
                dateFormat={interval === 'month' ? 'MMM' : 'DD-MM'}
                dateFormatTooltip={interval === 'month' ? 'MMMM YYYY' : 'DD-MM'}
              />
            ) : null}
            {CHART_MAP[chartType] === 'pie' ? (
              <PieChart
                chartData={data.slice().reverse()}
                title="pie-chart"
                dataKey="value"
                companyNames={keyNames}
                useCompanyColors={!randomizeColors}
                customTooltip
                tooltipData={data}
              />
            ) : null}
          </>
        )}
      </div>
      <TotalCoverageSettingsContainer
        className={`${chartType} ${settingsInColumn}`}
      >
        {chartType !== 'pie' ? (
          <SwitchesContainer>
            <Switch
              onChange={setShowAggregate}
              checkId={() => {}}
              label="Aggregate"
              name="show-aggregate"
              value={showAggregate}
              mobileColumn
              isDashboard
              tooltipText={SHOW_AGGREGATES_TOOLTIP}
            />
            <Switch
              onChange={setRandomizeColors}
              checkId={() => {}}
              label="Randomise colours"
              name="company-colors"
              value={randomizeColors}
              mobileColumn
              isDashboard
              tooltipText={COMPANY_COLORS_TOOLTIP}
            />
          </SwitchesContainer>
        ) : null}
        <DropdownsContainer className={settingsInColumn}>
          <DashboardDropdown
            id="chart-type-select"
            name="ChartType"
            // prefix={isMobile ? '' : 'Chart type: '}
            onChange={setChartType}
            value={chartType}
            options={CHART_TYPES}
            placeholder="Chart type"
            icon={<ChartAreaLine size={18} />}
          />
          <DashboardDropdown
            minWidth={170}
            id="interval-select"
            name="Interval"
            // prefix={isMobile ? '' : 'Interval: '}
            onChange={setInterval}
            value={interval}
            options={INTERVALS}
            placeholder="Interval"
            icon={<ChartInfographic size={18} />}
          />
        </DropdownsContainer>
      </TotalCoverageSettingsContainer>
      <DropdownsContainer className="with-margin">
        <DashboardExportDropdown
          data={data}
          name="total-coverage"
          isPieChart={chartType === 'pie'}
          keyNames={keyNames}
          rowKey={interval === 'total' ? 'name' : 'date'}
          saveImage={saveImage}
        />
      </DropdownsContainer>
    </ChartContainer>
  );
};

TotalCoverage.propTypes = {
  dashboardStore: PropTypes.shape({
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default inject(
  'dashboardStore',
  'sidebarStore',
)(observer(TotalCoverage));
