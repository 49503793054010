import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const TotalCoverageSettingsContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  &.pie {
    justify-content: flex-end;
  }

  @media screen and ${breakpoint.md} {
    margin-top: 0;
    flex-direction: column;
    padding: 0 10px 10px 10px;
    gap: 32px;
  }

  &.column {
    flex-direction: column;
  }
`;

export const SwitchesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 32px;

  @media screen and ${breakpoint.md} {
    justify-content: center;
  }

  @media screen and ${breakpoint.sm} {
    justify-content: space-around;
  }
`;

export const DropdownsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  @media screen and ${breakpoint.md} {
    justify-content: center;
  }

  &.with-margin {
    margin-top: 12px;
  }

  &.column {
    margin-top: 16px;
    justify-content: flex-start;
  }
`;
