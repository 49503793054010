import { runInAction, makeAutoObservable } from 'mobx';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';
import { groupBy } from '@utils/briefingsUtils';

export class CompanyStore {
  isLoading = { banner: false, news: false };

  error = false;

  company = {};

  sections = [];

  news = { sections: [], links: {} };

  showNewContentBanner = false;

  loadingMore = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchCompany = async (id, sectionId) => {
    runInAction(() => {
      this.isLoading.banner = true;
    });

    try {
      if (this.company) {
        const { data } = await API.get(apiRoutes.singleCompany(id));
        const { data: sections } = await API.get(apiRoutes.companySections(id));
        const sectionsGroupedByName = groupBy(sections.items, 'name');

        const sectionsWithIdsArray = [];
        Object.keys(sectionsGroupedByName).forEach((name) => {
          const ids = sectionsGroupedByName[name].map(({ id: sId }) => sId);
          sectionsWithIdsArray.push({
            name,
            company_id: id,
            ids,
          });
        });

        runInAction(() => {
          this.company = data;
          this.sections = [
            { name: 'latest', company_id: 'latest', ids: ['latest'] },
            ...sectionsWithIdsArray,
          ];
        });
      }

      if (sectionId === 'latest') {
        const { data: news } = await API.get(apiRoutes.companyNews(id));
        runInAction(() => {
          if (!news.sections.data) this.news = news;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.isLoading.banner = false;
      });
    }
  };

  setShowNewContentBanner = (value) => {
    this.showNewContentBanner = value;
  };

  checkForNewContent = async (companyId, sectionId) => {
    try {
      let url = '';
      if (sectionId === 'latest') {
        url = apiRoutes.companyNews(companyId);
      } else {
        url = apiRoutes.specificSection(companyId, sectionId);
      }

      const {
        data: { sections },
      } = await API.get(url, {
        params: { limit: 1 },
      });

      const { date: publicationDate, id } = sections[0].data[0];

      if (publicationDate && id && !this.news.sections[0]) {
        this.showNewContentBanner = true;
      }

      const { date: publicationDateTop, id: idTop } =
        this.news.sections[0].data[0];

      if (
        publicationDate !== publicationDateTop ||
        id !== idTop ||
        !this.news.sections[0]
      ) {
        this.showNewContentBanner = true;
      }
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
  };

  fetchSection = async (companyId, sectionId) => {
    try {
      runInAction(() => {
        this.isLoading.news = true;
      });

      if ((sectionId.length === 1 && sectionId[0] === 'latest') || sectionId === 'latest') {
        const { data: latestNews } = await API.get(
          apiRoutes.companyNews(companyId),
        );
        runInAction(() => {
          this.news = latestNews;
        });
      } else {
        const { data: news } = await API.get(
          apiRoutes.specificSection(companyId, sectionId),
        );

        runInAction(() => {
          if (news.sections.length === 0) {
            this.news = { sections: [], links: {} };
          } else {
            this.news = news;
          }
        });
      }
    } catch (error) {
      this.error = error;
    } finally {
      runInAction(() => {
        this.isLoading.news = false;
      });
    }

    return null;
  };

  mergeNews = (sections) => {
    const lastSectionIndex = this.news.sections.length - 1;
    const lastSection = this.news.sections[lastSectionIndex];
    const [firstNewSection] = sections;

    const newSections = [...this.news.sections];
    // if first new section should be included to the last of existing
    if (
      lastSection.sectionTitle === firstNewSection.sectionTitle &&
      lastSection.publicationDate === firstNewSection.publicationDate
    ) {
      const updatedLastSection = {
        ...lastSection,
        data: [...lastSection.data, ...firstNewSection.data],
      };

      newSections[newSections.length - 1] = updatedLastSection;
      sections.shift();
    }
    this.news.sections = [...newSections, ...sections];
  };

  loadMore = async () => {
    runInAction(() => {
      this.loadingMore = true;
    });

    try {
      const { data } = await API.get(this.news.links.next);
      runInAction(() => {
        this.news.links = data.links;
        this.mergeNews(data.sections);
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loadingMore = false;
      });
    }
  };
}

export default new CompanyStore();
