import React from 'react';
import Select from 'react-select';
import {
  Container,
  Label,
  ErrorText,
  ReactSelectStyles,
} from '@components/Select/styles';

function Dropdown({
  onChange,
  options,
  label,
  error,
  placeholder,
  value,
  name,
  id,
  onBlur,
  disableSearch,
  defaultValue,
}) {
  const selectId = `dropdown_${name}-${id}`;

  return (
    <Container className="custom-select" error={error}>
      <Label htmlFor={selectId}>{label}</Label>
      <Select
        id={selectId}
        options={options}
        name={name}
        classNamePrefix="react-select"
        onChange={(option) => {
          onChange(option.value);
        }}
        onBlur={onBlur}
        styles={ReactSelectStyles}
        defaultValue={defaultValue}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        placeholder={placeholder}
        value={options?.find((x) => x.value === value)}
        isSearchable={!disableSearch}
      />

      {error && (
        <ErrorText className="error-message">{error.message}</ErrorText>
      )}
    </Container>
  );
}

Dropdown.defaultProps = {
  placeholder: null,
  id: null,
  onBlur: null,
  disableSearch: false,
};

export default Dropdown;
