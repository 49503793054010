import styled from '@emotion/styled';

export const ContactBox = styled.div`
  padding-inline: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
  margin-bottom: ${({ isMobile }) => (isMobile ? '40' : '70')}px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;
export const Container = styled.div`
  width: 100%;
  max-width: 1134px;
`;
export const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  max-width: 1134px;
  padding-bottom: 30px;
  padding-inline: 30px;
`;
export const ImgSmall = styled.img`
  z-index: 1;
  position: relative;
  width: 190%;
  height: auto;
  top: 10%;
  right: 96%;
`;
export const ImgBig = styled.img`
  position: relative;
  height: auto;
  width: 100%;
  z-index: 10;
  box-shadow: 13px 12px 16px rgb(0 0 0 / 30%);
`;
export const ImageWrapper = styled.div`
  width: 70%;
`;
export const SmallImageWrapper = styled.div`
  width: 30%;
  box-shadow: 13px 12px 16px rgb(0 0 0 / 30%);
`;
export const Flex = styled.div`
  padding-inline: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Text = styled.p`
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSizeXXL}px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.secondary};
  text-align: center;
`;
export const ContactText = styled.p`
  font-weight: 700;
  font-size: ${({ theme, bigger }) =>
    bigger ? theme.defaults.fontSizeXXL : theme.defaults.fontSizeXL}px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.secondary};
`;
export const LinkWrapper = styled.div`
  padding-top: ${({ isMobile }) => (isMobile ? '30' : '0')}px;
`;
