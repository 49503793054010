import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { breakpoint } from '@styles/breakpoints';

export const Section = styled.div`
  margin-bottom: 30px;

  @media screen and ${breakpoint.md} {
    margin-bottom: 28px;
  }
`;

export const Header = styled.h2`
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSizeXXL}px;
  line-height: 22px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.secondary};
`;
export const Text = styled.p`
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  line-height: ${({ isValue }) => (isValue ? '26px' : '22px')};
  color: ${({ theme, isValue }) =>
    isValue ? theme.colors.secondary : theme.font.secondary};
`;

export const Divider = styled.div`
  height: 1px;
  margin-top: 30px;
  margin-bottom: 15px;
  background-color: ${({ theme }) => theme.font.secondary};
`;

export const ButtonTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.secondary};
`;
