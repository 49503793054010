import React, { useEffect, useState, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import { SearchContainer } from '@components/MobileExtendedSearchBar/styles';
import DateRangeSelector from '@components/DatesRangeSelector';
import SearchBar from '@components/SearchBar';

const MobileExtendedSearchBar = ({
  dataRangePickerStore: { isMobileExpanded, handleMobileExpand },
  placeholder,
  repairedMargin,
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const { search, setSearch } = useCustomSearchParams();
  const [searchBarHidden, setSearchBarHidden] = useState(false);

  const fromDate = useMemo(
    () => (search.from ? moment(search.from) : null),
    [search.from],
  );
  const toDate = useMemo(
    () => (search.to ? moment(search.to) : null),
    [search.to],
  );

  const changeDatesHandler = (dates) => {
    if (dates.startDate && dates.endDate && dates.startDate < dates.endDate) {
      setSearch({
        from: moment(dates.startDate).startOf('day').toISOString(),
        to: moment(dates.endDate).endOf('day').toISOString(),
      });
    } else {
      setSearch({});
    }
  };

  useEffect(() => {
    let oldValue = 0;
    let newValue = 0;
    const scrollEvent = () => {
      newValue = window.pageYOffset;
      if (oldValue < newValue) {
        setSearchBarHidden(true);
        handleMobileExpand(false);
      } else if (oldValue > newValue) {
        setSearchBarHidden(false);
      }
      oldValue = newValue;
    };
    window.addEventListener('scroll', scrollEvent);
    return window.removeEventListener('scroll', scrollEvent);
  }, []);

  return isMobile ? (
    <SearchContainer
      searchBarHidden={searchBarHidden}
      isCalendarExpended={isMobileExpanded}
      repairedMargin={repairedMargin}
    >
      <SearchBar placeholder={placeholder} withoutButton extendedSearch />
      <DateRangeSelector
        onChange={changeDatesHandler}
        startDate={fromDate}
        endDate={toDate}
      />
    </SearchContainer>
  ) : null;
};

MobileExtendedSearchBar.propTypes = {
  placeholder: PropTypes.string,
  repairedMargin: PropTypes.bool,
};

MobileExtendedSearchBar.defaultProps = {
  placeholder: 'Search',
  repairedMargin: false,
};

export default inject('dataRangePickerStore')(
  observer(MobileExtendedSearchBar),
);
