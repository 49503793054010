import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const TAGS = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  SPAN: 'span',
  P: 'p',
  DIV: 'DIV',
};

export const COLORS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
};

export const TYPES = {
  PAGE_TITLE: 'page-title',
  BODY: 'body',
};

const defaultText = (theme) => css`
  font-size: ${theme.defaults.fontSize};
  color: ${theme.colors.primary};
`;

const h1 = (theme) => css`
  font-size: ${theme.defaults.fontSizeXXL}px;
  line-height: 33px;
  color: ${theme.font.default};
  font-weight: ${theme.defaults.fontWeight.bold};

  @media only screen and ${breakpoint.md} {
    font-size: ${theme.defaults.fontSizeXL}px;
  }
`;

const h2 = (theme) => css`
  font-size: ${theme.defaults.fontSizeXL}px;
  color: ${theme.font.dark};
  font-weight: ${theme.defaults.fontWeight.bold};
`;

export const Wrapper = styled.span`
  ${({ as, theme }) => {
    switch (as) {
      case TAGS.H1:
        return h1(theme);
      case TAGS.H2:
        return h2(theme);
      default:
        return defaultText(theme);
    }
  }}
`;
