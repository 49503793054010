import axios from 'axios';
import { authStore } from '@stores';
import { getItem, setItem, KEYS } from '@utils/cache';

const AUTH_TOKEN = getItem(KEYS.AUTH_TOKEN);
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

if (AUTH_TOKEN) {
  API.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
}
API.defaults.headers.common['Content-Type'] = 'application/json';
// API.defaults.headers.common['User-Agent']   = 'Briefcase App';

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      authStore.logout();
    }
    return Promise.reject(error.response.data);
  },
);

export const setAuthenticationToken = (token, email) => {
  setItem(KEYS.AUTH_TOKEN, token);
  if (email) {
    setItem(KEYS.EMAIL, email);
  }
  API.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default API;
