import React from 'react';
import {
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  LineChart as Chart,
  ResponsiveContainer,
  Line,
} from 'recharts';
import styled from '@emotion/styled';
import { aggregateData, dateTickFormatter } from '@utils/chartHelpers';
import ChartTooltip from '@components/ChartTooltip';

const ChartContainer = styled.div`
  width: 100%;
  padding-bottom: 10px;
  height: 400px;
`;

const LineChart = ({
  isMobile,
  data,
  keys,
  dataKey,
  xAxisLabel,
  dateFormat,
  yAxisLabel,
  dateFormatTooltip,
  aggregateResults,
  useCompanyColor,
}) => (
  <ChartContainer>
    <ResponsiveContainer width="100%" height={400}>
      <Chart
        data={aggregateResults ? aggregateData(data) : data}
        margin={{
          top: 5,
          right: 10,
          left: isMobile ? -16 : 0,
        }}
      >
        <CartesianGrid strokeDasharray="4 3" stroke="rgba(0,0,0,.1)" />
        <XAxis
          tick={{ fill: 'black' }}
          height={50}
          dataKey={dataKey}
          stroke="#aaaaaa"
          tickFormatter={(tick) => dateTickFormatter(tick, dataKey, dateFormat)}
          label={{
            value: xAxisLabel,
            offset: 10,
            position: 'insideBottom',
            fill: '#aaaaaa',
          }}
        />
        <YAxis
          tick={{ fill: 'black', marginLeft: 20 }}
          stroke="#aaaaaa"
          label={{
            value: yAxisLabel,
            angle: -90,
            position: 'center',
            fill: '#000000',
            dx: -20,
          }}
        />
        <Tooltip
          wrapperStyle={{ zIndex: 9 }}
          content={
            <ChartTooltip
              dateFormat={dataKey === 'date' && dateFormatTooltip}
            />
          }
        />
        {(aggregateResults ? [{ name: 'Total' }] : keys).map((key) => (
          <Line
            key={`${key.name}-line`}
            dot={false}
            strokeWidth={4}
            connectNulls
            type="linear"
            dataKey={key.name}
            stroke={useCompanyColor ? key.companyColor : key.color}
          />
        ))}
      </Chart>
    </ResponsiveContainer>
  </ChartContainer>
);

LineChart.propTypes = {};

export default LineChart;
