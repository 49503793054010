import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Container, LeftSide, Wrapper } from '@components/Navbar/style';
import Preloader, { POSITIONS, SIZES } from '@components/Preloader';
import UserMenu from '@components/UserMenu';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import DateRangeSelector from '@components/DatesRangeSelector';
import useBreakpoint from '@root/hooks/useBreakpoint';

const SCROLL_OFFSET = 100;

const Navbar = ({
  userStore: { isLoading, user },
  children,
  sidebarStore: { menuCollapsed },
}) => {
  const { pathname } = useLocation();
  const [allowToHide, setAllowToHide] = useState(false);
  const [navbarHidden, setNavbarHidden] = useState(false);
  const [withCalendar, setWithCalendar] = useState(false);
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isDashboard = useMemo(
    () => pathname.includes('/dashboard'),
    [pathname],
  );

  const thickNavbar = useMemo(() => {
    if (pathname.includes('/briefings/all')) {
      return false;
    }

    if (
      pathname.includes('/my-news') ||
      pathname.includes('/briefings') ||
      pathname.includes('/company')
    ) {
      return true;
    }

    return false;
  }, [pathname]);

  const isDashboardTwoRows = matchBreakpoint(
    menuCollapsed ? BREAKPOINTS.LG : BREAKPOINTS.XL,
  );

  useEffect(() => {
    const setNavbar = (toHide, calendar) => {
      setAllowToHide(toHide);
      setWithCalendar(calendar);
    };

    if (pathname.includes('/companies')) {
      return setNavbar(true, false);
    }

    if (pathname.includes('/my-news') || pathname.includes('/company')) {
      return setNavbar(true, true);
    }

    if (
      pathname.includes('/briefings') &&
      !pathname.includes('/briefings/all')
    ) {
      return setNavbar(false, true);
    }

    return setNavbar(false, false);
  }, [pathname]);

  useEffect(() => {
    let oldValue = 0;
    let newValue = 0;
    window.addEventListener('scroll', () => {
      newValue = window.pageYOffset;
      if (oldValue < newValue && newValue > SCROLL_OFFSET) {
        setNavbarHidden(true);
      } else if (oldValue > newValue) {
        setNavbarHidden(false);
      }
      oldValue = newValue;
    });
  }, []);

  const { search, setSearch } = useCustomSearchParams();

  const fromDate = useMemo(
    () => (search.from ? moment(search.from) : null),
    [search.from],
  );
  const toDate = useMemo(
    () => (search.to ? moment(search.to) : null),
    [search.to],
  );

  const changeDatesHandler = (dates) => {
    if (dates.startDate && dates.endDate && dates.startDate < dates.endDate) {
      setSearch({
        from: moment(dates.startDate).startOf('day').toISOString(),
        to: moment(dates.endDate).endOf('day').toISOString(),
      });
    } else {
      setSearch({});
    }
  };

  return (
    <Wrapper
      allowToHide={allowToHide}
      navbarHidden={navbarHidden}
      withCalendar={withCalendar}
      menuCollapsed={menuCollapsed}
      thickNavbar={thickNavbar}
    >
      <Container
        className={isDashboard ? 'dashboard' : ''}
        allowToHide={allowToHide}
        thickNavbar={thickNavbar}
      >
        {children ? <LeftSide>{children}</LeftSide> : null}
        {isLoading || !user?.fullName ? (
          <Preloader display={POSITIONS.HORIZONRAL} size={SIZES.SMALL} />
        ) : (
          <>
            {withCalendar && (
              <DateRangeSelector
                onChange={changeDatesHandler}
                startDate={fromDate}
                endDate={toDate}
              />
            )}
            <UserMenu
              fullName={user.fullName}
              firstName={user.firstName}
              lastName={user.lastName}
              allowToHide={allowToHide}
              isDashboard={isDashboard && isDashboardTwoRows}
              userEmail={user.email}
            />
          </>
        )}
      </Container>
    </Wrapper>
  );
};

Navbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  userStore: PropTypes.shape({
    isLoading: PropTypes.bool,
    user: PropTypes.shape({
      accessRights: PropTypes.shape({
        allow_access_to_companies: PropTypes.bool,
        allow_access_to_briefings: PropTypes.bool,
      }),
      allEmailNotificationsEnabled: PropTypes.bool,
      allPushNotificationsEnabled: PropTypes.bool,
      anonymous: PropTypes.bool,
      company: PropTypes.string,
      dashboardSettings: PropTypes.shape({
        type: PropTypes.string,
        enabled: PropTypes.bool,
      }),
      email: PropTypes.string,
      externalId: PropTypes.string,
      firstName: PropTypes.string,
      fullName: PropTypes.string,
      id: PropTypes.number,
      intercomHash: PropTypes.string,
      lastName: PropTypes.string,
      linksInContentPublicationsAvailable: PropTypes.bool,
      subscriptions: PropTypes.arrayOf(
        PropTypes.shape({
          access_to: PropTypes.string,
          contractType: PropTypes.string,
          date: PropTypes.string,
          label_key: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
      title: PropTypes.string,
    }),
  }).isRequired,
};

Navbar.defaultProps = {
  children: null,
};

export default inject('userStore', 'sidebarStore')(observer(Navbar));
