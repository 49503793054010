import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ContentWrapper } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.header`
  background-color: ${({ theme }) => theme.colors.navbar};
  width: 100%;
  height: 104px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 20;
  transition: all 0.4s;

  ${({ allowToHide, menuCollapsed, theme }) =>
    allowToHide === true
      ? css`
          position: fixed;
          top: 0px;
          width: calc(
            100% -
              ${menuCollapsed === true ? 96 : theme.defaults.desktopMenuWidth}px
          );
        `
      : null}

  ${({ navbarHidden, allowToHide }) =>
    navbarHidden === true && allowToHide === true
      ? css`
          top: -120px;

          @media only screen and ${breakpoint.xl} {
            top: -135px;
          }
        `
      : null}

      ${({ withCalendar }) => (withCalendar === true ? css`` : null)}
`;

export const Container = styled(ContentWrapper)`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  padding: 32px 144px;
  justify-content: space-between;
  position: relative;

  ${({ thickNavbar }) =>
    thickNavbar === true
      ? css`
          button[type='submit'] {
            margin-right: 18px;
          }

          .input-container {
            min-width: 300px;
          }

          @media only screen and ${breakpoint.xl} {
            align-items: center;
            padding: 9px 32px;
            gap: 5px;

            .user-menu {
              position: relative;
              top: 25px;
            }

            .date-range-picker {
              order: 10;
            }
          }

          @media only screen and ${breakpoint.lg} {
            padding: 9px 16px;
            .input-container {
              min-width: 250px;
            }

            button[type='submit'] {
              margin-right: 10px;
            }

            button[type='button'] {
              margin-left: 0;
            }
          }
        `
      : css`
          @media only screen and ${breakpoint.lg} {
            align-items: center;
          }
        `};
`;

export const LeftSide = styled.div`
  flex: 1;
`;
