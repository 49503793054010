import styled from '@emotion/styled';
import { Eye, EyeOff, InfoCircle } from 'tabler-icons-react';

export const DashboardSettingsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 10px;

  &.row {
    flex-wrap: wrap;
    padding-right: 0;
  }
`;

export const ManageButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.row {
    flex-direction: row;
  }
`;

export const OtherSettingsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  &.row {
    margin-top: 4px;
    justify-content: flex-start;
  }
`;

export const Button = styled.button`
  width: 100%;
  max-width: 160px;
  background: ${({ theme }) => theme.colors.navbar};
  border-radius: 4px;
  text-transform: ${({ noUppercase }) => (noUppercase ? 'none' : 'uppercase')};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  height: 40px;
  cursor: pointer;
  &:hover {
    background-color: rgba(103, 147, 255, 0.05);
  }
`;

export const SelectedCompaniesLabel = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  line-height: 24px;
  color: ${({ theme }) => theme.font.secondary};
  gap: 2px;

  &.row {
    line-height: 40px;
    margin-left: 16px;
  }
`;

export const BespokeButton = styled.button`
  display: flex;
  justify-content: space-between;
  width: ${({ showBespokeLabel }) => (showBespokeLabel ? 140 : 210)}px;
  background: ${({ theme }) => theme.colors.navbar};
  border-radius: 4px;
  color: ${({ showBespoke, theme }) =>
    showBespoke ? theme.colors.secondary : theme.colors.inputLabel};
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  padding: 0 10px;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const SelectContainer = styled.div`
  margin-left: 24px;
  height: 40px;
  min-width: 135px;
`;

export const EyeIcon = styled(Eye)`
  margin: auto 0;
`;

export const EyeOffIcon = styled(EyeOff)`
  margin: auto 0;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  margin-top: 8px;
  margin-right: ${({ showBespokeLabel }) => (showBespokeLabel ? 0 : 24)}px;
  color: ${({ showBespoke, theme }) =>
    showBespoke ? theme.colors.secondary : theme.colors.inputLabel};

  &.no-margins {
    margin-top: 0px;
    margin-right: 0px;
  }
`;
