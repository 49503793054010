import React, { useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { toBlob } from 'html-to-image';
import { saveAs } from 'file-saver';
import Preloader from '@components/Preloader';
import {
  Button,
  ButtonsContainer,
  ChartContainer,
  ChartTitle,
  HeartIcon,
  InfoCircleIcon,
  StyledTooltip,
} from '@containers/Dashboard/styles';
import { SENTIMENT_TOOLTIP, SENTIMENTS } from '@root/constants/dashboard';
import VerticalBarChart from '@components/VerticalBarChart';
import CustomChartLegend from '@components/CustomChartLegend';
import { formatName, titleCase } from '@utils/chartHelpers';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { DashboardExportDropdown } from '@components/DashboardExportDropdown';

const TopJournalists = ({
  dashboardStore: {
    selectedCompanies,
    isLoadingTopJournalists,
    topJournalistsChartData,
  },
}) => {
  const [showSentiment, setShowSentiment] = useState(true);
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const chartId = 'top-journalists-chart';
  const buttonsContainer1Id = 'top-journalists-chart-buttons-1';
  const buttonsContainer2Id = 'top-journalists-chart-buttons-2';
  const undisclosedTooltipId = 'undisclosed-top-journalists-tooltip';

  const noData = useMemo(
    () => topJournalistsChartData.length === 0 || selectedCompanies.size === 0,
    [selectedCompanies, topJournalistsChartData],
  );

  const sentimentTotalKeys = useMemo(
    () => [{ name: 'sum', color: '#aaaaaaff', label: 'total' }],
    [],
  );
  const sentimentKeyNames = useMemo(
    () => (showSentiment ? SENTIMENTS : sentimentTotalKeys),
    [showSentiment],
  );
  if (isLoadingTopJournalists) {
    return (
      <ChartContainer className="half empty">
        <Preloader />
      </ChartContainer>
    );
  }

  const saveImage = async () => {
    const name = `briefcase-dashboard-${chartId}.png`;
    const chart = document.getElementById(chartId);
    const buttons1 = document.getElementById(buttonsContainer1Id);
    const buttons2 = document.getElementById(buttonsContainer2Id);
    const undisclosedTooltip = document.getElementById(undisclosedTooltipId);
    const chartBackground = chart.style.backgroundColor;

    chart.style.backgroundColor = 'white';
    buttons1.style.display = 'none';
    buttons2.style.display = 'none';
    undisclosedTooltip.style.display = 'none';

    const blob = await toBlob(chart, {});
    saveAs(blob, name);

    buttons1.style.display = 'flex';
    buttons2.style.display = 'flex';
    undisclosedTooltip.style.display = 'inline-block';
    chart.style.backgroundColor = chartBackground;
  };

  return (
    <ChartContainer id={chartId} className={isMobile ? null : 'half'}>
      {!isMobile ? <ChartTitle>Top Journalists by Volume</ChartTitle> : null}
      {noData ? (
        <ChartContainer className="empty">
          No data, please use filters
        </ChartContainer>
      ) : (
        <>
          <VerticalBarChart
            hideLegend
            marginBottom={10}
            marginLeft={10}
            customLegend
            dataKey="journalist"
            showSentiment={showSentiment}
            setShowSentiment={setShowSentiment}
            maxBarHeight={30}
            chartData={topJournalistsChartData}
            keyNames={sentimentKeyNames}
            title="Top Journalists"
          />
          <CustomChartLegend
            tooltipId={undisclosedTooltipId}
            showSentiment={showSentiment}
            setShowSentiment={setShowSentiment}
            payload={
              sentimentKeyNames.length > 1
                ? sentimentKeyNames.map(({ name, color }) => ({
                    value: formatName(name === 'sum' ? 'total' : name),
                    color,
                  }))
                : []
            }
          />
          <StyledTooltip />
          <ButtonsContainer id={buttonsContainer1Id}>
            <Button onClick={() => setShowSentiment(!showSentiment)}>
              {showSentiment ? 'Hide sentiment' : 'Show sentiment'}
              <InfoCircleIcon
                size={16}
                data-tip={SENTIMENT_TOOLTIP}
                data-place="bottom"
              />
              <HeartIcon
                size={24}
                className={showSentiment ? 'filled' : null}
              />
            </Button>
          </ButtonsContainer>
          <ButtonsContainer id={buttonsContainer2Id} className="small-margin">
            <DashboardExportDropdown
              data={topJournalistsChartData}
              name="top-journalists"
              isPieChart={false}
              keyNames={[...SENTIMENTS, { name: 'sum', label: 'total' }]}
              rowKey="journalist"
              formatLabel={(value) => titleCase(value.replace('_', ' '))}
              saveImage={saveImage}
            />
          </ButtonsContainer>
        </>
      )}
    </ChartContainer>
  );
};

TopJournalists.propTypes = {
  dashboardStore: PropTypes.shape({
    selectedCompanies: PropTypes.any.isRequired,
    isLoadingTopJournalists: PropTypes.bool.isRequired,
    topJournalistsChartData: PropTypes.array.isRequired,
  }).isRequired,
};

export default inject('dashboardStore')(observer(TopJournalists));
