import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import routes from '@routes';
import PersonalData from '@containers/Settings/PersonalData';
import PasswordChange from '@containers/Settings/PasswordChange';
import Notifications from '@containers/Settings/Notifications';
import Following from '@containers/Settings/Following';

import {
  SettingsContainer,
  ContentWrapper,
  AccountWrapper,
  NotificationsWrapper,
  ColumnHeader,
  SectionHeader,
  SectionContainer,
  SubscriptionContent,
  SubscriptionText,
  SubscriptionExpiresDate,
  MobileMenu,
  MenuItem,
} from '@containers/Settings/styles';
import ChangeAppSize from '@containers/Settings/ChangeAppSize';

const Settings = ({
  settingsStore: { handleChangeSection, section, subscriptions },
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SettingsContainer>
      <MobileMenu section={section}>
        <MenuItem onClick={() => handleChangeSection('account')}>
          Account
        </MenuItem>
        <MenuItem onClick={() => handleChangeSection('notifications')}>
          Notifications
        </MenuItem>
      </MobileMenu>
      <ContentWrapper>
        <AccountWrapper section={section}>
          <ColumnHeader>Account</ColumnHeader>
          <SectionContainer>
            <SectionHeader>Subscriptions</SectionHeader>
            {subscriptions.map(
              ({ access_to: accessTo, date, contractType }, idx) => (
                <SubscriptionContent
                  key={`subscription-container-${idx}`}
                  isClickable={contractType === 'B2C'}
                  onClick={() =>
                    contractType === 'B2C'
                      ? navigate(routes.subscriptionInfo(idx + 1))
                      : null
                  }
                >
                  <SubscriptionText>{accessTo}</SubscriptionText>
                  <SubscriptionExpiresDate>
                    Your plan expires on {moment(date).format('DD/MM/YY')}
                  </SubscriptionExpiresDate>
                </SubscriptionContent>
              ),
            )}
          </SectionContainer>
          <ChangeAppSize />
          <PersonalData />
          <PasswordChange />
        </AccountWrapper>
        <NotificationsWrapper section={section}>
          <ColumnHeader>Notifications</ColumnHeader>
          <Notifications />
          <Following />
        </NotificationsWrapper>
      </ContentWrapper>
    </SettingsContainer>
  );
};

export default inject('settingsStore')(observer(Settings));
