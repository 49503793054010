import styled from '@emotion/styled';
import { css } from '@emotion/react';
// eslint-disable-next-line import/no-cycle
import { SIZES } from '@components/Preloader';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => (size === SIZES.SMALL ? '30px' : 'auto')};

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
    max-width: 64px;
    margin: 0 auto;
    max-width: 32px;
    filter: ${({ theme }) =>
      `brightness(${theme.imageFilter.preloaderBrightness})`};
  }

  img:not([alt='loading']) {
    filter: none;
  }

  ${({ overlay, theme }) =>
    overlay
      ? css`
          align-items: flex-start;
          justify-content: flex-start;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, ${theme.opacity.preloader});
          z-index: 99;

          img {
            max-width: 64px;
            position: sticky;
            top: 40%;
            width: 64px;
            height: 64px;
          }
        `
      : null};
`;
