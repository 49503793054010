import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Calendar, ChevronLeft, ChevronRight, X } from 'tabler-icons-react';
import { CSSTransition } from 'react-transition-group';
import { DateRangePicker } from 'react-dates';
import momentPropTypes from 'react-moment-proptypes';
import ContentOverlay from '@components/ContentOverlay';
import useBreakpoint from '@root/hooks/useBreakpoint';
import {
  Overlay,
  NavButton,
  Wrapper,
  MobileTrigger,
} from '@components/DatesRangeSelector/styles';

const DateRangeSelector = ({
  onChange,
  startDate,
  endDate,
  constraintDate,
  isDashboard,
  alwaysExpanded,
  showClearDates,
  isMobileDashboard,
  allowOneDayRange=false,
  dataRangePickerStore: { handleMobileExpand, isMobileExpanded },
}) => {
  // const [mobilePickerVisible, setMobilePickerVisibility] = useState(false);
  const [currentStartDate, setStartDate] = useState(startDate);
  const [currentEndDate, setEndDate] = useState(endDate);
  const [focusedInput, setFocusedInput] = useState();
  const theme = useTheme();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(
    isDashboard ? BREAKPOINTS.XL : BREAKPOINTS.MD,
  );

  useEffect(() => {
    handleMobileExpand(alwaysExpanded);
  }, []);

  // useEffect(() => {
  //   if (isDashboard && focusedInput === null && isMobile) {
  //     handleMobileExpand(false);
  //   }
  // }, [focusedInput]);

  const openCalendarMobile = useCallback(() => {
    if (isMobileExpanded) {
      if (!isMobileDashboard) {
        handleMobileExpand(false);
      }
    } else {
      setFocusedInput('startDate');
      handleMobileExpand(true);
    }
  }, [isMobileExpanded]);

  const today = useMemo(() => moment(), []);

  const focusHandler = useCallback((input) => {
    setFocusedInput(input);
    if (input !== null && isMobileDashboard) {
      window.scrollTo({ top: 130, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (focusedInput === null) {
      onChange({ startDate: currentStartDate, endDate: currentEndDate });
    }
  }, [focusedInput, currentStartDate, currentEndDate]);

  useEffect(() => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, [startDate, endDate]);

  return (
    <Wrapper className="date-range-picker">
      {isMobile && !isMobileDashboard ? (
        <MobileTrigger
          type="button"
          onClick={openCalendarMobile}
          isMobileExpanded={isMobileExpanded}
        >
          <Calendar stroke={theme.inputs.secondary.icon} />
        </MobileTrigger>
      ) : null}

      {isMobile && !isMobileExpanded ? null : (
        <>
          <DateRangePicker
            startDatePlaceholderText="From date"
            endDatePlaceholderText="To date"
            anchorDirection="right"
            startDate={currentStartDate}
            startDateId="start-date"
            endDate={currentEndDate}
            endDateId="end-date"
            numberOfMonths={isMobile ? 1 : 2}
            onDatesChange={(dates) => {
              setStartDate(dates.startDate);
              setEndDate(dates.endDate);
              if (!dates.startDate && !dates.endDate) {
                onChange(dates);
              }
            }}
            focusedInput={focusedInput}
            onFocusChange={focusHandler}
            inputIconPosition="after"
            customInputIcon={
              <Calendar
                stroke={theme.inputs.secondary.icon}
                onClick={openCalendarMobile}
              />
            }
            hideKeyboardShortcutsPanel
            showClearDates={showClearDates}
            displayFormat="DD/MM/YYYY"
            isOutsideRange={(day) => day > today || day < constraintDate}
            initialVisibleMonth={() => moment().subtract(1, 'months')}
            navPrev={
              <NavButton dir="prev">
                <ChevronLeft />
              </NavButton>
            }
            navNext={
              <NavButton dir="next">
                <ChevronRight />
              </NavButton>
            }
            minimumNights={allowOneDayRange ? 0 : 1}
          />
          {currentStartDate || currentEndDate ? (
            <X
              stroke={theme.inputs.secondary.icon}
              className="reset-calendar"
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
              }}
            />
          ) : null}
        </>
      )}

      <ContentOverlay>
        <CSSTransition
          timeout={300}
          in={!!focusedInput}
          classNames="fadein"
          unmountOnExit
        >
          <Overlay />
        </CSSTransition>
      </ContentOverlay>
    </Wrapper>
  );
};

DateRangeSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  constraintDate: momentPropTypes.momentObj,
};

DateRangeSelector.defaultProps = {
  startDate: null,
  endDate: null,
  constraintDate: moment().subtract(30, 'days'),
};

export default inject('dataRangePickerStore')(observer(DateRangeSelector));
