import React from 'react';
import {
  PasswordInstructionsContainer,
  PasswordInstructionsTitle,
  PasswordInstructionsList,
  PasswordInstructionsListItem,
  Normal,
  Important,
  NonBreaking,
  TeleType,
} from '@components/PasswordInstructions/styles';

export const PasswordInstructions = () => (
  <PasswordInstructionsContainer>
    <PasswordInstructionsTitle>
      <Normal>New password</Normal>
      <NonBreaking>must be at least</NonBreaking>
      <Important>6 characters</Important>
      <Normal>long and contain</Normal>
      <Important>all</Important>
      <Normal>the following:</Normal>
    </PasswordInstructionsTitle>
    <PasswordInstructionsList>
      <PasswordInstructionsListItem>
        <Normal>at least one</Normal>
        <Important>lower case</Important>
        <Normal>
          character (<TeleType>a-z</TeleType>),
        </Normal>
      </PasswordInstructionsListItem>
      <PasswordInstructionsListItem>
        <Normal>at least one</Normal>
        <Important>upper case</Important>
        <Normal>
          character (<TeleType>A-Z</TeleType>),
        </Normal>
      </PasswordInstructionsListItem>
      <PasswordInstructionsListItem>
        <Normal>at least one</Normal>
        <Important>number</Important>
        <Normal>
          (<TeleType>0-9</TeleType>),
        </Normal>
      </PasswordInstructionsListItem>
      <PasswordInstructionsListItem>
        <Normal>at least one</Normal>
        <Important>special character</Important>
        <Normal>
          (<TeleType>!</TeleType>, <TeleType>@</TeleType>,{' '}
          <TeleType>&</TeleType>, <TeleType>#</TeleType>).
        </Normal>
      </PasswordInstructionsListItem>
    </PasswordInstructionsList>
  </PasswordInstructionsContainer>
);
