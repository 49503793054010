import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Container = styled.div`
  position: relative;
  min-width: ${({ minWidth }) => minWidth || '150px'};
  max-width: ${({ maxWidth }) => maxWidth};

  .error__control {
    border-color: ${({ theme }) => theme.colors.error};
  }

  .error__indicator {
    color: ${({ theme }) => theme.colors.error};
  }

  @media screen and ${breakpoint.xs} {
    min-width: 120px;
  }
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  line-height: 1.67;
  color: #00202d;
  position: relative;
`;

export const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  line-height: 1.67;
  color: ${({ theme }) => theme.colors.error};
  position: absolute;
  top: 40px;
`;

export const SingleValueContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 3px;
  line-height: 21px;
`;

export const ReactSelectStyles = (theme) => ({
  container: (provided) => ({
    ...provided,
    textAlign: 'left',
    borderColor: theme.colors.secondary,
    fontSize: theme.defaults.fontSize,
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible',
  }),
  dropdownIndicator: (provided, state) => ({
    color: theme.colors.secondary,
    marginRight: 10,
    transition: 'all 0.3s ease-in-out',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
  }),
  option: (provided) => ({
    ...provided,
    padding: 10,
    width: '100%',
    cursor: 'pointer',
  }),
  placeholder: (provided) => ({
    ...provided,
    position: 'absolute',
  }),
  control: (base) => ({
    ...base,
    backgroundColor: theme.inputs.secondary.background,
    border: 'none',
    minHeight: '40px',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.inputs.secondary.placeholder,
    height: '100%',
    fontSize: theme.defaults.fontSize,
  }),
  input: (provided) => ({
    ...provided,
    margin: '-1px',
    paddingBottom: 0,
    paddingTop: 0,
    color: theme.inputs.secondary.placeholder,
  }),
  menu: (base) => ({ ...base, zIndex: 9999 }),
});

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  &.clickable {
    cursor: pointer;
  }
`;
