import styled from '@emotion/styled';
import { ContentWrapper } from '@styles/globalStyles';

export const Container = styled(ContentWrapper)``;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    padding: 5px;
    cursor: pointer;
    margin-right: 18px;
  }
`;

export const LexisNexis = styled.div`
  width: 200px;
  position: relative;
  left: -25px;
  margin-bottom: 32px;

  img {
    width: 100%;
    height: 61px;
  }
`;

export const LexisFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  grid-column-gap: 8px;
`;

export const LexisLink = styled.a`
  color: ${({ theme }) => theme.font.tertiary};
  -webkit-text-decoration: none;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;

  &:nth-of-type(2) {
    border-right: ${({ theme }) => `1px solid ${theme.font.tertiary}`};
    border-left: ${({ theme }) => `1px solid ${theme.font.tertiary}`};
    padding: 0 15px;
  }

  &:hover {
    text-decoration: underline;
  }
`;
