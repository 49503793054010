/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Apps, Mail } from 'tabler-icons-react';
import routes from '@app/routes';

import {
  SectionHeader,
  SectionContainer,
  Companies,
  Company,
  CompanyName,
  Tile,
  Inputs,
  Radios,
  Informations,
  PreloaderWrapper,
  ScrollableContent,
  IconsContainer,
  Buttons,
  ContentDialog,
  Question,
  Info,
  AllIcons,
} from '@containers/Settings/styles';

import Preloader from '@components/Preloader';
import Control from '@components/Control';
import Dialog from '@components/Modal/Dialog';
import Button, { BUTTON_THEMES } from '@components/Button';

const Following = ({
  sidebarStore: { menuCollapsed },
  settingsStore: {
    notifications,
    fetchFollowing,
    companies,
    briefings,
    isLoading,
    followingSection,
    followingSectionChange,
    getNumbersOfFollows,
    updateNumberOfFollows,
    singleNotification,
    rawSingleNotification,
    updateSingleNotification,
    sendStatus,
    updateArray,
    actualArray,
    initialized,
    clearArrays,
  },
}) => {
  const firstRender = useRef(true);
  const isFollowingCompanies = followingSection === 'companies';
  const { handleSubmit, control, setValue } = useForm();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [clickedCheckbox, setClickedCheckbox] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      await fetchFollowing();
    };
    fetchData();
    return clearArrays;
  }, []);

  const emailCompany = !companies
    .map((x) => x.email_notification)
    .some((x) => !x);
  const pushCompany = !companies
    .map((x) => x.push_notification)
    .some((x) => !x);
  const emailBriefing = !briefings
    .map((x) => x.email_notification)
    .some((x) => !x);
  const pushBriefing = !briefings
    .map((x) => x.push_notification)
    .some((x) => !x);

  useEffect(() => {
    setValue('email_notification-company', emailCompany);
    setValue('push_notification-company', pushCompany);
    setValue('email_notification-briefing', emailBriefing);
    setValue('push_notification-briefing', pushBriefing);
  }, [initialized]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      sendStatus(singleNotification.id, {
        [singleNotification.name]: singleNotification.value,
      });

      if (rawSingleNotification.name.includes('email_notification-company-')) {
        setValue('email_notification-company', emailCompany);
      }
      if (rawSingleNotification.name.includes('push_notification-company-')) {
        setValue('push_notification-company', pushCompany);
      }
      if (rawSingleNotification.name.includes('email_notification-briefing-')) {
        setValue('email_notification-briefing', emailBriefing);
      }
      if (rawSingleNotification.name.includes('push_notification-briefing-')) {
        setValue('push_notification-briefing', pushBriefing);
      }
    }
  }, [singleNotification]);

  const SelectConfirmation = (value, name) => {
    setIsDialogOpen(false);
    actualArray.forEach(
      (x, i) =>
        setValue(
          `${
            name.includes('email') ? 'email_notification' : 'push_notification'
          }-${name.includes('company') ? 'company' : 'briefing'}-${i}`,
          !value,
        ),

      actualArray.forEach((x) =>
        sendStatus(x.id, {
          [name.includes('email') ? 'email_notification' : 'push_notification']:
            !value,
        }),
      ),
    );
    setValue(
      `${name.includes('email') ? 'email_notification' : 'push_notification'}-${
        name.includes('company') ? 'company' : 'briefing'
      }`,
      !value,
    );
    updateArray(value, name);
  };

  const selectAll = async (value, name) => {
    switch (name) {
      case 'email_notification-company':
        setValue('email_notification-company', value);
        setClickedCheckbox({ value, name });
        setDialogText(
          `Are you sure you want to turn ${
            value ? 'off' : 'on'
          } e-mail notifications for all companies you are following?`,
        );
        break;
      case 'push_notification-company':
        setValue('push_notification-company', value);
        setClickedCheckbox({ value, name });
        setDialogText(
          `Are you sure you want to turn ${
            value ? 'off' : 'on'
          } app notifications for all companies you are following?`,
        );
        break;
      case 'email_notification-briefing':
        setValue('email_notification-briefing', value);
        setClickedCheckbox({ value, name });
        setDialogText(
          `Are you sure you want to turn ${
            value ? 'off' : 'on'
          } e-mail notifications for all briefings you are following?`,
        );
        break;
      default:
        setValue('push_notification-briefing', value);
        setClickedCheckbox({ value, name });
        setDialogText(
          `Are you sure want to turn ${
            value ? 'off' : 'on'
          } app notifications for all briefings you are following?`,
        );
    }

    setIsDialogOpen(true);
  };

  useEffect(() => {
    getNumbersOfFollows();
  }, [companies, briefings]);

  const checkId = (id, value, name) => {
    updateSingleNotification(id, value, name);
    updateNumberOfFollows(name, value, id);
  };

  const renderSection = useCallback(() => {
    const elements = isFollowingCompanies ? companies : briefings;

    if (isLoading.following) {
      return (
        <PreloaderWrapper>
          <Preloader />
        </PreloaderWrapper>
      );
    }

    if (elements.length === 0) {
      if (isFollowingCompanies) {
        return <Info>You don&apos;t follow any companies</Info>;
      }
      return <Info>You don&apos;t follow any briefings</Info>;
    }

    return elements.map((element, i) => (
      <Company key={element.id}>
        <Tile color={element.color} />
        {isFollowingCompanies ? (
          <Link to={routes.singleCompany(element.id, 'latest')}>
            <CompanyName>{element.name}</CompanyName>
          </Link>
        ) : (
          <CompanyName>{element.name}</CompanyName>
        )}
        <Inputs>
          <Controller
            name={`email_notification-${
              isFollowingCompanies ? 'company' : 'briefing'
            }-${i}`}
            defaultValue={element.email_notification}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Control
                type="checkbox"
                onChange={onChange}
                disabled={!notifications.email}
                handleSubmit={handleSubmit}
                name={name}
                value={value}
                checkId={() => checkId(element.id, value, name)}
                checked={value}
              />
            )}
          />
          <Controller
            name={`push_notification-${
              isFollowingCompanies ? 'company' : 'briefing'
            }-${i}`}
            defaultValue={element.push_notification}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Control
                type="checkbox"
                onChange={onChange}
                handleSubmit={handleSubmit}
                disabled={!notifications.push}
                name={name}
                value={value}
                checkId={() => checkId(element.id, value, name)}
                checked={value}
              />
            )}
          />
        </Inputs>
      </Company>
    ));
  }, [companies, isLoading, followingSection, briefings]);

  return (
    <SectionContainer className="following-section">
      <SectionHeader>Following</SectionHeader>
      <Dialog
        isOpen={isDialogOpen}
        closeHandler={() => {
          setIsDialogOpen(false);
        }}
        smallModal
      >
        <ContentDialog>
          <Question>{dialogText}</Question>
          <Buttons>
            <Button
              theme={BUTTON_THEMES.PRIMARY}
              onClick={() =>
                SelectConfirmation(clickedCheckbox.value, clickedCheckbox.name)
              }
            >
              Ok
            </Button>
            <Button
              theme={BUTTON_THEMES.SECONDARY}
              onClick={() => setIsDialogOpen(false)}
            >
              Cancel
            </Button>
          </Buttons>
        </ContentDialog>
      </Dialog>
      <Companies>
        <Radios menuCollapsed={menuCollapsed}>
          <Control
            type="radio"
            name="section"
            label="Companies"
            value="companies"
            checked={followingSection === 'companies'}
            onChange={() => followingSectionChange('companies')}
          />
          <Control
            type="radio"
            name="section"
            label="Briefings"
            value="briefings"
            onChange={() => followingSectionChange('briefings')}
          />
        </Radios>
        <Informations>
          <AllIcons>
            <IconsContainer>
              <Mail strokeWidth={1} size={22} color="#7F7B9D" />
              <Controller
                name={`email_notification-${
                  isFollowingCompanies ? 'company' : 'briefing'
                }`}
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value, name } }) => (
                  <Control
                    type="checkbox"
                    onChange={onChange}
                    name={name}
                    disabled={!notifications.email || isLoading.following}
                    value={value}
                    handleSubmit={handleSubmit}
                    checked={value}
                    checkId={() => selectAll(value, name)}
                  />
                )}
              />
              <Apps strokeWidth={1} size={22} color="#7F7B9D" />
              <Controller
                name={`push_notification-${
                  isFollowingCompanies ? 'company' : 'briefing'
                }`}
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value, name } }) => (
                  <Control
                    type="checkbox"
                    onChange={onChange}
                    name={name}
                    value={!value}
                    handleSubmit={handleSubmit}
                    disabled={!notifications.push || isLoading.following}
                    checked={value}
                    checkId={() => selectAll(value, name)}
                  />
                )}
              />
            </IconsContainer>
          </AllIcons>
        </Informations>
        <ScrollableContent>{renderSection()}</ScrollableContent>
      </Companies>
    </SectionContainer>
  );
};

export default inject(
  'sidebarStore',
  'settingsStore',
  'companiesStore',
)(observer(Following));
