import moment from 'moment';

export const groupSections = (sections) => {
    const virtualSections = [];
    sections.forEach(section => {
      if(section.contentHashtagType === 'briefing') {
        virtualSections.push(section);
      } else {
        let previousItemDate = new Date(0);
        const itemsBuffer = [];
        section.data.forEach(item => {
          if(itemsBuffer.length && !moment(item.date).startOf('day').isSame(moment(previousItemDate).startOf('day'))) {
            virtualSections.push({
              ...section,
              'publicationDate': itemsBuffer[0].date,
              'data': [...itemsBuffer]});
            itemsBuffer.length = 0;
          }
          itemsBuffer.push(item);
          previousItemDate = item.date;
        });
        if(itemsBuffer.length) {
            virtualSections.push({
                ...section,
                'publicationDate': itemsBuffer[0].date,
                'data': [...itemsBuffer]});
        }
      }
    });
    return virtualSections;
}