import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { toBlob } from 'html-to-image';
import { saveAs } from 'file-saver';
import PieChart from '@components/PieChart';
import Preloader from '@components/Preloader';
import {
  ButtonsContainer,
  ChartContainer,
  ChartTitle,
} from '@containers/Dashboard/styles';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { SENTIMENTS } from '@root/constants/dashboard';
import {
  CompanyBadge,
  PieContainer,
} from '@containers/Dashboard/components/SentimentAnalysis/style';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { titleCase } from '@utils/chartHelpers';
import { DashboardExportDropdown } from '@components/DashboardExportDropdown';

const SentimentAnalysis = ({
  dashboardStore: {
    sentimentAnalysisChartData,
    allCompanies,
    selectedCompanies,
    isLoadingSentimentAnalysis,
  },
}) => {
  const [chartsInGroup, setChartsInGroup] = useState(2);
  const { BREAKPOINTS, width, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const chartId = 'sentiment-analysis-chart';
  const buttonsContainerId = 'sentiment-analysis-chart-buttons';

  useEffect(() => {
    const isTablet = matchBreakpoint(BREAKPOINTS.XL);
    const isSmallScreen = matchBreakpoint(BREAKPOINTS.XXL);
    if (!sentimentAnalysisChartData || !sentimentAnalysisChartData.sorted) {
      return;
    }
    const chartsNumber = sentimentAnalysisChartData.sorted.length;
    if (isTablet) {
      if (chartsNumber >= 2) {
        setChartsInGroup(2);
      } else {
        setChartsInGroup(chartsNumber || 1);
      }
      return;
    }
    if (isSmallScreen) {
      if (chartsNumber >= 3) {
        setChartsInGroup(3);
      } else {
        setChartsInGroup(chartsNumber || 1);
      }
      return;
    }
    if (chartsNumber >= 4) {
      setChartsInGroup(4);
    } else {
      setChartsInGroup(chartsNumber || 1);
    }
  }, [width, sentimentAnalysisChartData]);

  const noData = useMemo(
    () =>
      !sentimentAnalysisChartData.sorted ||
      sentimentAnalysisChartData.sorted.length === 0 ||
      selectedCompanies.size === 0,
    [sentimentAnalysisChartData, selectedCompanies],
  );

  const companyColors = useMemo(() => {
    const colors = {};
    if (allCompanies.length) {
      allCompanies.forEach(({ name, color }) => {
        colors[name] = color;
      });
    }
    return colors;
  }, [allCompanies]);

  if (isLoadingSentimentAnalysis) {
    return (
      <ChartContainer className="empty">
        <Preloader />
      </ChartContainer>
    );
  }

  const saveImage = async () => {
    const name = `briefcase-dashboard-${chartId}.png`;
    const chart = document.getElementById(chartId);
    const buttons = document.getElementById(buttonsContainerId);
    const chartBackground = chart.style.backgroundColor;

    chart.style.backgroundColor = 'white';
    buttons.style.display = 'none';

    const blob = await toBlob(chart, {});
    saveAs(blob, name);

    buttons.style.display = 'flex';
    chart.style.backgroundColor = chartBackground;
  };

  return (
    <ChartContainer id={chartId}>
      {!isMobile ? <ChartTitle>Sentiment Analysis</ChartTitle> : null}
      {noData ? (
        <ChartContainer className="empty">
          No data, please use filters
        </ChartContainer>
      ) : (
        <Swiper
          slidesPerView={isMobile ? 1 : chartsInGroup}
          spaceBetween={0}
          slidesPerGroup={isMobile ? 1 : chartsInGroup}
          pagination={{
            clickable: true,
          }}
          navigation
          modules={[Pagination, Navigation]}
        >
          {sentimentAnalysisChartData.sorted.map(
            ({ sentiments, name, tooltipSentiments }) => (
              <SwiperSlide key={`pie-chart-${name}`}>
                <PieContainer>
                  <CompanyBadge companyColor={companyColors[name]}>
                    {name}
                  </CompanyBadge>
                  <PieChart
                    chartData={sentiments}
                    title={`pie-chart-${name}`}
                    dataKey="value"
                    formatLabel={(label) => label.replace('_', ' ')}
                    companyNames={SENTIMENTS}
                    useCompanyColors={false}
                    tooltipData={tooltipSentiments}
                    useAspect
                    customTooltip
                  />
                </PieContainer>
              </SwiperSlide>
            ),
          )}
        </Swiper>
      )}
      <ButtonsContainer id={buttonsContainerId}>
        <DashboardExportDropdown
          data={sentimentAnalysisChartData.raw}
          name="sentiment-analysis"
          isPieChart={false}
          keyNames={[...SENTIMENTS, { name: 'sum', label: 'total' }]}
          rowKey="company"
          formatLabel={(value) => titleCase(value.replace('_', ' '))}
          saveImage={saveImage}
        />
      </ButtonsContainer>
    </ChartContainer>
  );
};

SentimentAnalysis.propTypes = {
  dashboardStore: PropTypes.shape({
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default inject('dashboardStore')(observer(SentimentAnalysis));
