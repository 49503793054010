import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';

export const SearchContainer = styled.div`
  background-color: ${({ theme }) => theme.control.background};
  position: fixed;
  height: fit-content;
  flex-direction: row;
  display: flex;
  top: 52px;
  left: 0px;
  width: 100%;
  transition: top 0.4s;
  padding: 10px;
  z-index: 99;
  flex-wrap: wrap;

  .date-range-picker {
    width: unset;
  }

  @media only screen and ${breakpoint.sm} {
    .date-range-picker {
      button {
        right: -5px;
      }
    }
  }

  ${({ searchBarHidden }) =>
    searchBarHidden === true
      ? css`
          top: -50px;
        `
      : css`
          top: 56px;
        `}

  ${({ isCalendarExpended }) =>
    isCalendarExpended === true
      ? css`
          flex-direction: column;

          .reset-calendar {
            margin-left: 10px;
          }

          form {
            margin-top: 45px;
          }

          form > div {
            margin-right: 0;
          }

          .DateRangePicker {
            width: 100%;

            .DateInput {
              width: 50%;
            }
          }

          .date-range-picker {
            order: -1;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
          }
        `
      : null}

      ${({ isCalendarExpended, repairedMargin }) =>
    isCalendarExpended === true && repairedMargin === true
      ? css`
          form {
            margin-top: 10px;
          }
        `
      : null}
`;
