import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';
import { Grid } from '@styles/globalStyles';

export const ContentWrapper = styled.div`
  padding: 146px 40px 56px 144px;
  min-height: 100%;

  .input-container {
    margin: 10px 0px;
  }

  @media only screen and (max-width: 1600px) {
    padding: 156px 32px 32px 32px;
  }

  @media only screen and ${breakpoint.lg} {
    padding: 156px 50px 16px 16px;
  }

  @media only screen and ${breakpoint.sm} {
    padding: 86px 38px 16px 16px;
  }
`;

export const Company = styled.div`
  position: relative;
  width: 304px;
  height: 212px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  border-radius: 3px;
  transition: all 0.3s;
  box-shadow: 0px 0px 20px -14px rgba(66, 68, 90, 1);

  img {
    max-width: -webkit-fill-available;
    height: 35px;
    transition: 0.3s;
  }

  a {
    color: #000;
  }

  &:hover img {
    transform: scale(1.1);
  }

  ${({ followed, color, theme }) =>
    followed
      ? css`
          background-color: ${color};
          border: none;
          transition: all 0.3s;

          p {
            color: ${theme.colors.white};
          }

          button {
            color: #e5005c;
          }

          img {
            filter: none !important;
          }
        `
      : null}

  @media only screen and (max-width: 1135px) {
    width: calc(33% - 20px);
  }

  @media only screen and ${breakpoint.lg} {
    width: calc(50% - 35px);
  }

  @media only screen and ${breakpoint.md} {
    width: 45%;

    ${({ collapsed }) =>
      !collapsed
        ? css`
            & {
              width: 100%;
            }
          `
        : null}
  }

  @media only screen and ${breakpoint.md} {
    width: calc(50% - 8px);
    height: 180px;

    img {
      max-width: 135px;
      height: 30px;
    }

    ${({ collapsed }) =>
      !collapsed
        ? css`
            width: calc(50% - 8px);
          `
        : null}
  }

  @media only screen and ${breakpoint.xs} {
    width: 100%;

    ${({ collapsed }) =>
      !collapsed
        ? css`
            width: 100%;
          `
        : null}
  }
`;

export const Top = styled.div`
  width: 100%;
  padding: 16px;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: center;

  img {
    margin-top: 10px;
  }
  ${({ isLoading }) =>
    isLoading
      ? css`
          opacity: 0.3;
        `
      : null}
`;

export const CompanyName = styled.p`
  font-weight: 400;
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  line-height: 16px;
  align-self: flex-start;
  color: ${({ theme }) => theme.font.default};
`;

export const Articles = styled.p`
  font-weight: 400;
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  line-height: 16px;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.companyButton};
  text-transform: uppercase;
  border: none;
  width: 100%;
  height: 40px;
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  line-height: 18px;
  border-top: 1px solid #e2e0e0;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.companyButtonHover};
  }

  @media only screen and ${breakpoint.md} {
    font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  }
  :disabled {
    color: #666666;
  }
  :hover:disabled {
    background-color: white;
  }
`;

export const VerticalAlphabetSearch = styled.div`
  display: none;
  position: fixed;
  text-align: center;
  width: 20px;
  height: 60vh;
  right: 5px;

  @media only screen and ${breakpoint.sm} {
    top: 150px;
    display: block;
  }
  @media only screen and ${breakpoint.md} {
    top: 200px;
    display: block;
  }
`;

export const AlphabetSearchHorizontalContainer = styled.div`
  display: flex;
  position: fixed;
  padding-top: 10px;
  z-index: 99;
  top: ${({ show }) => (show ? '104px' : '0px')};
  background-color: ${({ theme }) => theme.colors.mainBackground};
  transition: all 0.4s;
  border-top: 1px solid ${({ theme }) => theme.colors.mainBackground};
  width: calc(
    100% -
      ${({ menuCollapsed, theme }) =>
        menuCollapsed ? '96px' : `${theme.defaults.desktopMenuWidth}px`}
  );

  @media screen and ${breakpoint.md} {
    display: none;
  }
`;

export const AlphabetSearchHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.font.dark};
  margin: 0 40px 0 144px;

  @media only screen and (max-width: 1600px) {
    margin: 0 32px;
  }
`;

export const AlphabetButton = styled.div`
  font-weight: ${({ chosen }) => (chosen ? 700 : 400)};
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  line-height: 11px;
  color: ${({ theme }) => theme.font.dark};
  margin-bottom: 10px;
  cursor: pointer;
`;

export const SearchContainer = styled.div`
  background-color: ${({ theme }) => theme.control.background};
  position: fixed;
  top: 52px;
  width: 100%;
  transition: top 0.4s;
  left: 0;
  padding: 10px;
  z-index: 99;

  .input-container {
    margin: 0;
  }

  ${({ searchBarHidden }) =>
    searchBarHidden === true
      ? css`
          top: -50px;
        `
      : css`
          top: 56px;
        `}
`;

export const FilterNotFound = styled.p`
  font-weight: 400;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  line-height: 16px;
  margin-top: 35px;
  color: ${({ theme }) => theme.font.default};
  align-self: flex-start;
`;

export const CompaniesGrid = styled(Grid)`
  justify-content: flex-start !important;
`;

export const CenterBox = styled.div`
  position: absolute;
  top: 34%;
  left: 43%;
  z-index: 10;
`;

export const PageTitleWrapper = styled.div`
  height: 65px;
`;

export const PageTitle = styled.div`
  position: absolute;

  & .preloader {
    background-color: ${({ theme }) => theme.colors.mainBackground}cc;
  }

  & .preloader img {
    height: 33px;
  }
`;