import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { MoodSad } from 'tabler-icons-react';
import { useTheme } from '@emotion/react';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

import { ButtonWrapper, Wrapper, NoResults } from '@containers/MyNews/style';
import Preloader from '@components/Preloader';
import NewsSection from '@components/NewsSection';
import Button, { BUTTON_THEMES } from '@components/Button';
import MobileExtendedSearchBar from '@components/MobileExtendedSearchBar';
import { groupSections } from '@utils/sectionGrouping';

const Search = ({
  newsStore: {
    initialized,
    isLoading,
    sections,
    links,
    searchNews,
    loadMore,
    loadingMore,
  },
}) => {
  const { searchWithArrays } = useCustomSearchParams();
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      await searchNews(searchWithArrays);
    })();
  }, [searchWithArrays]);

  const processedSections = groupSections(sections);

  return (
    <Wrapper>
      {isLoading || !initialized ? <Preloader overlay /> : null}

      {!isLoading && initialized && !sections.length ? (
        <NoResults>
          <MoodSad size={64} strokeWidth={1} color={theme.font.default} />
          Sorry, we couldn&apos;t find any results
        </NoResults>
      ) : null}

      <MobileExtendedSearchBar />

      {processedSections.map((item, index) => {
        const previousSection = index > 0 ? processedSections[index - 1] : {};

        return (
          <NewsSection
            section={item}
            previousPublicationDate={previousSection.publicationDate}
            key={`news-section_${index}`}
          />
        );
      })}

      {links.next ? (
        <ButtonWrapper>
          <Button
            type="button"
            theme={BUTTON_THEMES.SECONDARY}
            onClick={loadMore}
            loading={loadingMore}
          >
            Load more
          </Button>
        </ButtonWrapper>
      ) : null}
    </Wrapper>
  );
};

Search.propTypes = {
  newsStore: PropTypes.shape({
    isLoading: PropTypes.bool,
    news: PropTypes.array,
    searchNews: PropTypes.func,
  }).isRequired,
};

export default inject('newsStore', 'dataRangePickerStore')(observer(Search));
