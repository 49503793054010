import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Check, X } from 'tabler-icons-react';
import { Button, GroupContainer } from '@components/CompaniesDialog/styles';
import DashboardDropdown from '@components/DashboardSelect';
import Input, { INPUT_TYPES, THEMES } from '@components/Input';
import useBreakpoint from '@root/hooks/useBreakpoint';

const CompanyGroups = ({
  dashboardStore: {
    dashboardUserSettings,
    dialogSelectedCompanies,
    saveNewGroup,
    removeGroup,
    setDialogSelectedCompanies,
  },
}) => {
  const [newGroupName, setNewGroupName] = useState('');
  const [chosenCompanyGroup, setChosenCompanyGroup] = useState('');
  const [showCompanyGroupInput, setShowCompanyGroupInput] = useState(false);
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const companyGroupsLabels = useMemo(
    () =>
      dashboardUserSettings.companyGroups
        ? Object.keys(dashboardUserSettings.companyGroups).map((name) => ({
            label: name,
            value: name,
            id: name,
          }))
        : [],
    [dashboardUserSettings],
  );

  useEffect(() => {
    if (
      chosenCompanyGroup !== '' &&
      dashboardUserSettings.companyGroups[chosenCompanyGroup] &&
      dialogSelectedCompanies.size !==
        dashboardUserSettings.companyGroups[chosenCompanyGroup].length
    ) {
      setChosenCompanyGroup('');
    }
  }, [dialogSelectedCompanies.size]);

  useEffect(() => {
    if (chosenCompanyGroup && chosenCompanyGroup !== '') {
      setDialogSelectedCompanies(
        dashboardUserSettings.companyGroups[chosenCompanyGroup],
      );
    }
  }, [chosenCompanyGroup]);

  return (
    <>
      <GroupContainer
        className={`${showCompanyGroupInput ? 'hide' : 'show'} reverse`}
      >
        <DashboardDropdown
          id="group-select"
          name="Group"
          prefix="Group: "
          onChange={setChosenCompanyGroup}
          value={chosenCompanyGroup || ''}
          options={companyGroupsLabels}
          placeholder="Load group"
          showDelete
          onOptionDelete={removeGroup}
        />
        <Button
          noUppercase
          className="border small padding"
          onClick={() => setShowCompanyGroupInput(true)}
        >
          Create Company Group
        </Button>
      </GroupContainer>
      <GroupContainer className={showCompanyGroupInput ? 'show' : 'hide'}>
        <Input
          maxWidth={isMobile ? null : 200}
          name="Group name"
          inputType={INPUT_TYPES.TEXT}
          onChange={setNewGroupName}
          value={newGroupName}
          placeholder="New group name..."
          inputTheme={THEMES.SECONDARY}
        />
        <Button
          className="border small"
          onClick={() => {
            setNewGroupName('');
            setShowCompanyGroupInput(false);
          }}
        >
          <X />
        </Button>
        <Button
          className="save small"
          onClick={() => {
            if (saveNewGroup && saveNewGroup !== '') {
              saveNewGroup(newGroupName);
              setShowCompanyGroupInput(false);
            }
          }}
        >
          <Check />
        </Button>
      </GroupContainer>
    </>
  );
};

export default inject('dashboardStore')(observer(CompanyGroups));
