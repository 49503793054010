import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ICON_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const THEMES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const primaryInput = () => css``;

const secondaryInput = (theme) => css`
  background-color: ${theme.inputs.secondary.background};
  border-color: ${theme.inputs.secondary.borderColor};
  font-size: ${theme.defaults.fontSize};

  ::placeholder {
    color: ${theme.inputs.secondary.placeholder};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${theme.inputs.secondary.placeholder};
  }

  ::-ms-input-placeholder {
    color: ${theme.inputs.secondary.placeholder};
  }
`;

const inputThemes = {
  [THEMES.PRIMARY]: (theme) => primaryInput(theme),
  [THEMES.SECONDARY]: (theme) => secondaryInput(theme),
};

export const Container = styled.div`
  width: 100%;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 40)}px;
  position: relative;
  max-width: ${({ maxWidth }) =>
    typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
`;

export const Label = styled.div`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  color: ${({ theme }) => theme.colors.inputLabel};
  margin-bottom: 6px;
  width: 100%;
`;

export const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.inputs.secondary.default};
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-weight: 300;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.secondary};
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 3px;
  margin-top: ${({ noLabel }) => (noLabel ? 0 : 3)}px;
  padding-left: ${({ iconPosition }) =>
    iconPosition === ICON_POSITION.LEFT ? 42 : 8}px;

  &.invalid {
    border-color: ${({ theme }) => theme.colors.error};
  }

  ${({ inputTheme, theme }) => inputThemes[inputTheme](theme)};
`;

export const ErrorMessage = styled.div`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 6px;
  position: absolute;
`;

export const VisibilityButton = styled.button`
  width: 20px;
  height: 14px;
  border: none;
  background: none;
  cursor: pointer;
  display: block;
  position: absolute;
  z-index: 2;
  right: 18px;
  top: 33px;
`;

export const VisibilityButtonIcon = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: -6px;
  left: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;

  & img {
    width: 100%;
  }
`;

export const InputIcon = styled.div`
  position: absolute;
  z-index: 2;
  pointer-events: none;
  top: 8px;
  left: 10px;
`;
