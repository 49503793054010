import { reaction, makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import moment from 'moment';
import { authStore } from '@stores';
import { log, TYPE } from '@utils/logger';
import userStore from '@root/stores/userStore';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import DashboardStorageController from '@utils/DashboardStorageController';
import {
  chartDataWithAllTicks,
  preparePercentData,
  totalDataForTotalCoverage,
} from '@utils/chartHelpers';
import { SENTIMENTS, SENTIMENTS_ORDER } from '@root/constants/dashboard';

export class DashboardStore {
  isLoadingCompanies = false;

  dashboardUserSettings = null;

  selectedCompanies = new Set();

  dialogSelectedCompanies = new Set();

  allCompanies = [];

  totalCoverageInterval = 'month';

  searchQuery = '';

  startDate = moment().subtract(30, 'days');

  endDate = moment();

  companies = {
    raw: [],
    bySector: [],
    alphabetically: [],
  };

  error = null;

  totalCoverageChartData = {};

  totalCoverageChartType = 'bar';

  topSourcesChartData = [];

  topJournalistsChartData = [];

  isLoadingTotalCoverage = false;

  isLoadingTopSources = false;

  isLoadingTopJournalists = false;

  isLoadingSentimentAnalysis = false;

  sentimentAnalysisChartData = {};

  showBespoke = false;

  isLoadingFullReport = false;

  isHydrated = false;

  expandedLabel = '';

  shouldReloadTotalCoverage = true;

  shouldReloadCharts = true;

  selectedCompaniesObjects = [];

  dialogSelectedCompaniesObjects = [];

  constructor() {
    makeAutoObservable(this);

    const controller = new DashboardStorageController();

    makePersistable(this, {
      name: 'DashboardStore',
      properties: [
        'selectedCompanies',
        'companies',
        'dashboardUserSettings',
        'startDate',
        'endDate',
        'totalCoverageInterval',
        'totalCoverageChartType',
        'selectedCompaniesObjects',
      ],
      storage: controller,
      stringify: false,
    }).then((store) => {
      this.isHydrated = store.isHydrated;
    });

    reaction(
      () => ({ isAuthenticated: authStore.isAuthenticated }),
      ({ isAuthenticated }) => {
        if (!isAuthenticated) {
          this.clear();
        }
      },
    );

    reaction(
      () => ({ user: userStore.user }),
      ({ user }) => {
        if (user) {
          this.dashboardUserSettings = user.dashboardSettings;
        }
      },
    );
  }

  clearDialogSelectionCompaniesObjects = () => {
    this.dialogSelectedCompaniesObjects = [];
  };

  updateSelectedCompaniesObjects = () => {
    if (this.selectedCompanies.size && this.allCompanies.length) {
      const tempCompaniesObject = {};
      this.allCompanies.forEach((company) => {
        tempCompaniesObject[company.id] = company;
      });
      this.selectedCompaniesObjects = [];
      this.selectedCompanies.forEach((id) => {
        this.selectedCompaniesObjects.push(tempCompaniesObject[id]);
      });
    }
  };

  updateDialogSelectedCompaniesObjects = () => {
    if (this.dialogSelectedCompanies.size && this.allCompanies.length) {
      const tempCompaniesObject = {};
      this.allCompanies.forEach((company) => {
        tempCompaniesObject[company.id] = company;
      });
      this.dialogSelectedCompaniesObjects = [];
      this.dialogSelectedCompanies.forEach((id) => {
        this.dialogSelectedCompaniesObjects.push(tempCompaniesObject[id]);
      });
    } else {
      this.dialogSelectedCompaniesObjects = [];
    }
  };

  setShouldReloadCharts = (value) => {
    this.shouldReloadCharts = value;
  };

  setShouldReloadTotalCoverage = (value) => {
    this.shouldReloadTotalCoverage = value;
  };

  setStartDate = (value) => {
    this.startDate = value;
  };

  setEndDate = (value) => {
    this.endDate = value;
  };

  setDefaultSelection = () => {
    const limit = 20;
    if (this.dashboardUserSettings.type === 'advisory') {
      const companies = this.allCompanies
        .filter((c) => c.follow)
        .map(({ id }) => id);
      this.selectedCompanies = new Set([
        ...(companies.length
          ? companies
          : this.allCompanies.map(({ id }) => id).slice(0, 5)),
      ]);
    }
    if (this.dashboardUserSettings.type === 'corporate') {
      this.selectedCompanies = new Set([
        ...this.allCompanies.map(({ id }) => id).slice(0, limit),
      ]);
    }
  };

  setTotalCoverageInterval = (interval) => {
    if (
      interval === 'total' &&
      ['pie', 'stacked'].indexOf(this.totalCoverageChartType) === -1
    ) {
      this.totalCoverageChartType = 'stacked';
    }

    if (interval !== 'total' && this.totalCoverageChartType === 'pie') {
      this.totalCoverageChartType = 'line';
    }

    this.totalCoverageInterval = interval;
  };

  setTotalCoverageChartType = (chartType) => {
    if (this.totalCoverageInterval !== 'total' && chartType === 'pie') {
      this.totalCoverageInterval = 'total';
    }

    if (
      this.totalCoverageInterval === 'total' &&
      ['pie', 'stacked'].indexOf(chartType) === -1
    ) {
      this.totalCoverageInterval = 'month';
    }

    this.totalCoverageChartType = chartType;
  };

  fetchCompanies = async () => {
    this.isLoadingCompanies = true;

    try {
      const {
        data: { companies },
      } = await API.get(apiRoutes.dashboard.companies);

      runInAction(() => {
        this.companies.raw = companies;
        this.allCompanies = companies;
        if (this.dashboardUserSettings.type === 'advisory') {
          this.sortCompanies();
        }
        if (this.selectedCompanies.size) {
          const tempCompaniesObject = {};
          this.allCompanies.forEach((company) => {
            tempCompaniesObject[company.id] = company;
          });
          this.selectedCompaniesObjects = [];
          this.selectedCompanies.forEach((id) => {
            this.selectedCompaniesObjects.push(tempCompaniesObject[id]);
          });
        }
      });
    } catch (error) {
      this.error = error;
    } finally {
      runInAction(() => {
        this.isLoadingCompanies = false;
      });
    }
  };

  groupCompanies = (field, alphabet) =>
    this.companies.raw.reduce((r, e) => {
      let label = e[field];
      if (alphabet) {
        [label] = e[field];
      }

      if (!r[label]) r[label] = [e];
      else r[label].push(e);
      return r;
    }, {});

  toggleCompany = (companyId) => {
    if (this.dialogSelectedCompanies.has(companyId)) {
      this.dialogSelectedCompanies.delete(companyId);
    } else {
      this.dialogSelectedCompanies.add(companyId);
    }
    this.updateDialogSelectedCompaniesObjects();
  };

  clearSector = (companies) => {
    companies.forEach(({ id }) => this.dialogSelectedCompanies.delete(id));
    this.updateDialogSelectedCompaniesObjects();
  };

  selectSector = (companies) => {
    companies.forEach(({ id }) => this.dialogSelectedCompanies.add(id));
    this.updateDialogSelectedCompaniesObjects();
  };

  isSectorSelected = (companies) =>
    companies.every(({ id }) => this.dialogSelectedCompanies.has(id));

  setDialogSelectedCompanies = (companies) => {
    this.dialogSelectedCompanies = new Set();
    companies.forEach(({ id }) => this.dialogSelectedCompanies.add(id));
    this.dialogSelectedCompaniesObjects = [...companies];
  };

  setDialogSelectedCompaniesIds = (companies) => {
    this.dialogSelectedCompanies = new Set(companies);
    const tempCompaniesObject = {};
    this.allCompanies.forEach((company) => {
      tempCompaniesObject[company.id] = company;
    });
    this.dialogSelectedCompaniesObjects = [];
    this.dialogSelectedCompanies.forEach((id) => {
      this.dialogSelectedCompaniesObjects.push(tempCompaniesObject[id]);
    });
  };

  sortCompanies = () => {
    this.companies.bySector = this.groupCompanies('sector_name', false);
    this.companies.alphabetically = this.groupCompanies('name', true);
  };

  searchCompanies = () => {
    const queryLowerCase = this.searchQuery.toLowerCase();
    if (this.searchQuery.length > 0) {
      this.companies.raw = this.allCompanies.filter(
        (company) =>
          company.name.toLowerCase().includes(queryLowerCase) ||
          company.sector_name.toLowerCase().includes(queryLowerCase),
      );
    } else {
      this.companies.raw = this.allCompanies;
    }
    this.sortCompanies();
  };

  setSearchQuery = (query) => {
    this.searchQuery = query;
  };

  saveDialogSelection = () => {
    this.selectedCompanies = new Set([...this.dialogSelectedCompanies]);
  };

  copySelectedCompanies = () => {
    runInAction(() => {
      this.dialogSelectedCompanies = new Set([...this.selectedCompanies]);
      this.dialogSelectedCompaniesObjects = [...this.selectedCompaniesObjects];
    });
  };

  fetchTotalCoverage = async ({
    startDate: from,
    endDate: to,
    showBespoke: bespoke,
    totalCoverageInterval: interval,
    selectedCompanies,
  }) => {
    if (!this.shouldReloadTotalCoverage) {
      this.shouldReloadTotalCoverage = true;
      return;
    }
    this.isLoadingTotalCoverage = true;
    this.totalCoverageChartData = {};
    try {
      const {
        data: { data },
      } = await API.get(apiRoutes.dashboard.plots.totalCoverage, {
        params: {
          from: from.toISOString(),
          to: to.toISOString(),
          bespoke,
          interval,
          companies: [...selectedCompanies].toString(),
        },
      });

      const { companyNames, data: chartData, companyColors } = data;

      runInAction(() => {
        this.totalCoverageChartData = {
          companyNames,
          rawData: chartData,
          data: chartDataWithAllTicks(chartData, interval, from, to),
          companyColors,
          pieData: totalDataForTotalCoverage(chartData),
        };

        this.totalCoverageChartData.percentData = preparePercentData(
          this.totalCoverageChartData.data,
          companyNames,
        );
      });
    } catch (error) {
      this.error = error;
    } finally {
      runInAction(() => {
        this.isLoadingTotalCoverage = false;
      });
    }
  };

  fetchCharts = async (params) => {
    if (!this.shouldReloadCharts) {
      this.shouldReloadCharts = true;
      return;
    }
    await this.fetchTopSources(params);
    await this.fetchTopJournalists(params);
    await this.fetchSentimentAnalysis(params);
  };

  fetchTopSources = async ({
    startDate: from,
    endDate: to,
    showBespoke: bespoke,
    selectedCompanies,
  }) => {
    this.isLoadingTopSources = true;
    const fromStartOfDay = moment(from).startOf('day').toISOString()
    const toEndOfDay = moment(to).endOf('day').toISOString()
    try {
      const {
        data: {
          data: { data },
        },
      } = await API.get(apiRoutes.dashboard.plots.topSources, {
        params: {
          from: fromStartOfDay,
          to: toEndOfDay,
          bespoke,
          companies: [...selectedCompanies].toString(),
        },
      });

      runInAction(() => {
        this.topSourcesChartData = data;
      });
    } catch (error) {
      this.error = error;
    } finally {
      runInAction(() => {
        this.isLoadingTopSources = false;
      });
    }
  };

  fetchTopJournalists = async ({
    startDate: from,
    endDate: to,
    showBespoke: bespoke,
    selectedCompanies,
  }) => {
    this.isLoadingTopJournalists = true;
    try {
      const {
        data: {
          data: { data },
        },
      } = await API.get(apiRoutes.dashboard.plots.topJournalists, {
        params: {
          from: moment(from).format("DD-MM-YYYY"),
          to: moment(to).format("DD-MM-YYYY"),
          bespoke,
          companies: [...selectedCompanies].toString(),
        },
      });

      runInAction(() => {
        this.topJournalistsChartData = data;
      });
    } catch (error) {
      this.error = error;
    } finally {
      runInAction(() => {
        this.isLoadingTopJournalists = false;
      });
    }
  };

  fetchSentimentAnalysis = async ({
    startDate: from,
    endDate: to,
    showBespoke: bespoke,
    selectedCompanies,
  }) => {
    this.isLoadingSentimentAnalysis = true;
    try {
      const {
        data: {
          data: { data },
        },
      } = await API.get(apiRoutes.dashboard.plots.sentimentAnalysis, {
        params: {
          from: moment(from).format("DD-MM-YYYY"),
          to: moment(to).format("DD-MM-YYYY"),
          bespoke,
          companies: [...selectedCompanies].toString(),
        },
      });

      runInAction(() => {
        this.sentimentAnalysisChartData = {
          raw: data,
          sorted: this.prepareSentimentAnalysisData(data),
        };
      });
    } catch (error) {
      this.error = error;
    } finally {
      runInAction(() => {
        this.isLoadingSentimentAnalysis = false;
      });
    }
  };

  prepareSentimentAnalysisData = (chartData) => {
    const processedChartData = chartData.map((item) => {
      const mappedItem = {
        name: item.company,
        sentiments: [],
        tooltipSentiments: [],
      };
      SENTIMENTS_ORDER.forEach((name) => {
        mappedItem.sentiments.push({ name, value: item[name] });
      });

      SENTIMENTS.forEach(({ name }) => {
        mappedItem.tooltipSentiments.push({ name, value: item[name] });
      });
      return mappedItem;
    });
    const sortingArr = this.selectedCompaniesObjects.map(({ name }) => name);
    return processedChartData
      .slice()
      .sort((a, b) => sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name));
  };

  setShowBespoke = (value) => {
    this.showBespoke = value;
  };

  requestFullReport = async () => {
    try {
      this.isLoadingFullReport = true;
      await API.post(apiRoutes.dashboard.fullReport, {
        from: moment(this.startDate).toISOString(),
        to: moment(this.endDate).toISOString(),
        bespoke: this.showBespoke,
        company_container_ids: [...this.selectedCompanies].toString(),
      });
      return true;
    } catch (error) {
      return false;
    } finally {
      this.isLoadingFullReport = false;
    }
  };

  saveNewGroup = async (groupName) => {
    const companies = this.dialogSelectedCompaniesObjects;
    this.dashboardUserSettings = {
      ...this.dashboardUserSettings,
      companyGroups: {
        ...this.dashboardUserSettings.companyGroups,
        [groupName]: companies,
      },
    };
    try {
      this.isLoadingFullReport = true;
      await API.post(apiRoutes.updateMe, {
        dashboardSettings: this.dashboardUserSettings,
      });
      return true;
    } catch (error) {
      return false;
    } finally {
      this.isLoadingFullReport = false;
    }
  };

  removeGroup = async (groupName) => {
    const { [groupName]: groupToDelete, ...rest } =
      this.dashboardUserSettings.companyGroups;
    this.dashboardUserSettings = {
      ...this.dashboardUserSettings,
      companyGroups: rest,
    };
    try {
      this.isLoadingFullReport = true;
      await API.post(apiRoutes.updateMe, {
        dashboardSettings: this.dashboardUserSettings,
      });
      return true;
    } catch (error) {
      return false;
    } finally {
      this.isLoadingFullReport = false;
    }
  };

  setExpandedLabel = async (label) => {
    this.expandedLabel = label;
  };

  clear = () => {
    log('@@@ clearing DashboardStore', null, TYPE.INFO);
    this.isLoadingCompanies = false;
    this.dashboardUserSettings = false;
    this.selectedCompanies = new Set();
    this.dialogSelectedCompanies = new Set();
    this.allCompanies = [];
    this.totalCoverageInterval = 'month';
    this.searchQuery = '';
    this.startDate = moment().subtract(30, 'days');
    this.endDate = moment();
    this.companies = {
      raw: [],
      bySector: [],
      alphabetically: [],
    };
    this.error = null;
    this.totalCoverageChartData = {};
    this.totalCoverageChartType = 'bar';
    this.topSourcesChartData = [];
    this.topJournalistsChartData = [];
    this.isLoadingTopJournalists = false;
    this.isLoadingTopSources = false;
    this.isLoadingTotalCoverage = false;
    this.isLoadingSentimentAnalysis = false;
    this.sentimentAnalysisChartData = {};
    this.showBespoke = false;
    this.isLoadingFullReport = false;
  };
}

export default new DashboardStore();
