import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';

export const SettingsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.mainBackground};
  width: 100%;
  padding-bottom: 32px;
  transition: background-color 0.3s;
  font-family: Nunito;
  z-index: -1;
`;

export const ContentWrapper = styled.div`
  width: 1088px;
  height: calc(100% - 160px);
  display: flex;
  margin: 0 auto;
  grid-column-gap: 32px;
  margin-top: 56px;

  @media only screen and (max-width: 1500px) {
    padding: 0 15px;
  }

  @media only screen and (max-width: 1380px) {
    width: 800px;
  }

  @media only screen and ${breakpoint.lg} {
    overflow: hidden;
    margin-top: 24px;
    width: 100%;
  } ;
`;

export const ColumnHeader = styled.h1`
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSizeXXL};
  color: ${({ theme }) => theme.font.default};
  line-height: 33px;

  @media only screen and ${breakpoint.lg} {
    display: none;
  }
`;

export const AccountWrapper = styled.div`
  width: 528px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: all 0.3s;

  @media only screen and (max-width: 1380px) {
    width: 50%;
  }

  @media only screen and ${breakpoint.lg} {
    min-width: 100%;
    gap: 16px;

    ${({ section }) =>
      section === 'notifications'
        ? css`
            transform: translateX(-100%);
          `
        : null}
`;

export const NotificationsWrapper = styled.div`
  width: 528px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: all 0.3s;

  @media only screen and (max-width: 1380px) {
    width: 50%;
  }

  @media only screen and ${breakpoint.lg} {
    min-width: 100%;

    ${({ section }) =>
      section === 'notifications'
        ? css`
            transform: translateX(calc(-100% - 30px));
          `
        : null}
`;

export const SectionHeader = styled.h2`
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  line-height: 25px;
  color: ${({ theme }) => theme.font.secondary};
  margin-bottom: 18px;
`;

export const SectionContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.content};
  transition: background-color 0.3s;
  padding: 32px 24px;
  border-radius: 3px;

  &.following-section {
    padding: 0;

    h2 {
      padding: 32px 32px 0px 32px;
    }
  }

  @media only screen and ${breakpoint.md} {
    &.following-section {
      h2 {
        padding: 32px 12px 0 12px;
      }
    }
  }

  ${({ menuCollapsed }) =>
    !menuCollapsed
      ? css`
          @media only screen and ${breakpoint.md} {
            padding: 32px 12px;
          }
        `
      : null}
`;

export const SubscriptionContent = styled.div`
  background-color: ${({ theme }) => theme.colors.quinary};
  transition: background-color 0.3s;
  height: 75px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'unset')};
`;

export const SubscriptionText = styled.h4`
  color: ${({ theme }) => theme.font.tertiary};
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 16px;
`;

export const SubscriptionExpiresDate = styled.p`
  color: #7f7b9d;
  font-weight: 400;
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  line-height: 20px;
  margin: 8px 0 0;
`;

export const SubscriptionLink = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  align-self: center;
`;

export const Form = styled.form`
  position: relative;

  div:first-of-type.custom-password-input {
    margin-bottom: 18px;
  }
`;

export const EmailWrapper = styled.div`
  margin-bottom: 34px;
  overflow-wrap: break-word;
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  color: ${({ theme }) => theme.colors.inputLabel};
  margin-bottom: 6px;
`;

export const Email = styled.p`
  color: ${({ theme }) => theme.font.default};
  margin-top: 10px;
  font-weight: 300;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  line-height: 22px;
`;

export const Button = styled.button`
  width: 50%;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabled : theme.colors.secondary};
  margin-left: 50%;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  font-weight: 700;
  padding: 11px 0;
  color: ${({ theme }) => theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:hover {
    background-color: ${({ theme }) => theme.buttons.primary.hover};
    border-color: ${({ theme }) => theme.buttons.primary.hover};
  }

  &:disabled:hover {
    background-color: ${({ theme }) => theme.colors.disabled};
    border-color: ${({ theme }) => theme.colors.disabled};
    cursor: default;
  }

  ${({ menuCollapsed }) =>
    !menuCollapsed
      ? css`
          @media only screen and ${breakpoint.md} {
            width: 100%;
            margin-left: 0;
          }
        `
      : null}

  @media only screen and ${breakpoint.md} {
    width: 100%;
    margin-left: 0;
  } ;
`;

export const NotificationsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Companies = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.p`
  color: #7f7b9d;
  font-weight: 400;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  line-height: 16px;
`;

export const Company = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.border.default}`};
  padding: 15px 0px;
  display: flex;
  align-items: center;
  transition: 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.companyButtonHover};
  }
`;

export const CompanyName = styled.p`
  color: ${({ theme }) => theme.font.secondary};
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  line-height: 22px;
`;

export const Tile = styled.div`
  height: 24px;
  width: 3px;
  background-color: ${({ color }) => color};
  margin-right: 8px;
`;

export const Inputs = styled.form`
  display: flex;
  align-items: center;
  grid-column-gap: 48px;
  flex: 1;
  place-content: flex-end;

  @media only screen and ${breakpoint.lg} {
    grid-column-gap: 24px;
  }
`;

export const Radios = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 34px;
  margin-top: 16px;
  padding: 0px 32px;

  @media only screen and ${breakpoint.md} {
    padding: 0px 12px;
  }

  ${({ menuCollapsed }) =>
    !menuCollapsed
      ? css`
          @media only screen and ${breakpoint.md} {
            flex-direction: column;
            align-items: baseline;
            gap: 18px;
          }
        `
      : null}

  @media only screen and ${breakpoint.md} {
    margin-top: 0;
    grid-column-gap: 16px;
    flex-direction: row;
  }
`;

export const Informations = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0;
  padding: 0 32px;

  @media only screen and ${breakpoint.md} {
    padding: 0px 12px;
  }

  ${({ menuCollapsed }) =>
    !menuCollapsed
      ? css`
          @media only screen and ${breakpoint.md} {
            flex-direction: column;
            align-items: baseline;
            gap: 18px;
          }
        `
      : null}

  @media only screen and ${breakpoint.md} {
    flex-direction: column;
    align-items: baseline;
    margin: 24px 0 32px 0;
    gap: 24px;
  }
`;

export const Texts = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 44px;

  img {
    width: 22px;
  }

  ${({ menuCollapsed }) =>
    !menuCollapsed
      ? css`
          @media only screen and ${breakpoint.md} {
            align-self: flex-end;
          }
        `
      : null}

  @media only screen and ${breakpoint.lg} {
    grid-column-gap: 20px;
  }

  @media only screen and ${breakpoint.md} {
    align-self: flex-end;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.font.default};
  font-weight: 400;
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  line-height: 16px;
`;

export const MobileMenu = styled.div`
  display: none;
  width: 100%;
  height: 10px;
  text-align: center;
  background-color: ${({ theme }) => theme.control.background};
  padding: 35px 0 25px 0;
  position: relative;

  @media only screen and ${breakpoint.lg} {
    display: flex;
  }

  &::after {
    content: '';
    display: block;
    width: calc(50% - 20px);
    height: 2px;
    background-color: ${({ theme }) => theme.font.default};
    position: absolute;
    transform: translateX(10px);
    bottom: 10px;
    transition: all 0.3s;
  }

  ${({ section }) =>
    section === 'notifications'
      ? css`
          &::after {
            transform: translateX(calc(100% + 30px));
            transition: all 0.3s;
          }
        `
      : null}
`;

export const MenuItem = styled.p`
  width: 50%;
  align-self: center;
  color: ${({ theme }) => theme.font.default};
`;

export const ForgottenPassword = styled.p`
  color: #6793ff;
  font-weight: 600;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  line-height: 16px;
  text-decoration: underline;
  text-align: right;
  cursor: pointer;
  margin-bottom: 13px;
`;

export const PreloaderWrapper = styled.div`
  img {
    max-width: 32px;
  }
`;
export const ScrollableContent = styled.div`
  overflow-y: scroll;
  max-height: 1000px;
  display: block;
  padding: 12px 32px 24px 32px;

  @media only screen and ${breakpoint.md} {
    padding: 12px 12px 24px 12px;
  }

  ::-webkit-scrollbar {
    width: 3px;
    margin-right: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scroll.default};
    border-radius: 10px;
    position: absolute;
    left: -10px;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  grid-column-gap: 12px;
  margin-right: 5px;

  svg {
    margin-top: 5px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  grid-column-gap: 20px;
`;

export const ContentDialog = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Question = styled.p`
  color: #7f7b9d;
  font-weight: 400;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  line-height: 16px;
`;

export const AllIcons = styled(Icons)`
  margin-left: auto;
`;
