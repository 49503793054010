/* eslint-disable */
import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router';

const MagicLinkPage = ({ authStore: { loginWithMagicLink } }) => {
  const { token } = useParams();
  useEffect(() => {
    (async () => {
      await loginWithMagicLink(token);
    })();
  }, [token]);
  return null;
};

export default inject('authStore')(observer(MagicLinkPage));
