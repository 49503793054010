import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  position: relative;
  z-index: 11;
  height: 40px;
  display: flex;
  align-items: center;

  .reset-calendar {
    margin-left: 10px;
  }

  .DateRangePicker {
    position: static;
    @media screen and ${breakpoint.md} {
      /* width: 100%; */
    }
  }

  .DateRangePicker_picker {
    margin-top: -24px;
    box-shadow: 0px -2px 8px 4px rgba(41, 35, 92, 0.03);
    border-radius: 0px 0px 3px 3px;

    @media screen and ${breakpoint.md} {
      width: 100%;
    }
  }

  .DayPicker {
    @media screen and ${breakpoint.md} {
      margin: 0 auto;
      box-shadow: none;
    }
  }

  .DateRangePickerInput {
    display: flex;
    padding: 0 8px 0 0;
    align-items: center;
    height: 40px;
  }

  .DateRangePickerInput__withBorder {
    border-radius: 3px;
    border-color: ${({ theme }) => theme.inputs.secondary.borderColor};
    background-color: ${({ theme }) => theme.inputs.secondary.borderColor};
  }

  .DateInput {
    background: transparent;
    display: block;
    height: 40px;
    width: 120px;
  }

  .DateInput_input {
    background: transparent;
    font-size: ${({ theme }) => theme.defaults.fontSize}px;
    font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
    font-weight: ${({ theme }) => theme.defaults.fontWeight.regular};
    color: ${({ theme }) => theme.colors.primary};
    padding: 0 16px;
    top: 2px;
    position: relative;
    height: 100%;
  }
  .DateInput_input__focused {
    border-bottom-color: ${({ theme }) => theme.colors.secondary};
  }

  .DateRangePickerInput_arrow {
    height: 24px;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.secondary};

    svg {
      display: none;
    }
  }

  .CalendarDay {
    vertical-align: middle;
  }
  .CalendarDay__hovered_span {
    background-color: ${({ theme }) => theme.datepicker.dayHover};
    border-color: #dfdfdf;
    color: ${({ theme }) => theme.datepicker.dayHoverText};

    &:hover {
      background-color: ${({ theme }) => theme.datepicker.active} !important;
    }
  }

  .CalendarDay__default {
    &:not(.CalendarDay__blocked_out_of_range) {
      &:hover {
        background-color: ${({ theme }) => theme.datepicker.active};
        color: ${({ theme }) => theme.datepicker.dayHoverText};
      }
    }
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: ${({ theme }) => theme.datepicker.active};
    border-color: #dfdfdf;
    //   ${({ theme }) => theme.datepicker.active};
  }

  .CalendarDay__selected_span {
    background-color: ${({ theme }) => theme.datepicker.dayHover};
    border-color: #dfdfdf; // ${({ theme }) => theme.datepicker.active};
  }

  .DayPicker_weekHeader_li {
    small {
      font-weight: ${({ theme }) => theme.defaults.fontWeight.bold};
      font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
    }
  }

  .DayPicker_weekHeader {
    top: 56px;
  }

  .DateRangePickerInput_calendarIcon {
    margin: 0;
    padding: 0;
    height: 24px;

    @media screen and ${breakpoint.md} {
      margin-left: auto;
    }
  }

  .DateRangePickerInput_clearDates {
    padding: 0;
    position: relative;
    top: unset;
    right: unset;
    margin: 0 20px 0 0;
    transform: none;
  }

  .DateRangePickerInput_clearDates_svg {
    fill: ${({ theme }) => theme.inputs.secondary.icon};
  }

  .DateRangePickerInput_clearDates_default:focus,
  .DateRangePickerInput_clearDates_default:hover {
    border-radius: none;
    background: transparent;
  }

  .DateRangePickerInput_clearDates__hide {
    display: none;
  }
  .CalendarDay__hovered {
  }

  .DateInput_fang {
    display: none;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.primary}12;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 5;
`;

export const NavButton = styled.button`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 18px;
  z-index: 5;
  padding: 0;
  margin: 0;

  ${({ dir }) => {
    switch (dir) {
      case 'prev':
        return css`
          left: 20px;
        `;
      case 'next':
        return css`
          right: 20px;
        `;
      default:
        return css`
          left: 20px;
        `;
    }
  }}
`;

export const MobileTrigger = styled.button`
  position: absolute;
  height: 100%;
  right: 3px;
  padding-top: 3px;

  ${({ isMobileExpanded }) =>
    isMobileExpanded === true
      ? css`
          svg {
            display: none;
          }
        `
      : null}
`;
