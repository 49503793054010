import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Container, Label, CustomSwitch } from '@components/Switch/styles';
import { InfoCircleIcon } from '@components/CustomChartLegend/style';
import { StyledTooltip } from '@containers/Dashboard/styles';
import useBreakpoint from '@root/hooks/useBreakpoint';

const StyledSwitch = ({
  name,
  label,
  value,
  onChange,
  onClick,
  handleSubmit,
  onSubmit,
  checkId,
  onColor,
  offColor,
  onHandleColor,
  offHandleColor,
  noGap,
  mobileColumn,
  isDashboard,
  tooltipText,
}) => {
  const switchId = useMemo(() => `switch_${name}`);
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  return (
    <Container
      onClick={onClick}
      className="custom-switch"
      noGap={noGap}
      mobileColumn={mobileColumn}
    >
      <CustomSwitch
        checked={!!value}
        value={value.toString()}
        onChange={
          handleSubmit
            ? (e) => {
                onChange(e);
                handleSubmit(onSubmit);
                checkId();
              }
            : onChange
        }
        onColor={onColor}
        offColor={offColor}
        onHandleColor={onHandleColor}
        offHandleColor={offHandleColor}
        handleDiameter={10}
        uncheckedIcon={false}
        checkedIcon={false}
        height={16}
        width={32}
        id={switchId}
      />
      {label !== '' && (
        <Label
          className={isDashboard ? 'dashboard' : ''}
          htmlFor={isMobile && isDashboard ? null : switchId}
        >
          {label}
          {tooltipText ? (
            <>
              <StyledTooltip />
              <InfoCircleIcon
                data-tip={tooltipText}
                data-place="bottom"
                size={18}
              />
            </>
          ) : null}
        </Label>
      )}
    </Container>
  );
};

StyledSwitch.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  name: PropTypes.string,
  onClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  checkId: PropTypes.func,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
  onHandleColor: PropTypes.string,
  offHandleColor: PropTypes.string,
};

StyledSwitch.defaultProps = {
  onChange: null,
  name: 'switch',
  value: false,
  label: null,
  onClick: null,
  handleSubmit: null,
  onSubmit: null,
  checkId: null,
  onColor: '#6793FF',
  offColor: '#888',
  onHandleColor: '#FFF',
  offHandleColor: '#FFF',
};

export default StyledSwitch;
