import { runInAction, makeAutoObservable, reaction } from 'mobx';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';
import { authStore } from '@stores';

export class UserStore {
  isLoading = false;

  error = null;

  isDashboardEnabled = true;

  user = {};

  constructor() {
    makeAutoObservable(this);

    if (authStore.isAuthenticated) {
      this.fetchUser();
    }

    reaction(
      () => ({ isAuthenticated: authStore.isAuthenticated }),
      ({ isAuthenticated }) => {
        if (isAuthenticated) {
          this.fetchUser();
        }
      },
    );
  }

  fetchUser = async () => {
    this.isLoading = true;

    try {
      const { data } = await API.get(apiRoutes.me);
      runInAction(() => {
        this.user = { ...data, fullName: `${data.firstName} ${data.lastName}` };
        this.isDashboardEnabled = data.dashboardSettings.enabled;
      });
    } catch (error) {
      this.error = error;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}

export default new UserStore();
