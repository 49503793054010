import { makeAutoObservable, runInAction } from 'mobx';

export class DataRangePickerStore {
  isMobileExpanded = false;

  constructor() {
    makeAutoObservable(this);
  }

  handleMobileExpand = (value) => {
    runInAction(() => {
      this.isMobileExpanded = value;
    });
  };
}

export default new DataRangePickerStore();
