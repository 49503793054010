import React, { useCallback, useMemo, useState, useRef } from 'react';

import {
  Container,
  Label,
  StyledInput,
  ErrorMessage,
  VisibilityButton,
  VisibilityButtonIcon,
  THEMES,
} from '@components/Input/styles';

import EyeIcon from '@app/assets/eye-off.svg';
import EyeOffIcon from '@app/assets/eye.svg';

function PasswordInput({
  label,
  onChange,
  value,
  name,
  error,
  autoComplete,
  showStrength,
  validationSchema
}) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const changePasswordVisibility = useCallback(() => {
    setPasswordVisible(!passwordVisible);
  }, [passwordVisible]);
  const ref = useRef(null);
  const errRef = useRef(null);

  if (validationSchema !== null) {
    validationSchema.byName[name] = { input: ref, err: errRef };
  }

  const inputId = useMemo(() => `input_${name}-passwd`, [name]);
  return (
    <Container
      showStrength={showStrength && !!value}
      className="custom-password-input"
    >
      <Label htmlFor={inputId}>{label}</Label>
      <StyledInput
        autoComplete={autoComplete}
        id={inputId}
        type={passwordVisible ? 'text' : 'password'}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        className={error && 'invalid'}
        inputTheme={THEMES.PRIMARY}
        ref={ref}
      />
      <VisibilityButton type="button" onClick={changePasswordVisibility}>
        <VisibilityButtonIcon visible={!passwordVisible}>
          <img src={EyeIcon} alt="" />
        </VisibilityButtonIcon>
        <VisibilityButtonIcon visible={passwordVisible}>
          <img src={EyeOffIcon} alt="" />
        </VisibilityButtonIcon>
      </VisibilityButton>

      <ErrorMessage ref={errRef}>{error && error.message}</ErrorMessage>
    </Container>
  );
}

PasswordInput.defaultProps = {
  error: undefined,
  autoComplete: null,
  showStrength: false,
  validationSchema: null,
};

export default PasswordInput;
