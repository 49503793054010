import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { breakpoint } from '@styles/breakpoints';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 416px;
  width: 90%;
  padding-bottom: 72px;

  .input-container {
    margin: 60px 0 72px 0;
  }

  @media screen and ${breakpoint.md} {
    .input-container {
      margin: 32px 0;
    }
  }
`;

export const Header = styled.h2`
  font-weight: ${({ theme }) => theme.defaults.fontWeight.bold};
  font-size: ${({ theme }) => theme.defaults.fontSizeXXL}px;
  line-height: 33px;
  color: ${({ theme }) => theme.colors.primary};
  align-self: flex-start;
`;

export const Back = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 36px;
  margin-left: 2em;
  align-self: flex-start;
  gap: 10px;

  @media screen and ${breakpoint.md} {
    margin-left: 1em;
  }
`;

export const Text = styled.span`
  font-weight: ${({ theme }) => theme.defaults.fontWeight.regular};
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.primary};
`;
