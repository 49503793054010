import React, { useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import useBreakpoint from '@root/hooks/useBreakpoint';

import { PageTitle } from '@styles/globalStyles';
import NewsSection from '@components/NewsSection';
import MobileExtendedSearchBar from '@components/MobileExtendedSearchBar';
import { Grid, ContentWrapper } from '@components/BriefingTypeBox/style';
import Preloader from '@components/Preloader';
import Text, { TAGS } from '@components/Text';
import BriefingTypeBox from '@components/BriefingTypeBox';
import NewContent from '@components/NewContentBanner';
import Button, { BUTTON_THEMES } from '@components/Button';
import { LoadMoreSection } from '@containers/Briefings/styles';
import routes from '@routes';

const Briefings = ({
  briefingsStore: {
    fetchBriefings,
    initialized,
    isLoading,
    briefingTypes,
    briefings,
    handleFollow,
    showNewContentBanner,
    setShowNewContentBanner,
    checkForNewContent,
    links,
    loadMore,
    loadingMore,
    loadingBriefingTypeId,
    briefingTitle,
  },
}) => {
  const { briefingId } = useParams();
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const navigate = useNavigate();

  useEffect(() => {
    setShowNewContentBanner(false);
    if (briefingId !== 'all') {
      const interval = setInterval(
        () => checkForNewContent(briefingId),
        60000 * 2,
      );
      return () => clearInterval(interval);
    }
    return () => {};
  }, [briefingId]);

  const newContentBannerAction = async () => {
    setShowNewContentBanner(false);
    await fetchBriefings(briefingId, true);
  };

  useEffect(() => {
    (async () => {
      await fetchBriefings(briefingId);
    })();
  }, [briefingId]);

  const briefingTypesSize = useMemo(
    () => briefingTypes.length,
    [briefingTypes],
  );

  const followedBriefingTypesSize = useMemo(
    () => briefingTypes.filter((bt) => bt.follow).length,
    [briefingTypes],
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [briefingId, briefings]);

  if (isLoading || !initialized) {
    return (
      <ContentWrapper>
        <Preloader overlay />
      </ContentWrapper>
    );
  }

  if(!isLoading && initialized && briefingTypesSize === 0) {
    navigate(`${routes.trial}?type=briefings`);
  }

  return (
    <>
      {showNewContentBanner ? (
        <NewContent onClick={newContentBannerAction} />
      ) : null}
      <ContentWrapper wide={briefingId === 'all'}>
        <MobileExtendedSearchBar repairedMargin />
        {briefingId === 'all' ? (
          <>
            <PageTitle>
              <Text tag={TAGS.H1}>
                All Briefings | Following {followedBriefingTypesSize}{' '}
                {isMobile ? ' / ' : 'out of '}
                {briefingTypesSize}
              </Text>
            </PageTitle>
            <Grid>
              {briefingTypes.map((briefingType) => (
                <BriefingTypeBox
                  isLoading={loadingBriefingTypeId === briefingType.id}
                  briefingType={briefingType}
                  key={`briefing-type-box_${briefingType.id}`}
                  handleFollow={handleFollow}
                />
              ))}
            </Grid>
          </>
        ) : (
          <>
            {briefings?.sections?.map((item, index) => {
              const previousSection =
                index > 0 ? briefings.sections[index - 1] : {};

              if (briefingTitle !== null) {
                item.sectionTitle = briefingTitle;
              }
              return (
                <NewsSection
                  section={item}
                  previousPublicationDate={previousSection.publicationDate}
                  key={`news-section_${index}`}
                />
              );
            })}
          </>
        )}
        {links && links.next ? (
          <LoadMoreSection>
            <Button
              type="button"
              theme={BUTTON_THEMES.SECONDARY}
              onClick={() => loadMore(briefingId)}
              loading={loadingMore}
            >
              Load more
            </Button>
          </LoadMoreSection>
        ) : null}
      </ContentWrapper>
    </>
  );
};

export default inject('briefingsStore', 'sidebarStore')(observer(Briefings));
