import React from 'react';
import { useTheme } from '@emotion/react';
import { ChevronLeft } from 'tabler-icons-react';
import { history } from '@app/history';
import Preloader from '@components/Preloader';
import {
  Box,
  Container,
  Wrapper,
  IconWrapper,
  BackButtonWrapper,
} from '@components/SubscriptionWrapper/styles';
import TextWrapper, { COLORS, TAGS } from '@components/Text';

function SubscriptionWrapper({ children, isLoading }) {
  const theme = useTheme();

  return (
    <Container>
      {isLoading && <Preloader size="normal" />}
      {!isLoading && (
        <>
          <BackButtonWrapper>
            <Box onClick={() => history.goBack()} isClickable>
              <TextWrapper color={COLORS.TERTIARY} tag={TAGS.H1}>
                <IconWrapper>
                  <ChevronLeft color={theme.font.default} size={42} />
                  Go back
                </IconWrapper>
              </TextWrapper>
            </Box>
          </BackButtonWrapper>
          <Wrapper>{children}</Wrapper>
        </>
      )}
    </Container>
  );
}

export default SubscriptionWrapper;
