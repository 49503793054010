import React from 'react';
import moment from 'moment';
import {
  Container,
  List,
  Label,
  ListItem,
} from '@components/PieChartTooltip/style';

const capitalize = (value) => {
  const words = value.split('_');
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const CustomPieTooltip = ({
  dateFormat,
  label,
  chartData,
  colors,
  separator,
}) => (
  <Container>
    <List>
      <Label>
        {dateFormat ? moment(label).format(dateFormat).toString() : label}
      </Label>
      {chartData &&
        chartData.map(
          (record) => {
            if (record.name === 'Sum') {
              record.name = 'Total';
            }
            if (record.value !== undefined &&
                record.value !== 0 &&
                record.name !== 'Total' &&
                record.name !== 'undisclosed') {
              return (
                <ListItem
                  key={`custom-tooltip_${record.name}`}
                  color={colors[record.name]}
                >
                  {capitalize(record.name)}
                  {separator}
                  {record.value}
                </ListItem>
              );
            }
            return null;
          },
        )}
    </List>
  </Container>
);

export default CustomPieTooltip;
