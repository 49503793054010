import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Photo } from 'tabler-icons-react';
import ChevronIcon from '@assets/images/icons/chevron.svg';
import { Button } from '@containers/Dashboard/styles';

export const ExportDropdown = styled(Button)`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;

  &:after {
    content: '';
    width: 14px;
    height: 12px;
    display: block;
    background: transparent url(${ChevronIcon}) no-repeat 50% 50% / contain;
    margin-left: 10px;
    transition: transform 0.3s ease-in-out;
    transform: rotate(${({ isOpened }) => (isOpened ? 180 : 0)}deg);
  }

  ${({ isOpened }) =>
    isOpened
      ? css`
          ${ExportMenu} {
            box-shadow: 0px 1px 9px 3px rgb(41 35 92 / 20%);
            max-height: 300px;
            padding: 8px;
          }
        `
      : null}
`;

export const ExportMenu = styled.ul`
  overflow: hidden;
  max-height: 0;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 110px;
  padding: 0 10px;
  background-color: ${({ theme }) => theme.colors.content};
  border-radius: 3px;
  z-index: 100;
`;

export const ExportMenuItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;

  &:first-of-type {
    padding: 3px 0;
  }

  a,
  p {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.font.default};
    text-align: left;
    padding: 10px 0;
    transition: background-color 0.3s ease-in-out;
    width: 100%;

    > svg {
      margin-right: 18px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.icons.hover};
    }
  }
  button {
    width: 100%;
  }
`;

export const ImageIcon = styled(Photo)`
  width: 22px;
  height: 22px;
  margin: auto 0 auto 5px;
`;
