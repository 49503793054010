import React from 'react';
import { inject, observer } from 'mobx-react';
import { SectionContainer, SectionHeader } from '@containers/Settings/styles';
import Switch from '@components/Switch';

const ChangeAppSize = ({ authStore: { appSize, handleAppSize } }) => {
  const isBig = appSize === 'big';
  return (
    <SectionContainer>
      <SectionHeader>Change application size</SectionHeader>
      <Switch
        onChange={() => {
          if (isBig) {
            handleAppSize('small');
          } else {
            handleAppSize('big');
          }
        }}
        label="Large layout and fonts"
        name="appSize"
        value={isBig}
      />
    </SectionContainer>
  );
};

export default inject('authStore')(observer(ChangeAppSize));
