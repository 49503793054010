import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { ArrowsSort, Search } from 'tabler-icons-react';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@components/Modal/Dialog';
import Input, { INPUT_TYPES, THEMES } from '@components/Input';
import {
  HeaderContainer,
  SearchContainer,
  Button,
  RightContainer,
  LeftContainer,
  ContentContainer,
  ScrollableContent,
} from '@components/CompaniesDialog/styles';
import DashboardDropdown from '@components/DashboardSelect';
import GroupedCompaniesList from '@components/CompaniesDialog/components/GroupedCompaniesList';
import SelectedCompaniesList from '@components/CompaniesDialog/components/SelectedCompaniesList';
import RawCompaniesList from '@components/CompaniesDialog/components/RawCompaniesList';
import useDebounce from '@root/hooks/useDebounce';
import { SORT_OPTIONS } from '@root/constants/dashboard';
import CompanyGroups from '@components/CompaniesDialog/components/CompanyGroups';
import { history } from '@app/history';

const DesktopCompaniesDialog = ({
  dashboardStore: {
    companies,
    dashboardUserSettings,
    dialogSelectedCompanies,
    searchCompanies,
    searchQuery,
    setSearchQuery,
    saveDialogSelection,
    setShouldReloadCharts,
    setShouldReloadTotalCoverage,
    clearDialogSelectionCompaniesObjects,
  },
}) => {
  const [sortBy, setSortBy] = useState('bySector');
  const navigate = useNavigate();

  useEffect(
    () => () => history.action === 'POP' ? reloadCharts(false) : null,
    [],
  );

  const reloadCharts = (value) => {
    setShouldReloadCharts(value);
    setShouldReloadTotalCoverage(value);
  };

  const closeHandler = () => {
    reloadCharts(false);
    navigate('/dashboard');
  };

  const saveHandler = () => {
    saveDialogSelection();
    reloadCharts(true);
    navigate('/dashboard');
  };

  useDebounce(searchQuery, 300, searchCompanies);

  const theme = useTheme();
  return (
    <Dialog closeHandler={closeHandler} isOpen>
      <HeaderContainer>
        <LeftContainer>
          <SearchContainer>
            <Input
              name="Companies search"
              inputType={INPUT_TYPES.TEXT}
              onChange={setSearchQuery}
              value={searchQuery}
              placeholder="Search companies"
              icon={
                <Search
                  className="input-container__icon"
                  stroke={theme.inputs.secondary.icon}
                  size={22}
                />
              }
              inputTheme={THEMES.SECONDARY}
            />
          </SearchContainer>
          {dashboardUserSettings &&
          dashboardUserSettings.type === 'advisory' ? (
            <DashboardDropdown
              minWidth="130px"
              id="sort-select"
              name="Sort"
              // prefix="Sort by: "
              icon={<ArrowsSort size={18} />}
              onChange={setSortBy}
              value={sortBy}
              options={SORT_OPTIONS}
              placeholder="Sort"
            />
          ) : null}
          <CompanyGroups />
        </LeftContainer>
        <RightContainer>
          <Button className="save small padding" onClick={saveHandler}>
            Done
          </Button>
        </RightContainer>
      </HeaderContainer>
      <ContentContainer>
        <ScrollableContent
          autoHide
          autoHeight
          autoHeightMin={300}
          autoHeightMax="100%"
        >
          <SelectedCompaniesList
            clearAll={() => {
              dialogSelectedCompanies.clear();
              clearDialogSelectionCompaniesObjects();
            }}
          />
          {dashboardUserSettings &&
          dashboardUserSettings.type === 'advisory' ? (
            <GroupedCompaniesList groupedCompanies={companies[sortBy]} />
          ) : null}
          {dashboardUserSettings &&
          dashboardUserSettings.type === 'corporate' ? (
            <RawCompaniesList companies={companies.raw} />
          ) : null}
        </ScrollableContent>
      </ContentContainer>
    </Dialog>
  );
};

DesktopCompaniesDialog.propTypes = {
  dashboardStore: PropTypes.shape({
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default inject('dashboardStore')(observer(DesktopCompaniesDialog));
