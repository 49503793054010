import React, { useMemo, useState } from 'react';
import {
  pointerWithin,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { inject, observer } from 'mobx-react';
import {
  ClearAllCompaniesButton,
  ClearIcon,
  CompaniesList,
  SelectedCompaniesHeader,
  SelectedCompaniesLabel,
} from '@components/CompaniesDialog/styles';
import CompanyButton from '@components/CompaniesDialog/components/CompanyButton';

const SelectedCompaniesList = ({
  clearAll,
  isMobile,
  dashboardStore: {
    dialogSelectedCompanies,
    dialogSelectedCompaniesObjects,
    setDialogSelectedCompaniesIds,
  },
}) => {
  const [dragCompanyId, setDragCompanyId] = useState(null);

  const dragCompany = useMemo(
    () =>
      dialogSelectedCompaniesObjects.filter((c) => c.id === dragCompanyId)[0],
    [dialogSelectedCompaniesObjects, dragCompanyId],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event) => {
    setDragCompanyId(event.active.id);
  };
  const handleDragEnd = ({ active, over }) => {
    const companiesArray = Array.from(dialogSelectedCompanies);

    if (over.id !== active.id) {
      const newIndex = companiesArray.indexOf(over.id);
      const oldIndex = companiesArray.indexOf(active.id);
      const sortedCompaniesArray = arrayMove(
        companiesArray,
        oldIndex,
        newIndex,
      );
      setDialogSelectedCompaniesIds(sortedCompaniesArray);
    }

    setDragCompanyId(null);
  };
  return (
    <>
      <SelectedCompaniesHeader>
        <SelectedCompaniesLabel>
          {isMobile
            ? `Selected (${dialogSelectedCompaniesObjects.length})`
            : `Selected Companies (${dialogSelectedCompaniesObjects.length})`}
        </SelectedCompaniesLabel>
        <ClearAllCompaniesButton onClick={() => clearAll()}>
          Clear all
          <ClearIcon />
        </ClearAllCompaniesButton>
      </SelectedCompaniesHeader>
      <DndContext
        onDragStart={handleDragStart}
        sensors={sensors}
        collisionDetection={pointerWithin}
        onDragEnd={handleDragEnd}
      >
        <CompaniesList className="margin-bottom">
          <SortableContext
            items={dialogSelectedCompaniesObjects}
            strategy={verticalListSortingStrategy}
          >
            {dialogSelectedCompaniesObjects.map(({ id, name, color }) => (
              <CompanyButton
                key={`company-button-${id}`}
                id={id}
                label={name}
                companyColor={color}
                showXIcon
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {dragCompanyId && dragCompany ? (
              <CompanyButton
                key={`company-button-${dragCompanyId}`}
                id={dragCompany.id}
                label={dragCompany.name}
                companyColor={dragCompany.color}
                showXIcon
              />
            ) : null}
          </DragOverlay>
        </CompaniesList>
      </DndContext>
    </>
  );
};

export default inject('dashboardStore')(observer(SelectedCompaniesList));
