import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { inject, observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import apiRoutes from '@app/apiRoutes';
import API from '@app/api';

import PasswordInput from '@components/Input/PasswordInput';
import { PasswordInstructions } from '@components/PasswordInstructions';
import { newPasswordValidationSchema } from '@utils/newPasswordResolver';

import {
  SectionHeader,
  SectionContainer,
  Form,
  Button,
  ForgottenPassword,
} from '@containers/Settings/styles';

const PasswordChange = ({
  sidebarStore: { menuCollapsed },
  userStore: { user },
}) => {
  const validationSchema = newPasswordValidationSchema();

  const resolver = yupResolver(validationSchema);

  const {
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    control,
    reset,
  } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      reNewPassword: '',
    },
    resolver,
  });

  const changePassword = async (data) => {
    await API.post(apiRoutes.changePassword, data)
      .then(() => {
        toast.success('Password successfully changed!');
      })
      .catch((err) => {
        const errorKey = Object.values(err)[1];
        const errorValue = Object.keys(errorKey)[0];

        toast.error(
          err?.errors ? err.errors[errorValue][0] : 'Something went wrong!',
        );
      });

    reset(
      { newPassword: '', oldPassword: '', reNewPassword: '' },
      { keepDirty: false },
    );
  };

  const onSubmit = (data) => {
    changePassword(data);
  };

  const forgotPassword = async () => {
    await API.post(apiRoutes.resetPassword, {
      email: user.email.toLowerCase(),
    })
      .then(() => {
        toast.info(`An email with instructions has been sent to ${user.email}`);
      })
      .catch(() =>
        toast.error(
          'Something went wrong on attempt to send password reset instructions via e-mail!',
        ),
      );
  };

  return (
    <SectionContainer>
      <SectionHeader>Change Password</SectionHeader>
      <PasswordInstructions />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="oldPassword"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <>
              <PasswordInput
                name={name}
                inputType="password"
                onChange={onChange}
                value={value}
                label="Current Password"
                error={errors[name]}
                autoComplete="true"
                validationSchema={validationSchema}
              />
              <ForgottenPassword onClick={forgotPassword}>
                Forgot Password
              </ForgottenPassword>
            </>
          )}
        />
        <Controller
          name="newPassword"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <PasswordInput
              name={name}
              inputType="password"
              onChange={onChange}
              value={value}
              label="New password"
              error={errors[name]}
              autoComplete="true"
              validationSchema={validationSchema}
            />
          )}
        />
        <Controller
          name="reNewPassword"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <PasswordInput
              name={name}
              inputType="password"
              onChange={onChange}
              value={value}
              label="Repeat new password"
              error={errors[name]}
              autoComplete="true"
              validationSchema={validationSchema}
            />
          )}
        />

        <Button
          disabled={
            !isDirty ||
            Object.keys(dirtyFields).length !== 3 ||
            Object.keys(errors).length !== 0
          }
          type="submit"
          menuCollapsed={menuCollapsed}
        >
          update password
        </Button>
      </Form>
    </SectionContainer>
  );
};
export default inject('sidebarStore', 'userStore')(observer(PasswordChange));
