import styled from '@emotion/styled';
import { InfoCircle } from 'tabler-icons-react';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const LegendContainer = styled.div`
  height: 60px;
  flex: 0 1 auto;
  transition: opacity 0.3s ease-in-out;
  margin-top: 16px;

  &.hidden {
    opacity: 0;
  }
`;

export const List = styled.div`
  display: inline-block;
  text-align: center;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.div`
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 13px;
  min-width: max-content;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
`;

export const CircleSvg = styled.svg`
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 5px;
`;
