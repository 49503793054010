import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 30px 15px;
  width: 100%;
  flex-direction: column;
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.content};
  transition: background-color 0.3s;
  box-shadow: 0px -2px 8px 4px rgba(41, 35, 92, 0.03);
  border-radius: 3px;
  padding: 36px 92px 36px 92px;
  max-width: 640px;

  @media screen and ${breakpoint.lg} {
    padding: 24px 56px 28px 56px;
  }

  @media screen and ${breakpoint.md} {
    padding: 24px 18px 28px 18px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    padding: 5px;
    margin-right: 18px;
  }
`;

export const BackButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  max-width: 660px;
`;

export const Box = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  padding-bottom: 10px;
`;
