import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ChevronLeft } from 'tabler-icons-react';
import { toast } from 'react-toastify';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';
import routes from '@routes';

import { FormContainer, Logo, PageContainer } from '@containers/SignIn/styles';
import {
  StyledForm,
  Header,
  Back,
  Text,
} from '@containers/ForgotPassword/styles';

import Input, { INPUT_TYPES } from '@components/Input';
import Button, { BUTTON_TYPES } from '@components/Button';
import logo from '@app/assets/logo.png';

const ForgotPassword = (props) => {
  const validationSchema = yup.object().shape({
    email: yup.string().email('Email is invalid').required('Email is required'),
  });

  const { action } = props;
  const secureLink = (action === 'secure-link-login' || action === undefined);

  const resolver = yupResolver(validationSchema);
  const {
    handleSubmit,
    formState: { errors: formError, isDirty },
    control,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
      action: (secureLink ? 'secure-link-login' : 'password-reset'),
    },
    resolver,
  });

  let errors = {};

  if (formError && Object.keys(formError).length) {
    errors = formError;
  }

  const onSubmit = async (data) => {
    reset({ email: '' });
    const apiRoute = action === 'password-reset' ? apiRoutes.resetPassword : apiRoutes.forgotPassword;
    await API.post(apiRoute, {
      email: data.email.toLowerCase(),
    })
      .then(() => {
        toast.success(
          `An email with instructions has been sent to ${data.email}`,
        );
      })
      .catch(() => toast.error('Something went wrong!'));
  };

  return (
    <PageContainer>
      <FormContainer>
        <Back to={routes.settings}>
          <ChevronLeft strokeWidth={2} size={22} color="#7F7B9D" />
          <Text>Back</Text>
        </Back>
        <Logo alt="logo" src={logo} />
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Header>{ secureLink ? 'Send a secure link' : 'Forgot password' }</Header>
          <Controller
            name="email"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                inputType={INPUT_TYPES.TEXT}
                onChange={onChange}
                value={value.toLowerCase()}
                label="Email address"
                error={errors[name]}
              />
            )}
          />
          <Button
            type={BUTTON_TYPES.SUBMIT}
            disabled={!isDirty || Object.keys(errors).length !== 0}
          >
            Send Link
          </Button>
        </StyledForm>
      </FormContainer>
    </PageContainer>
  );
};

export default ForgotPassword;
