import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { breakpoint } from '@styles/breakpoints';

export const Section = styled.div`
  margin-bottom: 30px;

  @media screen and ${breakpoint.md} {
    margin-bottom: 28px;
  }
`;

export const MailTo = styled.a`
  color: ${({ theme }) => theme.font.default};
  :hover {
    text-decoration: none;
    opacity: 0.8;
  }
`;

export const Spacer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.h2`
  font-weight: 700;
  font-size: ${({ theme, bigSize }) =>
    bigSize ? theme.defaults.fontSizeXXL : theme.defaults.fontSizeXL}px;
  line-height: 22px;
  margin-bottom: ${({ bigSize }) => (bigSize ? '18px' : '8px')};
  color: ${({ theme }) => theme.colors.secondary};
`;
export const Text = styled.p`
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  line-height: ${({ isValue }) => (isValue ? '26px' : '22px')};
  color: ${({ theme, isValue }) =>
    isValue ? theme.colors.secondary : theme.font.secondary};
`;

export const Divider = styled.div`
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.font.secondary};
`;

export const ButtonTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLink = styled(Link)`
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.secondary};
`;
export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`;
export const ConfirmButton = styled.button`
  width: 100%;
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  max-width: 200px;
  border-radius: 40px;
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  height: 50px;
  cursor: pointer;

  &:hover {
    background-color: rgba(103, 147, 255, 0.05);
  }
  &.max-content {
    width: max-content;
  }
`;

export const FooterText = styled.p`
  font-weight: 600;
  font-size: ${({ theme }) => theme.defaults.fontSizeXXL}px;
  line-height: 22px;
`;
