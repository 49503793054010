import React, { useEffect, useState, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Clock, ListDetails } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import DashboardDropdown from '@components/DashboardSelect';
import DateRangeSelector from '@components/DatesRangeSelector';
import { PRESETS } from '@root/constants/dashboard';
import {
  DashboardSettingsMobileContainer,
  Button,
  BespokeButton,
  EyeIcon,
  ManageButtonContainer,
  OtherSettingsContainer,
  EyeOffIcon,
} from '@components/DashboardSettingsMobile/styles';

const DashboardSettingsMobile = ({
  dashboardStore: {
    copySelectedCompanies,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    showBespoke,
    setShowBespoke,
  },
}) => {
  const [preset, setPreset] = useState('Custom');
  const navigate = useNavigate();

  const changeDatesHandler = useCallback((dates) => {
    if (dates.startDate && dates.endDate && dates.startDate <= dates.endDate) {
      setStartDate(dates.startDate);
      setEndDate(dates.endDate);
      setPreset('Custom');
    }
  }, []);

  useEffect(() => {
    if (preset && preset !== 'Custom') {
      const presetObject = PRESETS.find(({ value }) => value === preset);
      if (presetObject && presetObject.from && presetObject.to) {
        setStartDate(presetObject.from);
        setEndDate(presetObject.to);
      }
    }
  }, [preset]);

  return (
    <DashboardSettingsMobileContainer>
      <ManageButtonContainer>
        <Button
          className="max-content"
          onClick={() => {
            navigate('/dashboard/dialog');
            copySelectedCompanies();
          }}
        >
          <ListDetails />
        </Button>
        <BespokeButton
          className="max-content"
          showBespoke={showBespoke}
          onClick={() => setShowBespoke(!showBespoke)}
        >
          Bespoke
          {showBespoke ? <EyeIcon size={24} /> : <EyeOffIcon size={24} />}
        </BespokeButton>
        <DashboardDropdown
          minWidth={120}
          id="preset-select"
          name="Preset"
          icon={<Clock size={18} />}
          onChange={setPreset}
          value={preset}
          options={PRESETS}
          placeholder="Preset"
        />
      </ManageButtonContainer>
      <OtherSettingsContainer className="center">
        <DateRangeSelector
          onChange={changeDatesHandler}
          startDate={startDate}
          endDate={endDate}
          constraintDate={moment('01-01-2017')}
          isMobileDashboard
          alwaysExpanded
          showClearDates={false}
          allowOneDayRange
        />
      </OtherSettingsContainer>
    </DashboardSettingsMobileContainer>
  );
};

DashboardSettingsMobile.propTypes = {
  dashboardStore: PropTypes.shape({
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default inject('dashboardStore')(observer(DashboardSettingsMobile));
