import React, { useState, useCallback, useEffect } from 'react';
import { Button } from '@containers/Dashboard/styles';

import ExcelDownload from '@components/ExcelDownload';
import {
  ExportDropdown,
  ExportMenu,
  ExportMenuItem,
  ImageIcon,
} from '@components/DashboardExportDropdown/styles';

export const DashboardExportDropdown = ({
  data,
  isPieChart,
  keyNames,
  rowKey,
  saveImage,
  formatLabel,
  name,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const closeMenu = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  useEffect(() => {
    window.addEventListener('click', closeMenu);

    return () => {
      window.removeEventListener('click', closeMenu);
    };
  }, []);
  return (
    <ExportDropdown
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
      }}
      isOpened={isDropdownOpen}
      className="user-menu"
    >
      Export
      <ExportMenu>
        <ExportMenuItem>
          <ExcelDownload
            data={data}
            name={name}
            isPieChart={isPieChart}
            keys={keyNames}
            rowKey={rowKey}
            formatLabel={formatLabel}
          />
        </ExportMenuItem>
        <ExportMenuItem>
          <Button onClick={saveImage}>
            PNG
            <ImageIcon strokeWidth={1} />
          </Button>
        </ExportMenuItem>
      </ExportMenu>
    </ExportDropdown>
  );
};
