import React, { useMemo } from 'react';

import {
  BuildingSkyscraper,
  ChartAreaLine,
  Logout,
  Mail,
  News,
  Settings,
  World,
} from 'tabler-icons-react';
import { inject, observer } from 'mobx-react';
import DesktopSideBar from '@components/SideBar/components/DesktopSideBar';
import useBreakpoint from '@root/hooks/useBreakpoint';
import MobileSideBar from '@components/SideBar/components/MobileSideBar';
import routes from '@routes';
import DarkModeSwitch from '@components/DarkModeSwitch';

const MENU_ITEMS = [
  {
    label: 'My News',
    key: 'my-news',
    icon: <News strokeWidth={1} size={32} color="white" />,
    submenuItemsKey: 'myNews',
  },
  {
    label: 'Briefings',
    key: 'briefings',
    submenuItemsKey: 'briefingTypes',
    icon: <World strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Companies',
    key: 'companies',
    submenuItemsKey: 'sectors',
    icon: <BuildingSkyscraper strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Dashboard',
    key: 'dashboard',
    path: routes.dashboard,
    icon: <ChartAreaLine strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Settings',
    key: 'settings',
    path: routes.settings,
    icon: <Settings strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Contact Us',
    key: 'contact-us',
    path: routes.contact,
    icon: <Mail strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Logout',
    key: 'logout',
    path: routes.auth.logout,
    icon: <Logout strokeWidth={1} size={32} color="white" />,
  },
  {
    label: 'Dark Mode',
    key: 'dark-mode',
    icon: <DarkModeSwitch />,
  },
];

const SideBar = ({ mobileLabel, userStore: { isDashboardEnabled } }) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const menuItems = useMemo(
    () =>
      isDashboardEnabled
        ? MENU_ITEMS
        : MENU_ITEMS.filter(({ key }) => key !== 'dashboard'),
    [isDashboardEnabled],
  );

  if (isMobile) {
    return <MobileSideBar mobileLabel={mobileLabel} menuItems={menuItems} />;
  }
  return <DesktopSideBar menuItems={menuItems} />;
};

export default inject('userStore')(observer(SideBar));
