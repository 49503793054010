import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  input:focus,
  input {
    outline: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .reset {
    position: absolute;
    top: 9px;
    right: 39%;
    cursor: pointer;
    background-color: #f0f4ff;
    stroke: ${({ theme }) => theme.colors.secondary};
  }
`;

export const MultipleInputs = styled.div`
  position: relative;
  display: flex;

  .input-container {
    padding-right: 105px;
  }

  .custom-select {
    position: absolute;
    top: -5px;
    right: 0;
    min-width: 105px;
    z-index: 10;

    .react-select__control {
      background-color: ${({ theme }) => theme.inputs.secondary.background};
      cursor: pointer;
      border: none;
      border-radius: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    .react-select__single-value {
      font-weight: 400;
      font-size: ${({ theme }) => theme.defaults.fontSize}px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #7f7b9d;
      margin-bottom: 4px;
    }

    .react-select__option {
      cursor: pointer;
    }

    .react-select__indicator {
      margin-bottom: 4px;
    }

    .react-select__value-container {
      margin-top: 4px;
    }
  }

  @media only screen and ${breakpoint.sm} {
    width: 100%;
    margin-right: 45px;
  }
`;

export const SingleInput = styled.div`
  position: relative;
  width: 100%;
  max-width: 416px;

  .input {
    padding-right: 35px;
  }

  .reset {
    position: absolute;
    top: 9px;
    right: 10px;
    cursor: pointer;
  }
`;

export const Border = styled.span`
  height: 60%;
  width: 1px;
  position: absolute;
  right: 110px;
  top: 8px;
  background-color: ${({ theme }) => theme.colors.secondary};
`;
