import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Preloader from '@components/Preloader';
import routes from '@app/routes';
import Text, { COLORS, TAGS } from '@components/Text';
import useBreakpoint from '@root/hooks/useBreakpoint';
import {
  Company,
  CompanyName,
  Button,
  Top,
  VerticalAlphabetSearch,
  ContentWrapper,
  SearchContainer,
  FilterNotFound,
  AlphabetButton,
  AlphabetSearchHorizontal,
  AlphabetSearchHorizontalContainer,
  CompaniesGrid,
  CenterBox,
  PageTitleWrapper,
  PageTitle,
} from '@containers/Companies/styles';
import SearchBar from '@components/SearchBar';
import { generateAlphabetWithIds } from '@utils/companiesUtils';

const CompaniesBox = ({
  companiesStore: {
    isInitialLoad,
    fetchPending,
    companies,
    fetchCompanies,
    counts,
    handleFollow,
    filtered,
    companiesQuery,
    filterCompanies,
    processedCompanies,
  },
  sidebarStore: {
    menuCollapsed,
    submenusItems: { sectors },
  },
  type,
}) => {
  const [searchBarHidden, setSearchBarHidden] = useState(false);
  const navigate = useNavigate();
  const [chosenLetter, setChosenLetter] = useState('');
  useEffect(() => {
    let oldValue = 0;
    let newValue = 0;
    window.addEventListener('scroll', () => {
      newValue = window.pageYOffset;
      if (oldValue + 5 < newValue) {
        setSearchBarHidden(true);
      } else if (oldValue > newValue + 5) {
        setSearchBarHidden(false);
      }
      oldValue = newValue;
    });
  }, []);

  useEffect(() => {
    (async () => {
      await fetchCompanies(type);
    })();
  }, [type]);

  useEffect(() => {
    filterCompanies(companiesQuery);
  }, [companiesQuery]);

  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  const findHeader = sectors.find(
    (item) => item.contentContainerId.toString() === type,
  );

  const renderHeader = () => {
    switch (type) {
      case 'all':
        return 'All companies | ';
      case 'following':
        return '';
      default:
        return `${findHeader?.label} | `;
    }
  };

  const companiesList = filtered?.length ? filtered : companies;

  const alphabet = generateAlphabetWithIds(companiesList, 'name', 'id');

  const showNoCompanies = counts?.all === 0;
  const showNotFound = !filtered?.length && companiesQuery?.length > 0;

  const goToLetter = (label) =>
    document
      .getElementById(`company-${label}`)
      .scrollIntoView({ block: 'center', behavior: 'smooth' });

  if (isInitialLoad) {
    return (
      <ContentWrapper>
        <Preloader overlay />
      </ContentWrapper>
    );
  }

  if (type === 'all' && companies && !companies.length && !fetchPending) {
    navigate(routes.trial);
  }

  return (
    <>
      {type === 'all' && Object.keys(alphabet).length > 0 ? (
        <AlphabetSearchHorizontalContainer
          show={!searchBarHidden}
          menuCollapsed={menuCollapsed}
        >
          <AlphabetSearchHorizontal>
            {Object.keys(alphabet).map((letter) => (
              <AlphabetButton
                chosen={chosenLetter === letter}
                tabIndex={0}
                role="button"
                onKeyDown={() => {}}
                onClick={() => {
                  setChosenLetter(letter);
                  goToLetter(alphabet[letter]);
                }}
                key={letter}
              >
                {letter}
              </AlphabetButton>
            ))}
          </AlphabetSearchHorizontal>
        </AlphabetSearchHorizontalContainer>
      ) : null}
      <ContentWrapper>
        {isMobile ? (
          <SearchContainer searchBarHidden={searchBarHidden}>
            <SearchBar placeholder="Search" withoutButton />
          </SearchContainer>
        ) : null}
        {showNotFound || showNoCompanies ? (
          <>
            {showNotFound && !showNoCompanies && (
              <FilterNotFound>
                Sorry, no companies found by that name
                {type !== 'all' &&
                  findHeader?.label &&
                  ` in ${findHeader?.label}`}
                . Try looking for something else!
              </FilterNotFound>
            )}
            {
              showNoCompanies && navigate(routes.trial)
            }
          </>
        ) : (
          <>
            <PageTitleWrapper>
              <PageTitle>
                {fetchPending && <Preloader overlay />}
                {filtered.length ? (
                  <Text color={COLORS.TERTIARY} tag={TAGS.H1}>
                    {filtered.length === 1 ? 1 : filtered.length}
                    {filtered.length > 1 ? ' Companies' : ' Company'} found
                  </Text>
                ) : (
                  <Text color={COLORS.TERTIARY} tag={TAGS.H1}>
                    {renderHeader()} Following {counts.followed}{' '}
                    {counts.followed !== 1 ? ' companies' : ' company'}
                  </Text>
                )}
              </PageTitle>
            </PageTitleWrapper>
            {type === 'all' ? (
              <VerticalAlphabetSearch>
                {Object.keys(alphabet).map((letter) => (
                  <AlphabetButton
                    chosen={chosenLetter === letter}
                    tabIndex={0}
                    role="button"
                    onKeyDown={() => {}}
                    onClick={() => {
                      setChosenLetter(letter);
                      goToLetter(alphabet[letter]);
                    }}
                    key={letter}
                  >
                    {letter}
                  </AlphabetButton>
                ))}
              </VerticalAlphabetSearch>
            ) : null}
            <CompaniesGrid className="companies">
              {companiesList?.map((company) => (
                <Company
                  id={`company-${company.id}`}
                  key={`company-${company.id}`}
                  followed={company.follow}
                  collapsed={menuCollapsed}
                  color={company.color}
                >
                  {processedCompanies.includes(company.id) && (
                    <CenterBox>
                      <Preloader />
                    </CenterBox>
                  )}

                  <Link to={`${routes.singleCompany(company.id, ['latest'])}`}>
                    <Top isLoading={processedCompanies.includes(company.id)}>
                      <CompanyName>{company.name}</CompanyName>
                      <img
                        src={
                          company.follow ? company.icon : company.colorized_icon
                        }
                        alt={company.name}
                      />
                    </Top>
                  </Link>
                  <Button
                    onClick={() => handleFollow(company.id, company.follow)}
                    disabled={processedCompanies.includes(company.id)}
                  >
                    {company.follow ? 'unfollow' : 'follow'}
                  </Button>
                </Company>
              ))}
            </CompaniesGrid>
          </>
        )}
      </ContentWrapper>
    </>
  );
};
export default inject('companiesStore', 'sidebarStore')(observer(CompaniesBox));
