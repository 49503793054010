import React, { memo, useMemo } from 'react';
import moment from 'moment';
import ShareButton from '@components/ShareButton';
import Text, { TAGS } from '@components/Text';
import LexisNexisRedLogo from '@app/assets/LexisNexisRed.svg';
import { addTargetProperty } from '@utils/articleUtils';
import {
  ArticleWrapper,
  Analyst,
  Description,
  Source,
  Title,
  Published,
  ReadMore,
  Bottom,
  Content,
  Tag,
  LexisNexisLogo,
  LexisNexisLogoContainer,
  ReadMoreContainer,
} from '@components/NewsSection/style';
import routes from '@routes';

const Article = ({ article }) => {
  const publishedFromNow = useMemo(
    () => moment(article.date).fromNow(),
    [article.date],
  );

  let mailBody = `${article.description}`;

  if (article.author) {
    mailBody += `${encodeURI('\n\n\n')}${article.author} for ${
      article.source
    }.`;
  } else {
    mailBody += `${encodeURI('\n\n\n')}${article.source}`;
  }

  if (article.link) {
    mailBody += `${encodeURI('\n')}Article link: ${article.link}`;
  }

  return (
    <ArticleWrapper>
      <a href={`mailto:?subject=${article.title}&body=${mailBody}`}>
        <ShareButton />
      </a>

      <Source>
        <Text>{article.source}</Text>
        {article.briefing_section ? (
          <Tag>{article.briefing_section}</Tag>
        ) : null}
      </Source>
      <Title>
        <Text tag={TAGS.H2}>{article.title}</Text>
      </Title>
      <Published>
        <Text>
          <>
            {article.author ? (
              <>
                {article.author}
                <span className="separator">|</span>
              </>
            ) : null}
            {publishedFromNow}
          </>
        </Text>
      </Published>

      <Description>
        <Content
          dangerouslySetInnerHTML={{
            __html: addTargetProperty(article.description),
          }}
        />
      </Description>

      <Bottom>
        <Analyst>Analyst: {article.sumAuthor}</Analyst>
        <ReadMoreContainer>
          <LexisNexisLogoContainer>
            {article.brand === 'lexis_nexis' && article.direct_ln_links_allowed !== true && (
              <LexisNexisLogo src={LexisNexisRedLogo} />
            )}
          </LexisNexisLogoContainer>
          {article.link && (
            <ReadMore
              href={
                article.link.includes('briefcase.news/client/section')
                  ? routes.article(article.id)
                  : article.link
              }
              target={
                article.link.includes('briefcase.news/client/section')
                  ? '_self'
                  : '_blank'
              }
              rel="noreferrer"
            >
              Read full story
            </ReadMore>
          )}
        </ReadMoreContainer>
      </Bottom>
    </ArticleWrapper>
  );
};

export default memo(Article);
