import axios from 'axios';
import qs from 'qs';
import { makeAutoObservable, runInAction } from 'mobx';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';

export class PaymentMethodStore {

  error = null;

  params = {};

  loadedCards = false;

  cards = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchCards = async () => {
    if (this.loadedCards) {
      return;
    }
    try {
      const { data: cards } = await API.get(apiRoutes.stripeCards);
      runInAction(() => {
        this.cards = cards || [];
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.loadedCards = true;
      });
    }
  }

  makeCardDefault = async ({ id }) => {
    try {
      await API.post(apiRoutes.stripeCardDefault(id));
      this.loadedCards = false;
      this.fetchCards();
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
  };

  deleteCard = async ({ id }) => {
    try {
      await API.delete(apiRoutes.stripeCard(id));
      this.loadedCards = false;
      this.fetchCards();
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
  };

  addCard = async ({ data }) => {
    try {
      const [month, year] = data.expiryDate.split('/');
      const stripeApi = axios.create({
        baseURL: process.env.REACT_APP_STRIPE_URL,
      });
      const { data: { id: token } } = await stripeApi.post(
        '/v1/tokens',
        qs.stringify({
          card: {
            number: data.cardNumber,
            exp_month: month,
            exp_year: parseInt(year, 10) + 2000,
            cvc: data.securityCode,
          },
        }),
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_API_KEY}`,
          }
        }
      );
      await API.post(apiRoutes.stripeCards, { token });
      this.loadedCards = false;
      this.fetchCards();
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
  };
}

export default new PaymentMethodStore();