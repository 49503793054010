import moment from 'moment';
import { CHART_COLORS } from '@root/constants/dashboard';

const generateDates = (interval, startDate, endDate) => {
  moment.locale('en', {
    week: {
      dow: 1,
    },
  });
  const start = moment(startDate).locale('en').startOf(interval);
  const end = moment(endDate).locale('en').startOf(interval);
  const diff = end.diff(start, interval);

  const result = [start.format('YYYY-MM-DD').toString()];

  if (diff === 0) {
    return result;
  }

  for (let i = 1; i <= diff; i += 1) {
    result.push(start.add(1, interval).format('YYYY-MM-DD').toString());
  }
  return result;
};

export const chartDataWithAllTicks = (
  chartData,
  interval,
  startDate,
  endDate,
) => {
  if (chartData.length === 0) {
    return [];
  }
  if (interval === 'total') {
    return chartData;
  }
  const newChartData = [];
  const keys = Object.keys(chartData[0]).filter(
    (key) => key !== 'sum' && key !== 'date',
  );
  const dates = generateDates(interval, startDate, endDate);
  let dataIndex = 0;
  for (let i = 0; i < dates.length; i += 1) {
    if (chartData[dataIndex] && chartData[dataIndex].date === dates[i]) {
      newChartData.push(chartData[dataIndex]);
      dataIndex += 1;
    } else {
      const item = {
        date: dates[i],
        sum: 0,
      };
      keys.forEach((key) => {
        item[key] = 0;
      });
      newChartData.push(item);
    }
  }

  return newChartData;
};

export const totalDataForTotalCoverage = (chartData) => {
  const dataTotal = [];
  if (chartData) {
    chartData.forEach((datum) => {
      dataTotal.push({ name: datum.name, value: datum[datum.name] });
    });
  }
  return dataTotal;
};

export const dateTickFormatter = (tick, dataKey, format = 'DD-MM-YYYY') => {
  if (dataKey === 'date') {
    return moment(tick).format(format).toString();
  }
  return tick;
};

export const aggregateData = (data) =>
  data.map(({ date, sum }) => ({ date, Total: sum }));

export const nonZeroKeys = ({ keys, data }) => {
  const keyNamesArray = keys.map((key) => key.name);
  const keyNames = [];

  if (data) {
    data.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (
          value &&
          value !== 0 &&
          keyNames.indexOf(key) < 0 &&
          keyNamesArray.indexOf(key) >= 0
        ) {
          keyNames.push(key);
        }
      });
    });

    return keys.filter((key) => keyNames.indexOf(key.name) >= 0);
  }
  return [];
};

export const mapCompaniesColors = (defaultCompanies) =>
  defaultCompanies.map((company, index) => ({
    name: company.name,
    color: CHART_COLORS[index % 32],
    companyColor: company.color,
  }));

export const preparePercentData = (chartData, labels) => {
  const percentChartData = JSON.parse(JSON.stringify(chartData));
  percentChartData.forEach((data) => {
    labels.forEach((label) => {
      if (data.sum > 0 && data[label]) {
        // eslint-disable-next-line no-param-reassign
        data[label] = ((data[label] / data.sum) * 100).toFixed(2);
      }
    });
  });
  return percentChartData;
};

export const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const formatName = (value) => titleCase(value.replace('_', ' '));
