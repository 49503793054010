import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const BUTTON_THEMES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const PrimaryButton = (theme) => css`
  background-color: ${theme.buttons.primary.background};
  color: ${theme.buttons.primary.textColor};
  font-weight: ${theme.defaults.fontWeight.bold};
  border: 1px solid ${theme.buttons.primary.borderColor};
  transition: background-color 0.5s;

  &:hover {
    background-color: ${theme.buttons.primary.hover};
    border-color: ${theme.buttons.primary.hover};
  }

  &:disabled:hover {
    background-color: ${theme.colors.disabled};
    border-color: ${theme.colors.disabled};
    cursor: default;
  }
`;

const SecondaryButton = (theme) => css`
  font-weight: ${theme.defaults.fontWeight.bold};
  background-color: ${theme.buttons.secondary.background};
  color: ${theme.buttons.secondary.textColor};
  border: 1px solid ${theme.buttons.secondary.borderColor};

  &:hover {
    color: ${theme.buttons.primary.hover};
    border-color: ${theme.buttons.primary.hover};
  }
`;

const THEMES = {
  [BUTTON_THEMES.PRIMARY]: (theme) => PrimaryButton(theme),
  [BUTTON_THEMES.SECONDARY]: (theme) => SecondaryButton(theme),
};

export const StyledButton = styled.button`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  min-width: 120px;
  height: 40px;
  border-radius: 3px;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  justify-content: center;
  position: relative;

  .preloader {
    z-index: 2;
    position: absolute;
  }

  &:before {
    content: '';
    display: ${({ loading }) => (loading === 'true' ? 'block' : 'none')};
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    border: none;
    cursor: default;
  }

  ${({ buttonTheme, theme }) => THEMES[buttonTheme](theme)}
`;
