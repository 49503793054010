import React from 'react';
import { useParams } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { inject, observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import logo from '@app/assets/logo.png';
import PasswordInput from '@components/Input/PasswordInput';
import {
  PageContainer,
  FormContainer,
  Logo,
  StyledForm,
  SubmitContainer,
  StyledSubmit,
  Header,
} from '@containers/ResetPassword/styles';
import { PasswordInstructions } from '@components/PasswordInstructions';
import { newPasswordValidationSchema } from '@utils/newPasswordResolver';

const ResetPasswordPage = ({ authStore: { changePassword, setPasswordUsingInvitation }, action }) => {
  const { token } = useParams();

  const acceptInvitation = action === 'accept-invitation';

  const validationSchema = newPasswordValidationSchema();
  const resolver = yupResolver(validationSchema);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = useForm({
    defaultValues: {
      token,
      newPassword: '',
      reNewPassword: '',
    },
    resolver,
    token,
  });

  const onSubmit = async (data) => {
    if (!acceptInvitation) {
      changePassword(data);
    } else {
      setPasswordUsingInvitation(data);
    }

    reset({ newPassword: '', reNewPassword: '' }, { keepDirty: false });
  };

  return (
    <PageContainer>
      <FormContainer>
        <Logo alt="logo" src={logo} />
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Header>{ acceptInvitation ? 'Welcome to Briefcase.news! Secure your account with a password.' : 'Change password' }</Header>
          <PasswordInstructions />
          <Controller
            name="newPassword"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <PasswordInput
                name={name}
                inputType="password"
                onChange={onChange}
                value={value}
                label="New password"
                error={errors[name]}
                autoComplete="true"
                validationSchema={validationSchema}
              />
            )}
          />
          <Controller
            name="reNewPassword"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <PasswordInput
                name={name}
                inputType="password"
                onChange={onChange}
                value={value}
                label="Repeat new password"
                error={errors[name]}
                autoComplete="true"
                validationSchema={validationSchema}
              />
            )}
          />
          <SubmitContainer>
            <StyledSubmit
              style={{ width: '100%', height: 40 }}
              type="submit"
              disabled={!isDirty || Object.keys(errors).length !== 0}
            >
              { acceptInvitation ? 'Set password' : 'Update password' }
            </StyledSubmit>
          </SubmitContainer>
        </StyledForm>
      </FormContainer>
    </PageContainer>
  );
};

export default inject('authStore')(observer(ResetPasswordPage));
