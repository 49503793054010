import React from 'react';
import Select, { components } from 'react-select';
import { Trash } from 'tabler-icons-react';
import { useTheme } from '@emotion/react';
import {
  Container,
  Label,
  ErrorText,
  ReactSelectStyles,
  OptionContainer,
  LabelContainer,
  SingleValueContainer,
} from '@components/DashboardSelect/styles';

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <SingleValueContainer>
      <div>{props.selectProps.icon}</div>
      {props.selectProps.prefix}
      {children}
    </SingleValueContainer>
  </components.SingleValue>
);

const OptionWithDelete = ({ children, ...props }) => (
  <components.Option {...props}>
    <OptionContainer>
      <LabelContainer>{children}</LabelContainer>
      <LabelContainer
        onClick={(e) => {
          e.stopPropagation();
          props.selectProps.onOptionDelete(children);
        }}
      >
        <Trash size={18} strokeWidth={1} color="red" />
      </LabelContainer>
    </OptionContainer>
  </components.Option>
);

function DashboardDropdown({
  onChange,
  options,
  label,
  error,
  placeholder,
  value,
  name,
  id,
  onBlur,
  isSearchable,
  prefix,
  maxWidth,
  showDelete,
  onOptionDelete,
  icon,
  minWidth,
}) {
  const selectId = `dashboard_dropdown_${name}-${id}`;
  const theme = useTheme();

  return (
    <Container maxWidth={maxWidth} minWidth={minWidth}>
      <Label htmlFor={selectId}>{label}</Label>
      <Select
        menuPlacement="auto"
        isSearchable={isSearchable}
        id={selectId}
        options={options}
        name={name}
        classNamePrefix={`react-select ${error && 'error'}`}
        onChange={(option) => {
          onChange(option.value);
        }}
        onBlur={onBlur}
        styles={ReactSelectStyles(theme)}
        defaultValue=""
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        placeholder={placeholder}
        value={options?.find((x) => x.value === value) || null}
        prefix={prefix}
        onOptionDelete={onOptionDelete}
        components={{
          SingleValue,
          Option: showDelete ? OptionWithDelete : components.Option,
        }}
        icon={icon}
      />

      {error && (
        <ErrorText className="error-message">{error.message}</ErrorText>
      )}
    </Container>
  );
}

DashboardDropdown.defaultProps = {
  placeholder: null,
  id: null,
  onBlur: null,
  isSearchable: false,
  prefix: null,
  icon: null,
};

export default DashboardDropdown;
