import React, { useEffect, useMemo, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { ChevronLeft } from 'tabler-icons-react';
import moment from 'moment';
import { history } from '@app/history';
import Text, { COLORS, TAGS } from '@components/Text';
import ContentBox from '@components/ContentBox';
import Preloader from '@components/Preloader';
import LexisNexisIcon from '@app/assets/LexisNexis.svg';
import LexisNexisWhiteIcon from '@app/assets/LexisNexis_white.svg';

import {
  ArticleWrapper,
  Description,
  Source,
  Title,
  Published,
  Content,
  Header,
} from '@components/NewsSection/style';
import {
  Container,
  HeaderWrapper,
  LexisNexis,
  LexisFooter,
  LexisLink,
} from '@containers/Article/styles';
import { ContentWrapper } from '@styles/globalStyles';

const Article = ({
  articleStore: { fetchArticle, article, isLoading },
  authStore: { darkMode },
}) => {
  const { id: articleId } = useParams();

  useEffect(() => {
    (async () => {
      fetchArticle(articleId);
    })();
  }, []);

  const theme = useTheme();

  const publishedFromNow = useMemo(
    () => moment(article.created_at).fromNow(),
    [article.created_at],
  );

  const publicationDate = useMemo(() => {
    const date = moment(article?.created_at);
    if (!date) {
      return false;
    }
    return date.format('DD dddd, MMMM');
  }, [article]);

  const goBack = useCallback(() => {
    history.goBack();
  }, []);

  if (isLoading) {
    return (
      <ContentWrapper>
        <Preloader overlay />
      </ContentWrapper>
    );
  }

  return (
    <Container>
      {publicationDate ? (
        <Header>
          <Text color={COLORS.TERTIARY} tag={TAGS.H1}>
            <HeaderWrapper>
              <ChevronLeft
                color={theme.font.default}
                onClick={goBack}
                size={42}
              />
              {publicationDate}
            </HeaderWrapper>
          </Text>
        </Header>
      ) : null}
      <ContentBox title={article?.source}>
        {article?.lexis_nexis_licensed && (
          <LexisNexis darkMode={darkMode}>
            <img
              src={darkMode ? LexisNexisWhiteIcon : LexisNexisIcon}
              alt="lexis nexis"
            />
          </LexisNexis>
        )}
        <ArticleWrapper>
          <Source>
            <Text>{article?.source}</Text>
          </Source>
          <Title>
            <Text tag={TAGS.H2}>{article.title}</Text>
          </Title>
          <Published>
            <Text>
              <>
                {article.author ? (
                  <>
                    {article.author}
                    <span className="separator">|</span>
                  </>
                ) : null}
                {publishedFromNow}
              </>
            </Text>
          </Published>

          <Description>
            <Content
              dangerouslySetInnerHTML={{
                __html: article?.content?.replace(/\n/g, '<br />'),
              }}
            />
          </Description>
          {article?.lexis_nexis_licensed && (
            <LexisFooter>
              <LexisLink
                href="https://www.lexisnexis.com/en-us/terms/general/default.page"
                target="_blank"
              >
                LexisNexis Terms &#38; Conditions
              </LexisLink>
              <LexisLink
                href="https://www.lexisnexis.com/en-us/terms/privacy-policy.page"
                target="_blank"
              >
                Privacy Policy
              </LexisLink>
              <LexisLink
                href="https://www.lexisnexis.com/terms/copyright.aspx"
                target="_blank"
              >
                &copy; 2022 LexisNexis
              </LexisLink>
            </LexisFooter>
          )}
        </ArticleWrapper>
      </ContentBox>
    </Container>
  );
};

export default inject('articleStore', 'authStore')(observer(Article));
