import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const NewContentContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 120px;
  width: calc(
    100% -
      ${({ menuCollapsed, theme }) =>
        menuCollapsed ? '96px' : `${theme.defaults.desktopMenuWidth}px`}
  );
  transition: all 0.3s;

  @media screen and ${breakpoint.md} {
    width: 100%;
  }
`;

export const NewContentBanner = styled.div`
  display: flex;
  width: 224px;
  line-height: 24px;
  justify-content: space-around;
  text-align: center;
  padding: 12px;
  border-radius: 33px;
  color: ${({ theme }) => theme.buttons.primary.textColor};
  background-color: ${({ theme }) => theme.buttons.primary.background};
  cursor: pointer;

  animation: pulse 3s infinite;
  @keyframes pulse {
    0% {
      scale: 1;
    }
    50% {
      scale: 1.1;
    }
    100% {
      scale: 1;
    }
  }
`;
