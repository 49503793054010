import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import TotalCoverage from '@containers/Dashboard/components/TotalCoverage';
import TopSources from '@containers/Dashboard/components/TopSources';
import TopJournalists from '@containers/Dashboard/components/TopJournalists';
import {
  TopSourcesTopJournalistsContainer,
  Tab,
  TabList,
  TabPanel,
  Label,
  Line,
} from '@containers/Dashboard/styles';
import SentimentAnalysis from '@containers/Dashboard/components/SentimentAnalysis';
import FullReport from '@containers/Dashboard/components/FullReport';
import useBreakpoint from '@root/hooks/useBreakpoint';
import DashboardSettingsMobile from '@components/DashboardSettingsMobile';
import Preloader from '@components/Preloader';

const Dashboard = ({
  dashboardStore: {
    isLoading,
    fetchCharts,
    totalCoverageInterval,
    fetchTotalCoverage,
    fetchCompanies,
    selectedCompanies,
    isHydrated,
    allCompanies,
    setDefaultSelection,
    startDate,
    endDate,
    showBespoke,
    updateSelectedCompaniesObjects,
  },
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (allCompanies.length === 0) {
      fetchCompanies();
    }
  }, [allCompanies]);

  useEffect(() => {
    if (isHydrated && selectedCompanies.size === 0 && allCompanies.length > 0) {
      setDefaultSelection();
    }
  }, [isHydrated, allCompanies, selectedCompanies]);

  useEffect(() => {
    updateSelectedCompaniesObjects();
  }, [selectedCompanies]);

  useEffect(() => {
    if (selectedCompanies.size > 0 && allCompanies.length > 0) {
      fetchTotalCoverage({
        startDate,
        endDate,
        showBespoke,
        totalCoverageInterval,
        selectedCompanies,
      });
    }
  }, [
    totalCoverageInterval,
    selectedCompanies,
    startDate,
    endDate,
    showBespoke,
    allCompanies,
  ]);

  useEffect(() => {
    if (selectedCompanies.size > 0 && allCompanies.length > 0) {
      fetchCharts({
        startDate,
        endDate,
        showBespoke,
        selectedCompanies,
      });
    }
  }, [selectedCompanies, startDate, endDate, showBespoke, allCompanies]);

  if (isLoading) {
    return <Preloader overlay />;
  }

  if (isMobile) {
    return (
      <ContentWrapper className="dashboard-mobile">
        <TabList>
          <Tab onClick={() => setTabIndex(0)}>
            <Label>Total Coverage</Label>
            <Line className={tabIndex === 0 ? 'active' : ''} />
          </Tab>
          <Tab onClick={() => setTabIndex(1)}>
            <Label>Top Sources</Label>
            <Line className={tabIndex === 1 ? 'active' : ''} />
          </Tab>
          <Tab onClick={() => setTabIndex(2)}>
            <Label>Top Journalists</Label>
            <Line className={tabIndex === 2 ? 'active' : ''} />
          </Tab>
          <Tab onClick={() => setTabIndex(3)}>
            <Label>Sentiment Analysis</Label>
            <Line className={tabIndex === 3 ? 'active' : ''} />
          </Tab>
        </TabList>
        <DashboardSettingsMobile />
        <TabPanel index={0} currentIndex={tabIndex}>
          <TotalCoverage />
        </TabPanel>
        <TabPanel index={1} currentIndex={tabIndex}>
          <TopSources />
        </TabPanel>
        <TabPanel index={2} currentIndex={tabIndex}>
          <TopJournalists />
        </TabPanel>
        <TabPanel index={3} currentIndex={tabIndex}>
          <SentimentAnalysis />
        </TabPanel>
        <FullReport />
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <TotalCoverage />
      <TopSourcesTopJournalistsContainer>
        <TopSources />
        <TopJournalists />
      </TopSourcesTopJournalistsContainer>
      <SentimentAnalysis />
      <FullReport />
    </ContentWrapper>
  );
};

export default inject('dashboardStore')(observer(Dashboard));
