/* eslint-disable class-methods-use-this */
import moment from 'moment';

class DashboardStorageController {
  getItem(key) {
    const item = window.localStorage.getItem(key);
    let store = {};
    if (item) {
      store = JSON.parse(item);
    }
    if (store.selectedCompanies) {
      store.selectedCompanies = new Set(store.selectedCompanies);
    }

    if (store.startDate) {
      store.startDate = moment(store.startDate);
    }

    if (store.endDate) {
      store.endDate = moment(store.endDate);
    }
    return store;
  }

  removeItem(key) {
    window.localStorage.removeItem(key);
  }

  setItem(key, value) {
    const { selectedCompanies } = value;
    if (selectedCompanies) {
      value.selectedCompanies = [...selectedCompanies];
    }
    window.localStorage.setItem(key, JSON.stringify(value));
  }
}

export default DashboardStorageController;
