import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MaskedInput from 'react-text-mask';
import { breakpoint } from '@styles/breakpoints';

export const PaymentMethodContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.mainBackground};
  width: 100%;
  padding-bottom: 32px;
  transition: background-color 0.3s;
  font-family: Nunito;
`;

export const ContentWrapper = styled.div`
  width: 1088px;
  height: calc(100% - 160px);
  display: flex;
  margin: 0 auto;
  grid-column-gap: 32px;
  margin-top: 56px;

  @media only screen and (max-width: 1500px) {
    padding: 0 15px;
  }

  @media only screen and (max-width: 1380px) {
    width: 800px;
  }

  @media only screen and ${breakpoint.lg} {
    overflow: hidden;
    margin-top: 24px;
    width: 100%;
  } ;
`;

export const PaymentMethodWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: all 0.3s;

  @media only screen and ${breakpoint.lg} {
    min-width: 100%;
    gap: 16px;

    ${({ section }) =>
      section === 'notifications'
        ? css`
            transform: translateX(-100%);
          `
        : null}
`;

export const PaymentMethodHeader = styled.h1`
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSizeXXL};
  color: ${({ theme }) => theme.font.default};
  line-height: 33px;

  @media only screen and ${breakpoint.lg} {
    display: none;
  }
`;

export const SectionContainer = styled.div`
  max-width: 528px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.content};
  transition: background-color 0.3s;
  padding: 32px 24px;
  border-radius: 3px;

  &.following-section {
    padding: 0;

    h2 {
      padding: 32px 32px 0px 32px;
    }
  }

  @media only screen and ${breakpoint.md} {
    &.following-section {
      h2 {
        padding: 32px 12px 0 12px;
      }
    }
  }

  ${({ menuCollapsed }) =>
    !menuCollapsed
      ? css`
          @media only screen and ${breakpoint.md} {
            padding: 32px 12px;
          }
        `
      : null}
`;

export const SectionHeader = styled.h2`
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  line-height: 25px;
  color: ${({ theme }) => theme.font.secondary};
  margin-bottom: 18px;
`;

export const Form = styled.form`
  position: relative;

  div:first-of-type.custom-password-input {
    margin-bottom: 18px;
  }
`;

export const Button = styled.button`
  width: 50%;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabled : theme.colors.secondary};
  margin-left: 25%;
  text-transform: uppercase;
  border-radius: 4px;
  border: solid 1px;
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  font-weight: 700;
  padding: 11px 0;
  color: ${({ theme }) => theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &.unset {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  &:hover {
    background-color: ${({ theme }) => theme.buttons.primary.hover};
    border-color: ${({ theme }) => theme.buttons.primary.hover};
    color: ${({ theme }) => theme.colors.white};
  }

  &:disabled:hover {
    background-color: ${({ theme }) => theme.colors.disabled};
    border-color: ${({ theme }) => theme.colors.disabled};
    cursor: default;
  }

  &.delete {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.unfollow};
    border-color: ${({ theme }) => theme.colors.unfollow};
  }

  &.delete:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.unfollow};
  }

  &.pseudo, &.pseudo:hover {
    background-color: ${({ theme }) => theme.colors.content};
    color: ${({ theme }) => theme.colors.disabled};
    border-color: ${({ theme }) => theme.colors.content};
  }

  ${({ menuCollapsed }) =>
    !menuCollapsed
      ? css`
          @media only screen and ${breakpoint.md} {
            width: 100%;
            margin-left: 0;
          }
        `
      : null}

  @media only screen and ${breakpoint.md} {
    width: 100%;
    margin-left: 0;
  } ;
`;

export const Box = styled.div`
  display: flex;

  & > div {
    padding: 0 6px;
    margin-bottom: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  & > div {
    width: 100%;
    padding: 15px;
    margin-bottom: auto;
  }
`;

export const Card = styled.div`
  display: flex;
  padding: 1ex 0;

  & > button {
    width: 20em;
    margin: 0 6px;
  }
`

export const CardTitle = styled.h3`
  width: 100%;
  margin-top: 7px;
`

export const CardNumber = styled.span`
  font-family: monospace;
  margin-left: 0.5em;
`

export const StyledMaskedInput = styled(MaskedInput)`
  background-color: ${({ theme }) => theme.inputs.secondary.default};
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-weight: 300;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.secondary};
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 3px;
  margin-top: ${({ noLabel }) => (noLabel ? 0 : 3)}px;
  padding-left: 8px;

  &.invalid {
    border-color: ${({ theme }) => theme.colors.error};
  }
`;

export const Label = styled.div`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  color: ${({ theme }) => theme.colors.inputLabel};
  margin-bottom: 6px;
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const ErrorMessage = styled.div`
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${({ theme }) => theme.defaults.smallFontSize}px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 6px;
  position: absolute;
`;