/* eslint-disable class-methods-use-this */
import { runInAction, makeAutoObservable, reaction } from 'mobx';
import userStore from '@root/stores/userStore';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';

export class SettingsStore {
  section = 'account';

  followingSection = 'companies';

  initialized = false;

  isLoading = { following: false, subscriptions: true, paymentCards: false };

  error = { following: null, paymentCards: null, subcriptions: null };

  companies = [];

  briefings = [];

  actualArray = [];

  notifications = {
    email: null,
    push: null,
  };

  notificationsCount = {
    companies: { off: 0, on: 0 },
    briefings: { off: 0, on: 0 },
  };

  singleNotification = {};

  rawSingleNotification = {};

  subscriptions = [];

  paymentCards = [];

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => ({ user: userStore.user }),
      ({ user }) => {
        if (user) {
          this.notifications = {
            email: userStore.user.allEmailNotificationsEnabled,
            push: userStore.user.allPushNotificationsEnabled,
          };
          this.subscriptions = userStore.user.subscriptions;
          this.isLoading.subscriptions = false;
        }
      },
    );

    reaction(
      () => this.companies,
      () => {
        this.actualArray = this.companies;
        this.actualKey = 'email_notification';
      },
    );
  }

  handleChangeSection = (value) => {
    runInAction(() => {
      this.section = value;
    });
  };

  handleChangeNotification = (name, value) => {
    runInAction(() => {
      if (name === 'allEmailNotificationsEnabled') {
        this.notifications = { ...this.notifications, email: !value };
      } else {
        this.notifications = { ...this.notifications, push: !value };
      }
    });
  };

  fetchPaymentCards = async () => {
    try {
      this.isLoading.paymentCards = true;
      const { data } = await API.get(apiRoutes.paymentCards);
      this.paymentCards = data;
    } catch (error) {
      this.error.paymentCards = error;
    } finally {
      this.isLoading.paymentCards = false;
    }
  };

  cancelSubscription = async () => {
    try {
      this.isLoading.subscriptions = true;
      await API.post(apiRoutes.cancelStripeSubscription);
    } catch (error) {
      this.error.subcriptions = error;
    } finally {
      this.isLoading.subscriptions = false;
    }
  };

  fetchFollowing = async () => {
    this.isLoading.following = true;

    try {
      const {
        data: { companies, briefingTypes },
      } = await API.get(apiRoutes.following);

      runInAction(() => {
        this.companies = companies;
        this.briefings = briefingTypes;
      });
    } catch (error) {
      this.error.following = error;
    } finally {
      runInAction(() => {
        this.isLoading.following = false;
        this.initialized = true;
      });
    }
  };

  followingSectionChange = (value) => {
    runInAction(() => {
      this.followingSection = value;

      if (value === 'companies') {
        this.actualArray = this.companies;
        this.actualKey = 'email_notification';
      } else {
        this.actualArray = this.briefings;
        this.actualKey = 'email_notification';
      }
    });
  };

  getNumbersOfFollows = () => {
    const checkSum = (arr, key, initial) =>
      arr.reduce(
        (previousValue, currentValue) =>
          Number(previousValue) + Number(currentValue[key]),
        initial,
      );

    const initialValue = 0;

    const objects = [
      this.companies,
      this.briefings,
      this.companies,
      this.briefings,
    ];
    const objectsTypes = [
      'email_notification',
      'push_notification',
      'push_notification',
      'email_notification',
    ];

    const reduced = objects.map((x, i) =>
      checkSum(x, objectsTypes[i], initialValue),
    );

    const offCompaniesSum =
      this.companies.length * 2 - (reduced[0] + reduced[2]);
    const offBriefingsSum =
      this.briefings.length * 2 - (reduced[1] + reduced[3]);

    this.notificationsCount = {
      companies: {
        off: offCompaniesSum,
        on: reduced[0] + reduced[2],
      },
      briefings: {
        off: offBriefingsSum,
        on: reduced[1] + reduced[3],
      },
    };
  };

  updateNumberOfFollows = (name, value, id) => {
    if (name.includes('briefing')) {
      this.notificationsCount = value
        ? {
            ...this.notificationsCount,
            briefings: {
              off: this.notificationsCount.briefings.off + 1,
              on: this.notificationsCount.briefings.on - 1,
            },
          }
        : {
            ...this.notificationsCount,
            briefings: {
              off: this.notificationsCount.briefings.off - 1,
              on: this.notificationsCount.briefings.on + 1,
            },
          };
    } else {
      this.notificationsCount = value
        ? {
            ...this.notificationsCount,
            companies: {
              off: this.notificationsCount.companies.off + 1,
              on: this.notificationsCount.companies.on - 1,
            },
          }
        : {
            ...this.notificationsCount,
            companies: {
              off: this.notificationsCount.companies.off - 1,
              on: this.notificationsCount.companies.on + 1,
            },
          };
    }

    if (name.includes('email_notification-company-')) {
      this.companies = this.companies.map((x) =>
        x.id === id ? { ...x, email_notification: !value } : x,
      );
    }
    if (name.includes('push_notification-company-')) {
      this.companies = this.companies.map((x) =>
        x.id === id ? { ...x, push_notification: !value } : x,
      );
    }
    if (name.includes('email_notification-briefing-')) {
      this.briefings = this.briefings.map((x) =>
        x.id === id ? { ...x, email_notification: !value } : x,
      );
    }
    if (name.includes('push_notification-briefing-')) {
      this.briefings = this.briefings.map((x) =>
        x.id === id ? { ...x, push_notification: !value } : x,
      );
    }
  };

  updateSingleNotification = (id, value, name) => {
    this.singleNotification = {
      id,
      value: !value,
      name: name.split('-')[0],
    };

    this.rawSingleNotification = {
      id,
      value: !value,
      name,
    };
  };

  sendStatus = async (id, data) => {
    await API.put(apiRoutes.updateSingleNotification(id), data);
  };

  updateArray = (value, name) => {
    runInAction(() => {
      if (name.includes('email_notification-company')) {
        this.companies = this.companies.map((x) => ({
          ...x,
          email_notification: !value,
        }));
      }
      if (name.includes('push_notification-company')) {
        this.companies = this.companies.map((x) => ({
          ...x,
          push_notification: !value,
        }));
      }
      if (name.includes('email_notification-briefing')) {
        this.briefings = this.briefings.map((x) => ({
          ...x,
          email_notification: !value,
        }));
      }
      if (name.includes('push_notification-briefing')) {
        this.briefings = this.briefings.map((x) => ({
          ...x,
          push_notification: !value,
        }));
      }
    });
  };

  clearArrays = () => {
    this.companies = [];
    this.briefings = [];
    this.actualArray = [];
    this.isLoading = {
      following: false,
      subscriptions: false,
      paymentCards: false,
    };
    this.error = { following: null, paymentCards: null, subcriptions: null };
  };
}

export default new SettingsStore();
