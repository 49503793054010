import React, { useEffect, useState, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Clock } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import {
  DashboardSettingsContainer,
  Button,
  BespokeButton,
  EyeIcon,
  InfoCircleIcon,
  ManageButtonContainer,
  OtherSettingsContainer,
  SelectedCompaniesLabel,
  SelectContainer,
  EyeOffIcon,
} from '@components/DashboardSettings/styles';
import DashboardDropdown from '@components/DashboardSelect';
import DateRangeSelector from '@components/DatesRangeSelector';
import { BESPOKE_TOOLTIP, PRESETS } from '@root/constants/dashboard';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { StyledTooltip } from '@containers/Dashboard/styles';

const DashboardSettings = ({
  dashboardStore: {
    selectedCompanies,
    copySelectedCompanies,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    showBespoke,
    setShowBespoke,
  },
  sidebarStore: { menuCollapsed },
}) => {
  const [preset, setPreset] = useState('Custom');
  const navigate = useNavigate();

  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();

  const twoRowsBreakpoint = matchBreakpoint(
    menuCollapsed ? BREAKPOINTS.LG : BREAKPOINTS.XL,
  );

  const changeDatesHandler = useCallback((dates) => {
    if (dates.startDate && dates.endDate && dates.startDate <= dates.endDate) {
      setStartDate(dates.startDate);
      setEndDate(dates.endDate);
      setPreset('Custom');
    }
  }, []);

  useEffect(() => {
    if (preset && preset !== 'Custom') {
      const presetObject = PRESETS.find(({ value }) => value === preset);
      if (presetObject && presetObject.from && presetObject.to) {
        setStartDate(presetObject.from);
        setEndDate(presetObject.to);
      }
    }
  }, [preset]);

  return (
    <DashboardSettingsContainer className={twoRowsBreakpoint ? 'row' : ''}>
      <ManageButtonContainer className={twoRowsBreakpoint ? 'row' : ''}>
        <Button
          noUppercase
          onClick={() => {
            navigate('/dashboard/dialog');
            copySelectedCompanies();
          }}
        >
          Manage Companies
        </Button>
        <SelectedCompaniesLabel className={twoRowsBreakpoint ? 'row' : ''}>
          <b>{selectedCompanies.size}</b>
          selected
        </SelectedCompaniesLabel>
        {twoRowsBreakpoint ? (
          <>
            <StyledTooltip />
            <BespokeButton
              showBespoke={showBespoke}
              showBespokeLabel={twoRowsBreakpoint}
              onClick={() => setShowBespoke(!showBespoke)}
            >
              Bespoke
              <InfoCircleIcon
                showBespoke={showBespoke}
                className="no-margins"
                showBespokeLabel={twoRowsBreakpoint}
                size={18}
                data-tip={BESPOKE_TOOLTIP}
                data-place="bottom"
              />
              {showBespoke ? <EyeIcon size={24} /> : <EyeOffIcon size={24} />}
            </BespokeButton>
          </>
        ) : null}
      </ManageButtonContainer>
      {!twoRowsBreakpoint ? (
        <OtherSettingsContainer>
          <StyledTooltip />
          <BespokeButton
            showBespoke={showBespoke}
            showBespokeLabel={twoRowsBreakpoint}
            onClick={() => setShowBespoke(!showBespoke)}
          >
            Bespoke coverage
            <InfoCircleIcon
              showBespoke={showBespoke}
              className="no-margins"
              showBespokeLabel={twoRowsBreakpoint}
              size={18}
              data-tip={BESPOKE_TOOLTIP}
              data-place="bottom"
            />
            {showBespoke ? <EyeIcon size={24} /> : <EyeOffIcon size={24} />}
          </BespokeButton>
        </OtherSettingsContainer>
      ) : null}
      <OtherSettingsContainer className={twoRowsBreakpoint ? 'row' : ''}>
        <DateRangeSelector
          onChange={changeDatesHandler}
          startDate={startDate}
          endDate={endDate}
          constraintDate={moment('01-01-2017')}
          // isDashboard
          showClearDates={false}
          allowOneDayRange
        />
        <SelectContainer>
          <DashboardDropdown
            minWidth="130px"
            maxWidth="140px"
            id="preset-select"
            name="Preset"
            onChange={setPreset}
            value={preset}
            options={PRESETS}
            placeholder="Preset"
            icon={<Clock size={18} />}
          />
        </SelectContainer>
      </OtherSettingsContainer>
    </DashboardSettingsContainer>
  );
};

DashboardSettings.propTypes = {
  dashboardStore: PropTypes.shape({
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default inject(
  'dashboardStore',
  'sidebarStore',
)(observer(DashboardSettings));
