import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, COLORS, TAGS, TYPES } from '@components/Text/style';

export { COLORS, TAGS, TYPES };

const Text = ({ type, children, color, tag }) => (
  <Wrapper as={tag} textColor={color} className="text" type={type}>
    {children}
  </Wrapper>
);

Text.propTypes = {
  type: PropTypes.oneOf(Object.values(TYPES)),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  tag: PropTypes.oneOf(Object.values(TAGS)),
  color: PropTypes.oneOf(Object.values(COLORS)),
};

Text.defaultProps = {
  tag: TAGS.SPAN,
  color: COLORS.TERTIARY,
  type: TYPES.BODY,
};

export default Text;
