import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { getCookieConsentValue, Cookies } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import apiRoutes from '@app/apiRoutes';
import API, { setAuthenticationToken } from '@app/api';
import { routerStore, userStore } from '@stores';
import { getItem, removeItem, KEYS, setItem } from '@utils/cache';
import routes, { MY_NEWS_TYPES } from '@routes';

export class AuthStore {
  isLoading = false;

  authErrors = {};

  isAuthenticated = false;

  darkMode = false;

  appSize = 'small';

  isGoogleAnalyticsInitialized = false;

  constructor() {
    makeAutoObservable(this);
    if (getItem(KEYS.AUTH_TOKEN)) {
      this.isAuthenticated = true;
    }

    if (getItem(KEYS.DARKMODE)) {
      if (getItem(KEYS.DARKMODE) === 'true') {
        this.darkMode = true;
      } else {
        this.darkMode = false;
      }
    }
    const appSize = getItem(KEYS.APP_SIZE);
    if (appSize) {
      this.appSize = appSize;
    }
  }

  login = async (data) => {
    try {
      this.authErrors = {};
      const {
        data: { token },
      } = await API.post(apiRoutes.signIn, {
        ...data,
        email: data.email.toLowerCase(),
      });
      setAuthenticationToken(token, data.email);
      runInAction(() => {
        this.isAuthenticated = true;
      });

      // redirect to previous page if there's one
      routerStore.replace(
        routerStore.location?.state?.from?.pathname ||
          routes.myNews(MY_NEWS_TYPES.FOLLOWING),
      );
    } catch ({ errors: { password: passwordError, email: emailError } }) {
      runInAction(() => {
        this.authErrors = {
          password: { message: passwordError },
          email: { message: emailError },
        };
      });
    }
  };

  loginWithMagicLink = async (token) => {
    try {
      const {
        data: { token: authToken },
      } = await API.post(apiRoutes.magicLink, {
        token,
      });

      runInAction(() => {
        this.isAuthenticated = true;
        setAuthenticationToken(authToken);
      });
      await userStore.fetchUser();
      setAuthenticationToken(authToken, userStore.user.email);
      routerStore.replace(routes.myNews(MY_NEWS_TYPES.FOLLOWING));
    } catch (error) {
      routerStore.replace(routes.auth.signIn);
      toast.error(
        error.errors?.token.length
          ? error.errors?.token[0]
          : 'Something went wrong',
      );
    }
  };

  handleDarkMode = (value) => {
    this.darkMode = value;
    setItem(KEYS.DARKMODE, value);
  };

  handleAppSize = (value) => {
    this.appSize = value;
    setItem(KEYS.APP_SIZE, value);
  };

  logout = async () => {
    const token = getItem(KEYS.AUTH_TOKEN);
    if (token) {
      await API.post(
        apiRoutes.logout,
        { token },
        { validateStatus: () => true },
      );
    }
    this.isAuthenticated = false;
    setItem(KEYS.DARKMODE, false);
    removeItem(KEYS.AUTH_TOKEN);
    removeItem(KEYS.EMAIL);
    removeItem(KEYS.DARKMODE);
    removeItem(KEYS.APP_SIZE);
  };

  changePassword = async (data) => {
    try {
      const {
        data: { token },
      } = await API.post(apiRoutes.changePasswordUsingToken, data);
      runInAction(() => {
        this.isAuthenticated = true;
        setAuthenticationToken(token);
      });
      await userStore.fetchUser();
      setAuthenticationToken(token, userStore.user.email);
      routerStore.replace(routes.myNews(MY_NEWS_TYPES.FOLLOWING));
    } catch (err) {
      const errorKey = Object.values(err)[1];
      const errorValue = Object.keys(errorKey)[0];
      toast.error(
        err?.errors ? err.errors[errorValue] : 'Something went wrong!',
      );
    }
  };

  setPasswordUsingInvitation = async (data) => {
    try {
      const {
        data: { token },
      } = await API.post(apiRoutes.setPasswordUsingInvitation, data);
      runInAction(() => {
        this.isAuthenticated = true;
        setAuthenticationToken(token);
      });
      await userStore.fetchUser();
      setAuthenticationToken(token, userStore.user.email);
      routerStore.replace(routes.myNews(MY_NEWS_TYPES.FOLLOWING));
      runInAction(() => {
        toast.success('Your password was set successfully!');
      });
    } catch (err) {
      const errorKey = Object.values(err)[1];
      const errorValue = Object.keys(errorKey)[0];
      toast.error(
        err?.errors ? err.errors[errorValue] : 'Something went wrong!',
      );
    }
  };

  initializeGoogleAnalytics = () => {
    if (getCookieConsentValue('cookieConsentDomain') === 'true') {
      if (process.env.REACT_APP_ENV !== 'development') {
        const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
        ReactGA.initialize(TRACKING_ID);
        this.isGoogleAnalyticsInitialized = true;
      }
    } else {
      Object.keys(Cookies.get()).forEach((cookieName) => {
        Cookies.remove(cookieName);
      });
      this.isGoogleAnalyticsInitialized = false;
    }
  };
}

export default new AuthStore();
