import React, { useCallback, useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { Label } from 'recharts';
import {
  List,
  ListItem,
  TextListItem,
  TooltipContainer,
} from '@components/ChartTooltip/style';

const ChartTooltip = ({
  payload,
  dateFormat,
  label,
  isPercent,
  separator,
  active: libActive,
}) => {
  const scrollRef = useRef();
  const [active, setActive] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const scroll = () => {
      if (scrollRef.current !== null) {
        clearTimeout(scrollRef.current);
        setIsScrolling(true);
      }
      scrollRef.current = setTimeout(() => {
        setIsScrolling(false);
      }, 150);
    };

    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolling) {
      setActive(false);
    } else {
      setActive(libActive);
    }
  }, [libActive, isScrolling]);

  let filteredPayload = null;

  const formatPercentValue = useCallback((value) => {
    if (value < 1) {
      return '< 1%';
    }
    return `${Math.round(value)}%`;
  }, []);

  if (payload) {
    filteredPayload = payload.filter((record) => record.value !== 0);
  }
  return (
    <TooltipContainer active={active}>
      <List>
        <Label>
          {dateFormat ? moment(label).format(dateFormat).toString() : label}
        </Label>
        {filteredPayload &&
          filteredPayload.map((record) => (
            <ListItem
              color={record.color ? record.color : record.fill}
              key={`custom-tooltip_${record.name}`}
            >
              {record.name}
              {separator}
              {isPercent ? formatPercentValue(record.value) : record.value}
            </ListItem>
          ))}
        {filteredPayload &&
          filteredPayload.length > 1 &&
          filteredPayload[0].payload.sum && (
            <TextListItem>
              Total
              {separator}
              {filteredPayload[0].payload.sum}
            </TextListItem>
          )}
        {filteredPayload && filteredPayload.length === 0 && (
          <TextListItem>No articles</TextListItem>
        )}
      </List>
    </TooltipContainer>
  );
};

export default ChartTooltip;
