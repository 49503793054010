/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { CirclePlus, Bell, Mail } from 'tabler-icons-react';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';
import NewsSection from '@components/NewsSection';
import Preloader from '@components/Preloader';
import Switch from '@components/Switch';
import MobileExtendedSearchBar from '@components/MobileExtendedSearchBar';
import { groupSections } from '@utils/sectionGrouping';
import Button, { BUTTON_THEMES } from '@components/Button';

import {
  CompanyBanner,
  TopSection,
  Info,
  Name,
  Notifications,
  Notification,
  Sections,
  Section,
  ContentWrapper,
  NewsContainer,
  Label,
  MobileNotifications,
  PreloaderWrapper,
  NoRecentPublications,
  LoadMoreSection,
  CompanyNameContainer,
  WorldUpload,
} from '@containers/Company/styles';
import NewContent from '@components/NewContentBanner';

const keys = [
  {
    name: 'follow',
    label: 'FOLLOW',
    icon: <CirclePlus strokeWidth={1.3} size={26} color="#FFFFFF" />,
  },
  {
    name: 'email_notification',
    label: 'EMAIL NOTIFICATIONS',
    icon: <Mail strokeWidth={1.3} size={26} color="#FFFFFF" />,
  },
  {
    name: 'push_notification',
    label: 'APP NOTIFICATIONS',
    icon: <Bell strokeWidth={1.3} size={26} color="#FFFFFF" />,
  },
];

const Company = ({
  companyStore: {
    fetchCompany,
    isLoading,
    company,
    sections,
    news,
    fetchSection,
    showNewContentBanner,
    setShowNewContentBanner,
    checkForNewContent,
    loadMore,
    loadingMore,
  },
}) => {
  const { companyId, type } = useParams();
  const [payload, setPayload] = useState(null);
  const [mobileNotifications, setMobileNotifications] = useState({
    follow: company.follow,
    push_notification: company.push_notification,
    email_notification: company.email_notification,
  });
  const [sectionNews, setSectionNews] = useState(type);
  const firstRender = useRef(true);
  const newsRender = useRef(true);

  const changeStatus = async (idCompany, data) => {
    await API.put(apiRoutes.singleCompany(idCompany), data);
  };

  const { handleSubmit, control, setValue } = useForm();

  const handleChangeType = useCallback((newsSection) => {
    setSectionNews(newsSection);
  }, []);

  const handleChangeMobileNotification = (value, name) => {
    setMobileNotifications({
      ...mobileNotifications,
      [name]: !value,
    });
  };

  useEffect(() => {
    (async () => {
      await fetchCompany(companyId, type);
    })();
    handleChangeType(type);
    fetchSection(companyId, type);
  }, []);

  useEffect(() => {
    if (newsRender.current) {
      newsRender.current = false;
    } else {
      (async () => {
        const findSection = sections.filter((x) => x.id === parseInt(type, 10));

        if (type !== 'latest') {
          await fetchSection(companyId, { ...findSection[0] }.id);
        }
      })();
    }
  }, [sections]);

  useEffect(() => {
    setValue('follow', company.follow);
    setValue('push_notification', company.push_notification);
    setValue('email_notification', company.email_notification);
    setMobileNotifications({
      follow: company.follow,
      push_notification: company.push_notification,
      email_notification: company.email_notification,
    });
  }, [company]);

  const checkId = (name, value) => {
    setPayload({ [name]: !value });
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      changeStatus(companyId, payload);
    }
  }, [payload]);

  useEffect(() => {
    setShowNewContentBanner(false);
    const interval = setInterval(
      () => checkForNewContent(companyId, type),
      60000 * 2,
    );
    return () => clearInterval(interval);
  }, [companyId, type]);

  const newContentBannerAction = async () => {
    setShowNewContentBanner(false);
    await fetchSection(companyId, type);
  };

  if (isLoading.banner) {
    return (
      <ContentWrapper>
        <Preloader overlay />
      </ContentWrapper>
    );
  }

  const newsSections =
    news !== undefined && news.sections !== undefined
      ? groupSections(news.sections)
      : [];

  return (
    <>
      {showNewContentBanner ? (
        <NewContent onClick={newContentBannerAction} />
      ) : null}
      <ContentWrapper>
        <MobileExtendedSearchBar
          placeholder={`Search ${company.name}`}
          repairedMargin
        />

        <CompanyBanner color={company?.meta?.color}>
          <TopSection sections={sections}>
            <Info>
              <img src={company.meta?.icon} alt={company.name} />
              <CompanyNameContainer
                href={company?.meta?.website_url || null}
                target="_blank"
              >
                <Name>{company.name}</Name>
                {company?.meta?.website_url && <WorldUpload size={18} />}
              </CompanyNameContainer>
            </Info>
            <Notifications>
              {keys.map((key) => (
                <Notification key={key.name}>
                  {key.icon}
                  <Controller
                    name={key.name}
                    defaultValue={false}
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <Switch
                        onChange={onChange}
                        label={key.label}
                        checkId={() => checkId(name, value)}
                        handleSubmit={handleSubmit}
                        name={name}
                        value={value}
                        offColor="#fff"
                        onColor="#fff"
                        onHandleColor={company.meta?.color}
                      />
                    )}
                  />
                </Notification>
              ))}
            </Notifications>
            <MobileNotifications>
              {keys.map((key) => (
                <Notification
                  key={key.name}
                  active={mobileNotifications[key.name]}
                  onClick={() => {
                    handleChangeMobileNotification(
                      mobileNotifications[key.name],
                      key.name,
                    );
                    checkId(key.name, mobileNotifications[key.name]);
                  }}
                >
                  {key.icon}
                  <Label>{key.label}</Label>
                </Notification>
              ))}
            </MobileNotifications>
          </TopSection>

          {sections?.length ? (
            <Sections>
              {sections.map((section) => (
                <Link
                  to={`/company/${companyId}/${section.ids}`}
                  state={{ from: 'the-page-id' }}
                  key={section.ids}
                >
                  <Section
                    active={String(sectionNews) === String(section.ids)}
                    onClick={() => {
                      handleChangeType(section.ids);
                      fetchSection(company.id, section.ids);
                    }}
                  >
                    {section.name}
                  </Section>
                </Link>
              ))}
            </Sections>
          ) : null}
        </CompanyBanner>
        <NewsContainer>
          {!isLoading.news ? (
            newsSections.length > 0 ? (
              newsSections.map((item, index) => {
                const previousSection = index > 0 ? newsSections[index - 1] : {};
                item.sectionTitle = company.name;
                return (
                  <NewsSection
                    section={item}
                    previousPublicationDate={previousSection?.publicationDate}
                    key={`news-section_${index}`}
                  />
                );
              })
            ) : (
              <NoRecentPublications>No recent coverage</NoRecentPublications>
            )
          ) : (
            <PreloaderWrapper>
              <Preloader overlay className="loader" />
            </PreloaderWrapper>
          )}
        </NewsContainer>
        {news.links !== undefined && news.links.next ? (
          <LoadMoreSection>
            <Button
              type="button"
              theme={BUTTON_THEMES.SECONDARY}
              onClick={loadMore}
              loading={loadingMore}
            >
              Load more
            </Button>
          </LoadMoreSection>
        ) : null}
      </ContentWrapper>
    </>
  );
};

export default inject(
  'companyStore',
  'sidebarStore',
  'dataRangePickerStore',
)(observer(Company));
