import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { css } from '@emotion/react';
import { breakpoint, size } from '@styles/breakpoints';
import briefcaseBFull from '@assets/images/briefcase-b-full.svg';

export const Container = styled.aside`
  background-color: ${({ theme }) => theme.colors.sidebar};
  width: ${({ menuCollapsed, theme }) =>
    menuCollapsed
      ? `${theme.defaults.desktopMenuCollapsedWidth}px`
      : `${theme.defaults.desktopMenuWidth}px`};
  transition: width 0.3s ease-in-out, background-color 0.3s;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-shrink: 0;
  overflow-x: hidden;

  .sidebar-item {
    display: flex;

    div {
      overflow: initial !important;
    }

    & ~ div:nth-last-of-type(2) {
      .preloader {
        display: none;
      }
    }
  }

  :hover {
    overflow-y: auto;
  }

  @media only screen and ${breakpoint.md} {
    width: ${({ menuCollapsed, theme }) =>
      menuCollapsed
        ? `${theme.defaults.desktopMenuCollapsedWidth}px`
        : `${theme.defaults.smallDesktopMenuWidth}px`};
  }

  ${({ menuCollapsed }) =>
    menuCollapsed
      ? css`
          ${MenuItemIcon} {
            padding: 20px 32px;
          }

          ${MenuItemLabel} {
            opacity: 0;
          }
        `
      : null}
`;

export const SidebarContainer = styled(Container)`
  background: url(${briefcaseBFull}) no-repeat 75% center / 170%,
    ${({ theme }) => theme.colors.sidebar};
`;

export const ScrollableContent = styled(Scrollbars)`
  flex: 1 1 auto;
  overflow-y: hidden;

  div {
    overflow-x: hidden !important;
  }
`;

export const MobileContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.sidebar};
  transition: left 300ms, transform 300ms, background-color 300ms;
  width: 100%;
  overflow: hidden;
  top: ${({ theme }) => `${theme.defaults.mobileMenuHeight}px`};
  height: ${({ theme }) =>
    `calc(100vh - ${theme.defaults.mobileMenuHeight}px)`};
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.primary};
  }
  border-top: 1px #e5e5e5 solid;
`;

export const LogoContainer = styled.div`
  transition: background-color 0.3s;
  width: 100%;
  height: 105px;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @media screen and (${breakpoint.md}) {
    height: 72px;
  }
`;

export const LogoIcon = styled.div`
  color: ${({ theme }) => theme.colors.white};
  padding: 10px 18px 16px;

  img {
    width: 60px;
    height: 50px;
  }

  @media only screen and ${breakpoint.md} {
    padding: 23px 23px 16px 20px;
    img {
      width: 50px;
      height: 32px;
    }
  }
`;

export const LogoText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  align-items: flex-start;
  width: 100%;
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  position: relative;
  top: 2px;

  @media only screen and ${breakpoint.md} {
    font-size: ${({ theme }) => theme.defaults.fontSize}px;
  }
`;

export const StyledMenuItem = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.3s;
  width: 100%;
  height: ${({ theme }) => theme.defaults.menuItemHeight}px;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
`;

export const MenuItemIcon = styled.div`
  color: ${({ theme }) => theme.colors.white};
  align-items: center;
  min-width: 70px;
  padding: 20px;
  transition: padding 0.3s ease-in-out;
`;

export const MenuItemLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  line-height: 72px;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  font-weight: ${({ theme }) => theme.defaults.fontWeight.light};
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;

  .sidebar-item--active & {
    font-weight: ${({ theme }) => theme.defaults.fontWeight.bold};
  }

  .preloader {
    margin-left: auto;
    width: 30px;
  }
`;

export const SubMenu = styled.div`
  display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
`;

export const SubMenuItem = styled(NavLink)`
  display: block;
  align-items: center;
  background-color: rgb(255 255 255 / 2%);
  line-height: 24px;
  min-height: 48px;
  padding: 12px 15px 12px 74px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  font-weight: ${({ theme }) => theme.defaults.fontWeight.regular};

  &.active,
  &[aria-current='page'] {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
  }

  @media only screen and (min-width: ${size.sm}px) {
    &:not(.active):hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;

export const CollapseIcon = styled.div`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.white};
  padding: 28px 16px 28px 0;
  svg {
    transition: all 0.3s ease-in-out;
  }

  ${({ isExpanded }) =>
    isExpanded
      ? css`
          svg {
            transform: rotate(180deg);
          }
        `
      : null};
`;

export const TopBar = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.sidebar};
  transition: background-color 0.3s;
  width: 100%;
  height: ${({ theme }) => theme.defaults.mobileMenuHeight}px;
  z-index: 9999;
`;

export const TopBarIcon = styled.div`
  color: ${({ theme }) => theme.colors.white};
  align-items: center;
  padding: 16px;
`;

export const TopBarLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.defaults.fontSize};
  font-weight: 700;
  text-align: left;
  margin: 16px 0px 16px 8px;
  line-height: 24px;
`;

export const UserIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  height: 24px;
  width: 24px;
`;

export const TopBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const MobileLogoContainer = styled.div`
  transition: background-color 0.3s;
  width: 100%;
  height: 72px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const CloseMobileMenuContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 0 0;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  z-index: 2;
`;

export const MobileScrollableContent = styled.div`
  overflow-y: scroll;

  .sidebar-item:last-of-type + div {
    .preloader {
      display: none;
    }
  }

  @supports (-webkit-touch-callout: none) {
    padding-bottom: 100px;
  }
`;
