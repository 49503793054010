import styled from '@emotion/styled';

export const Container = styled.div`
  text-align: left;
  margin: 0;
  padding: 10px;
  background-color: white;
  white-space: nowrap;
  border-radius: 10px;
`;

export const List = styled.ul`
  margin: 0;
`;

export const Label = styled.li`
  list-style-type: none;
  margin-bottom: 2px;
`;

export const ListItem = styled.li`
  color: ${({ color }) => color};
  text-decoration: none;
  list-style-type: none;
  margin-bottom: 2px;
`;
