import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import getSymbolFromCurrency from 'currency-symbol-map';
import Preloader from '@components/Preloader';
import {
  Section,
  Header,
  Text,
  StyledLink,
  Divider,
  ButtonTitleWrapper,
} from '@containers/Settings/SubscriptionInfo/styles';
import routes from '@routes';
import SubscriptionWrapper from '@components/SubscriptionWrapper';
import BottomSection from '@containers/Settings/SubscriptionInfo/components/BottomSection';

function SubscriptionInfo({
  settingsStore: { subscriptions, isLoading, fetchPaymentCards, paymentCards },
}) {
  const { id } = useParams();
  const navigate = useNavigate();

  const subscription = useMemo(() => {
    if (id > 0) {
      return subscriptions[id - 1];
    }
    return undefined;
  }, [id, subscriptions]);

  const defaultCard = useMemo(() => {
    if (paymentCards.length && !isLoading.paymentCards) {
      return paymentCards.find((card) => card.default) || undefined;
    }
    return undefined;
  }, [paymentCards, isLoading.paymentCards]);

  useEffect(() => {
    fetchPaymentCards();
  }, []);

  if (!subscription && !isLoading.subscriptions) {
    navigate(routes.settings);
  }

  return (
    <SubscriptionWrapper isLoading={isLoading.subscriptions}>
      <>
        <Section>
          <Header>{subscription?.title}</Header>
          <Text>
            We hope you are enjoying your Briefcase News subscription.
          </Text>
        </Section>
        <Section>
          <Text>You have access to</Text>
          <Text isValue>{subscription?.access_to}</Text>
        </Section>
        <Section>
          {subscription?.cancelAtPeriodEnd ? (
            <>
              <Text>Your subscription expires on</Text>
              <Text isValue>
                {moment(subscription.date).format('DD MMMM YYYY')}
              </Text>
            </>
          ) : (
            <>
              <Text>Next payment</Text>
              <Text isValue>
                {getSymbolFromCurrency(subscription?.currency)}
                {subscription?.amount / 100}
                {' on '}
                {moment(subscription?.date).format('DD MMMM YYYY')}
              </Text>
            </>
          )}
        </Section>
        <Section>
          <ButtonTitleWrapper>
            <Text>Your payment details</Text>
            <StyledLink to={routes.paymentMethod}>Edit</StyledLink>
          </ButtonTitleWrapper>
          {isLoading.paymentCards ? (
            <Preloader size="normal" />
          ) : (
            defaultCard && (
              <Text isValue>Credit card ending *{defaultCard?.last4}</Text>
            )
          )}
        </Section>
        <Text>
          Please note that all changes on your payment details are managed by
          Stripe.
        </Text>
        <Divider />
        <BottomSection
          id={id}
          cancelAtPeriodEnd={subscription?.cancelAtPeriodEnd}
        />
      </>
    </SubscriptionWrapper>
  );
}

export default inject('settingsStore')(observer(SubscriptionInfo));
