import React from 'react';
import { Refresh } from 'tabler-icons-react';
import { inject, observer } from 'mobx-react';
import {
  NewContentBanner,
  NewContentContainer,
} from '@components/NewContentBanner/style';

const NewContent = ({ sidebarStore: { menuCollapsed }, onClick }) => (
  <NewContentContainer menuCollapsed={menuCollapsed}>
    <NewContentBanner onClick={onClick}>
      New content available!
      <Refresh />
    </NewContentBanner>
  </NewContentContainer>
);

export default inject('sidebarStore')(observer(NewContent));
