import React, { useMemo } from 'react';
import {
  Container,
  Label,
  StyledControl,
  ErrorMessage,
} from '@components/Control/styles';

function Control({
  type,
  label,
  name,
  error,
  disabled,
  onChange,
  value,
  checked,
  handleSubmit,
  onSubmit,
  checkId,
}) {
  const inputId = useMemo(() => `control_${name}_${value}`, [name, value]);

  return (
    <Container>
      <StyledControl
        name={name}
        id={inputId}
        type={type}
        value={value}
        onChange={
          handleSubmit
            ? (e) => {
                onChange(e);
                handleSubmit(onSubmit);
                checkId();
              }
            : onChange
        }
        className={error && 'invalid'}
        checked={checked}
        disabled={disabled}
      />

      {label !== '' && (
        <Label htmlFor={inputId} withError={!!error}>
          {label}
        </Label>
      )}

      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Container>
  );
}

export default Control;
