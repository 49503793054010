import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'tabler-icons-react';

import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  CheckIcon,
  ClearAllCompaniesButton,
  ClearIcon,
  CollapseIcon,
  CompaniesGroupContainer,
  CompaniesGroupHeader,
  CompaniesList,
  GroupLabel,
} from '@components/CompaniesDialog/styles';
import CompanyButton from '@components/CompaniesDialog/components/CompanyButton';
import useBreakpoint from '@root/hooks/useBreakpoint';

const CompaniesSortGroup = ({
  label,
  companies,
  dashboardStore: {
    clearSector,
    selectSector,
    isSectorSelected,
    searchQuery,
    expandedLabel,
    setExpandedLabel,
  },
}) => {
  const [isExpanded, setIsExpanded] = useState(expandedLabel);
  const sectorSelected = isSectorSelected(companies);
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);

  useEffect(() => {
    if (expandedLabel === label) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [expandedLabel]);

  useEffect(() => {
    if (searchQuery && searchQuery !== '') {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [searchQuery]);

  return (
    <CompaniesGroupContainer className={isExpanded ? '' : 'hover'}>
      <CompaniesGroupHeader
        onClick={() => {
          if (!isExpanded) {
            setExpandedLabel(label);
          }
          setIsExpanded(!isExpanded);
        }}
      >
        <GroupLabel>{label}</GroupLabel>
        <ClearAllCompaniesButton
          onClick={(e) => {
            e.stopPropagation();
            selectSector(companies);
          }}
          className={isExpanded ? '' : 'collapse'}
          selected={sectorSelected}
        >
          {isMobile ? null : 'Select all'}
          <CheckIcon />
        </ClearAllCompaniesButton>
        <ClearAllCompaniesButton
          onClick={(e) => {
            e.stopPropagation();
            clearSector(companies);
          }}
          className={isExpanded ? '' : 'collapse'}
        >
          {isMobile ? null : 'Clear all'}
          <ClearIcon />
        </ClearAllCompaniesButton>
        <CollapseIcon isExpanded={isExpanded}>
          <ChevronDown size={24} strokeWidth={1} />
        </CollapseIcon>
      </CompaniesGroupHeader>
      <CompaniesList
        className={isExpanded ? 'no-left-margin' : 'no-left-margin collapse'}
      >
        {companies.map(({ id, name, color }) => (
          <CompanyButton
            key={`company-button-${id}`}
            id={id}
            label={name}
            companyColor={color}
          />
        ))}
      </CompaniesList>
    </CompaniesGroupContainer>
  );
};

CompaniesSortGroup.propTypes = {
  label: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired,
  dashboardStore: PropTypes.shape({
    clearSector: PropTypes.func.isRequired,
    selectSector: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('dashboardStore')(observer(CompaniesSortGroup));
