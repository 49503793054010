import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import Preloader from '@components/Preloader';
import ContentBox from '@components/ContentBox';
import Text, { COLORS, TAGS } from '@components/Text';
import Article from '@components/NewsSection/Article';

import { Header, Section } from '@components/NewsSection/style';
import { groupSectionsDataByTags } from '@utils/briefingsUtils';

const NewsSection = ({
  section,
  enableToScroll,
  previousPublicationDate,
  sidebarStore: { briefingTypes },
}) => {
  const publicationDate = useMemo(() => {
    const date = moment(section.publicationDate);
    if (
      !date
        .startOf('day')
        .isSame(moment(previousPublicationDate).startOf('day'))
    ) {
      return date.format('dddd, Do MMMM');
    }
    return false;
  }, [section.publicationDate, previousPublicationDate]);

  const groupedSection = useMemo(() => {
    if (section) {
      return groupSectionsDataByTags(section, briefingTypes);
    }
    return { data: [] };
  }, [section, briefingTypes]);

  let companiesAlt = null;
  if (section.companyHashtags !== undefined && section.companyHashtags.length) {
    companiesAlt = section.companyHashtags.map(({ name }) => name).join(', ');
  }

  if (!groupedSection.data.length) {
    return <Preloader overlay />;
  }

  return (
    <Section enableToScroll={enableToScroll}>
      {publicationDate ? (
        <Header>
          <Text color={COLORS.TERTIARY} tag={TAGS.H1}>
            {publicationDate}
          </Text>
        </Header>
      ) : null}

      <ContentBox
        title={section.sectionTitle}
        badge={section.sectionColor}
        alt={companiesAlt}
      >
        {groupedSection.data.map((news) => (
          <Article
            article={news}
            key={`article_${news.article_id}_${news.id}`}
          />
        ))}
      </ContentBox>
    </Section>
  );
};

NewsSection.propTypes = {
  previousPublicationDate: PropTypes.string,
  sidebarStore: PropTypes.shape({
    briefingTypes: PropTypes.array,
  }).isRequired,
  section: PropTypes.object.isRequired,
};

NewsSection.defaultProps = {
  previousPublicationDate: null,
};

export default inject('sidebarStore')(observer(NewsSection));
