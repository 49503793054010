import styled from '@emotion/styled';

export const PieContainer = styled.div`
  align-items: center;
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  text-align: center;
`;

export const CompanyBadge = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ companyColor }) => companyColor};
  border: 1px solid ${({ companyColor }) => companyColor};
  border-radius: 9px; //20px
  color: ${({ theme }) => theme.colors.white};
  height: 34px; //32px
  line-height: 34px; //32px
  padding: 0 12px;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  user-select: none;
  max-width: max-content;
  margin-bottom: 10px;
`;
