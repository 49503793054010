import React, { useEffect } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';

import { useTheme } from '@emotion/react';
import { inject, observer } from 'mobx-react';

const CookieBanner = ({ authStore: { initializeGoogleAnalytics } }) => {
  useEffect(() => {
    initializeGoogleAnalytics();
  }, []);

  const theme = useTheme();

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="cookieConsentDomain"
      extraCookieOptions={{ domain: 'briefcase.news' }}
      style={{ background: theme.colors.sidebar }}
      buttonStyle={{
        background: theme.buttons.primary.background,
        color: theme.buttons.primary.textColor,
        fontSize: theme.defaults.fontSize,
        borderRadius: 3,
      }}
      onAccept={() => {
        Cookies.set('cookieConsentDomain', true, {
          expires: 150,
          domain: '.briefcase.news',
        });
        initializeGoogleAnalytics();
      }}
      expires={150}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
};
export default inject('authStore')(observer(CookieBanner));
