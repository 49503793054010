import styled from '@emotion/styled';
import { Eye, EyeOff } from 'tabler-icons-react';

export const DashboardSettingsMobileContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
`;

export const ManageButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

export const OtherSettingsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 0;
  justify-content: flex-end;
  gap: 16px;

  &.center {
    justify-content: center;
  }
`;

export const Button = styled.button`
  width: 100%;
  max-width: 160px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  height: 40px;
  cursor: pointer;
  &:hover {
    background-color: rgba(103, 147, 255, 0.05);
  }

  &.max-content {
    width: max-content;
  }
`;

export const BespokeButton = styled.button`
  display: flex;
  justify-content: space-between;
  width: 150px;
  gap: 10px;
  border-radius: 4px;
  color: ${({ showBespoke, theme }) =>
    showBespoke ? theme.colors.secondary : theme.colors.inputLabel};
  border: 1px solid
    ${({ showBespoke, theme }) =>
      showBespoke ? theme.colors.secondary : theme.colors.inputLabel};
  height: 40px;
  cursor: pointer;
  line-height: 40px;
  padding: 0 10px;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  margin-right: auto;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &.max-content {
    width: max-content;
  }
`;

export const EyeIcon = styled(Eye)`
  margin: auto 0;
`;

export const EyeOffIcon = styled(EyeOff)`
  margin: auto 0;
`;
