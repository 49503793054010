import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { MoodSad } from 'tabler-icons-react';
import Preloader from '@components/Preloader';
import NewsSection from '@components/NewsSection';

import { ButtonWrapper, Wrapper, NoResults } from '@containers/MyNews/style';
import Button, { BUTTON_THEMES } from '@components/Button';
import MobileExtendedSearchBar from '@components/MobileExtendedSearchBar';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';
import { groupSections } from '@utils/sectionGrouping';
import NewContent from '@components/NewContentBanner';

const MyNews = ({
  newsStore: {
    initialized,
    isLoading,
    sections,
    links,
    fetchNews,
    loadMore,
    loadingMore,
    checkForNewContent,
    showNewContentBanner,
    setShowNewContentBanner,
  },
}) => {
  const { type } = useParams();
  const { search } = useCustomSearchParams();
  const theme = useTheme();

  useEffect(() => {
    setShowNewContentBanner(false);
    const interval = setInterval(() => checkForNewContent(type), 60000 * 2);
    return () => clearInterval(interval);
  }, [type]);

  useEffect(() => {
    (async () => {
      await fetchNews(type, search);
    })();
  }, [type, search]);

  const processedSections = groupSections(sections);

  useEffect(() => {
    if (isLoading) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [type, sections, isLoading]);

  const newContentBannerAction = async () => {
    setShowNewContentBanner(false);
    await fetchNews(type);
  };

  return (
    <>
      {showNewContentBanner ? (
        <NewContent onClick={newContentBannerAction} />
      ) : null}
      <Wrapper>
        {isLoading || !initialized ? <Preloader overlay /> : null}

        {!isLoading && initialized && !processedSections.length ? (
          <NoResults>
            <MoodSad size={64} strokeWidth={1} color={theme.font.default} />
            Sorry, we couldn&apos;t find any results
          </NoResults>
        ) : null}

        <MobileExtendedSearchBar />

        {processedSections.map((item, index) => {
          const previousSection = index > 0 ? processedSections[index - 1] : {};
          return (
            <NewsSection
              section={item}
              previousPublicationDate={previousSection.publicationDate}
              key={`news-section_${index}`}
              enableToScroll
            />
          );
        })}

        {links.next && sections.length ? (
          <ButtonWrapper>
            <Button
              type="button"
              theme={BUTTON_THEMES.SECONDARY}
              onClick={loadMore}
              loading={loadingMore}
            >
              Load more
            </Button>
          </ButtonWrapper>
        ) : null}
      </Wrapper>
    </>
  );
};

MyNews.propTypes = {
  newsStore: PropTypes.shape({
    isLoading: PropTypes.bool,
    news: PropTypes.array,
    fetchNews: PropTypes.func,
  }).isRequired,
};

export default inject('newsStore')(observer(MyNews));
