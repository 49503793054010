import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';

export const ContentWrapper = styled.div`
  padding: ${({ wide }) => (wide ? '56px 40px 56px 144px' : '56px 144px')};
  min-height: 100%;

  @media only screen and (max-width: 1600px) {
    padding: 32px;
  }

  @media only screen and ${breakpoint.lg} {
    padding: 16px;
  }

  @media only screen and ${breakpoint.md} {
    padding-top: 80px;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  width: 100%;

  @media only screen and (max-width: 1600px) {
    width: 100%;
    padding-bottom: 16px;
  }

  @media only screen and ${breakpoint.lg} {
    justfiy-content: center;
  }

  @media only screen and ${breakpoint.md} {
    gap: 16px;
  }
`;

export const Box = styled.div`
  border-radius: 3px;
  background-color: ${({ theme }) => theme.box.default.background};
  width: 304px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    color: ${({ theme }) => theme.font.default} !important;
  }

  span {
    color: ${({ theme }) => theme.font.default} !important;
  }

  @media only screen and ${breakpoint.lg} {
    width: calc(50% - 35px);
  }

  @media only screen and ${breakpoint.md} {
    width: 45%;

    ${({ collapsed }) =>
      !collapsed
        ? css`
            & {
              width: 100%;
            }
          `
        : null}
  }
`;

export const Title = styled.h3`
  display: block;
  font-weight: ${({ theme }) => theme.defaults.fontWeight.bold};
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  padding: 16px;
  line-height: 24px;
`;

export const Button = styled.button`
  margin-top: auto;
  border-top: 1px solid #f0f4ff;
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  text-align: center;
  display: block;
  padding: 11px 0;
  width: 100%;
  font-weight: ${({ theme }) => theme.defaults.fontWeight.bold};
  color: ${({ followed, theme }) =>
    followed ? theme.colors.unfollow : theme.colors.follow};
  transition: background-color 0.3s;

  &:hover {
    background-color: #eee;
  }
`;
