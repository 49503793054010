import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import routes from '@routes';
import { Box, Content, Button, Title } from '@components/BriefingTypeBox/style';
import Text from '@components/Text';
import Preloader, { POSITIONS } from '@components/Preloader';

const BriefingTypeBox = ({
  briefingType,
  handleFollow,
  isLoading,
  sidebarStore: { menuCollapsed },
}) => {
  if (!briefingType) {
    return null;
  }

  return (
    <Box menuCollapsed={menuCollapsed}>
      <Link to={!isLoading && routes.briefing(briefingType.id)}>
        <Content>
          <Title>{briefingType.name}</Title>
          <Text>{briefingType.description}</Text>
        </Content>
      </Link>
      {isLoading ? (
        <Preloader display={POSITIONS.HORIZONRAL} />
      ) : (
        <Button
          type="button"
          onClick={() =>
            !isLoading && handleFollow(briefingType.id, briefingType.follow)
          }
          followed={briefingType.follow}
        >
          {briefingType.follow ? 'UNFOLLOW' : 'FOLLOW'}
        </Button>
      )}
    </Box>
  );
};

BriefingTypeBox.propTypes = {
  briefingType: PropTypes.shape({
    email_notification: PropTypes.bool,
    follow: PropTypes.bool,
    id: PropTypes.number,
    meta: PropTypes.shape({
      tags: PropTypes.array,
      color: PropTypes.string,
      description: PropTypes.string,
      briefing_type_id: PropTypes.number,
    }),
    name: PropTypes.string,
    push_notification: PropTypes.bool,
    sector_follow: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
};

export default inject('sidebarStore')(observer(BriefingTypeBox));
