import React, { useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import { Button } from '@containers/Dashboard/styles';
import { FullReportContainer } from '@containers/Dashboard/components/FullReport/style';
import Preloader, { POSITIONS, SIZES } from '@components/Preloader';

const PreloaderContainer = styled.div`
  margin-left: 10px;
`;

const FullReport = ({
  dashboardStore: {
    requestFullReport,
    isLoadingFullReport,
    dashboardUserSettings,
  },
}) => {
  const handleClick = useCallback(async () => {
    const success = await requestFullReport();
    if (success) {
      toast.success(
        'The report you requested will be provided to you as an Excel sheet via email.',
      );
    } else {
      toast.error('Something went wrong');
    }
  }, [requestFullReport]);

  if (!dashboardUserSettings.showReport) {
    return null;
  }

  return (
    <FullReportContainer>
      <Button className="full-report" onClick={handleClick}>
        REQUEST FULL REPORT
        {isLoadingFullReport ? (
          <PreloaderContainer>
            <Preloader display={POSITIONS.HORIZONRAL} size={SIZES.SMALL} />
          </PreloaderContainer>
        ) : null}
      </Button>
    </FullReportContainer>
  );
};

FullReport.propTypes = {
  dashboardStore: PropTypes.shape({
    requestFullReport: PropTypes.func.isRequired,
    isLoadingFullReport: PropTypes.bool.isRequired,
  }).isRequired,
};

export default inject('dashboardStore')(observer(FullReport));
