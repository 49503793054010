import React from 'react';
import { inject, observer } from 'mobx-react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';

import {
  Container,
  Wrapper,
  TopSection,
  Form,
  Header,
  Text,
  Button,
} from '@containers/ContactUs/styles';

import Input, { INPUT_TYPES } from '@components/Input';
import Select from '@components/Select';

const SUBJECTS = [
  { id: 1, label: 'Technical Support', value: 'Technical support' },
  { id: 2, label: 'Other Question', value: 'Other questions' },
];

const ContactUs = ({ userStore: { user } }) => {
  const validationSchema = yup.object().shape({
    email: yup.string().required('Email is required').email('Email is invalid'),
    fullName: yup.string().default(user.fullName),
    message: yup
      .string()
      .required('Message is required')
      .min(6, 'Message is too short'),
    subject: yup.string().required('Select a subject'),
  });

  const resolver = yupResolver(validationSchema);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ resolver });

  const sendMessage = async (data) => {
    await API.post(apiRoutes.contactUs, data)
      .then(() => {
        toast.success('Message has been sent!');
      })
      .catch(() => {
        toast.error('Something went wrong!');
      });
  };

  const onSubmit = (data) => {
    sendMessage(data);
    reset({ ...data, message: '', email: '' });
  };

  return (
    <Container>
      <Wrapper>
        <TopSection>
          <Header>Lets talk</Header>
          <Text>
            Have a question or need some help? Please get in touch below
          </Text>
        </TopSection>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            defaultValue=""
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                inputType={INPUT_TYPES.TEXT}
                onChange={onChange}
                value={value}
                label="Email address"
                error={errors[name]}
              />
            )}
          />
          <Controller
            name="message"
            control={control}
            defaultValue=""
            render={({ field: { name, onChange, value } }) => (
              <Input
                name={name}
                inputType={INPUT_TYPES.TEXT}
                onChange={onChange}
                value={value}
                placeholder="Message"
                textArea
                error={errors[name]}
              />
            )}
          />
          <Controller
            control={control}
            name="subject"
            defaultValue=""
            render={({ field: { name, onChange, value } }) => (
              <Select
                id="subject-select"
                name={name}
                onChange={onChange}
                value={value}
                options={SUBJECTS}
                placeholder="Select subject"
                disableSearch
                error={errors[name]}
              />
            )}
          />
          <Button type="submit">send</Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default inject('userStore')(observer(ContactUs));
