import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 64px;
    display: block;
    font-weight: ${({ theme }) => theme.defaults.fontWeight.light};
    margin-bottom: 32px;
  }

  h2 {
    font-weight: ${({ theme }) => theme.defaults.fontWeight.light};
    font-size: ${({ theme }) => theme.defaults.fontSizeXXL}px;
  }
`;
