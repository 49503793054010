/* eslint-disable */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const ContentOverlay = ({ children }) => {
  const [target, setTarget] = useState(null);

  useEffect(() => {
    setTarget(document.getElementById('content-portal'));
  }, []);

  if (!target) {
    return null;
  }

  return createPortal(children, target);
};

export default ContentOverlay;
