import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.content};
  transition: background-color 0.3s;
  box-shadow: 0px -2px 8px 4px rgba(41, 35, 92, 0.03);
  border-radius: 3px;
  position: relative;
  margin-bottom: 24px;
  ${({ badge }) =>
    badge
      ? css`
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 6px;
            height: 100%;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            background: ${badge};
            z-index: 3;
          }
        `
      : null}
`;

export const Header = styled.div`
  border-bottom: 1px solid #e1e9ff;
  padding: 12px 32px;
`;

export const Content = styled.div`
  padding: 32px;
  position: relative;
`;
