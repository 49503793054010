import { runInAction, makeAutoObservable } from 'mobx';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';

export class ArticleStore {
  isLoading = false;

  error = null;

  article = {};

  constructor() {
    makeAutoObservable(this);
  }

  fetchArticle = async (id) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const { data } = await API.get(apiRoutes.article(id));

      runInAction(() => {
        this.article = data.article;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}

export default new ArticleStore();
