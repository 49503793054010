import styled from '@emotion/styled';
import { Heart, InfoCircle } from 'tabler-icons-react';
import ReactTooltip from 'react-tooltip';
import { breakpoint } from '@styles/breakpoints';
import { ReactComponent as Excel } from '@app/assets/excel-icon.svg';

export const ChartContainer = styled.div`
  width: 100%;
  margin-bottom: 22px;
  box-shadow: rgb(0 0 0 / 16%) 0 3px 6px 0;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 3px;
  &.empty {
    display: flex;
    justify-content: center;
    box-shadow: unset;
    height: 400px;
    line-height: 400px;
    text-align: center;
    font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
    color: ${({ theme }) => theme.colors.primary};
  }

  &.half {
    width: calc(50% - 8px);
    @media screen and ${breakpoint.lg} {
      width: 100%;
    }
  }

  @media screen and ${breakpoint.md} {
    box-shadow: unset;
    background-color: unset;
    padding: 0 10px 10px 0;
  }
`;

export const ChartTitle = styled.div`
  padding-left: 20px;
  margin: 20px 0 20px;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  font-weight: 700;
`;

export const TopSourcesTopJournalistsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;

  @media screen and ${breakpoint.lg} {
    flex-direction: column;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 24px;

  &.small-margin {
    margin-top: 12px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  height: 40px;
  cursor: pointer;
  //&:hover {
  //  background-color: rgba(103, 147, 255, 0.05);
  //}
  line-height: 40px;
  padding: 0 10px;
  font-size: ${({ theme }) => theme.defaults.fontSize}px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media screen and ${breakpoint.xs} {
    font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  }

  &.full-report {
    background: ${({ theme }) => theme.colors.navbar};

    @media screen and ${breakpoint.md} {
      background: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const HeartIcon = styled(Heart)`
  margin: auto 0 auto 10px;
  fill: ${({ theme }) => theme.colors.white};

  &.filled {
    fill: ${({ theme }) => theme.colors.secondary};
  }
`;

export const ExcelIcon = styled(Excel)`
  width: 22px;
  height: 22px;
  margin: auto 0 auto 10px;
  fill: ${({ theme }) => theme.colors.secondary};
`;

export const StyledTooltip = styled(ReactTooltip)`
  max-width: 40vh;
  text-align: left;
  white-space: normal;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-top: 9px;
  margin-left: 5px;
`;

// mobile specific styles

export const Line = styled.hr`
  height: 2px;
  width: 0;
  border-color: ${({ theme }) => theme.colors.primary};
  visibility: hidden;
  transition: all 0.3s;
  &.active {
    width: 100%;
    visibility: visible;
  }
`;

export const Label = styled.div`
  line-height: 20px;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  height: 40px;
  width: 100%;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.primary};
  @media screen and ${breakpoint.xs} {
    font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  }
`;

export const Tab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  width: 25%;
  margin-bottom: 20px;
  color: red;
`;
export const TabList = styled.div`
  display: flex;
  width: 100%;
`;

export const TabPanel = styled.div`
  display: ${({ index, currentIndex }) =>
    index === currentIndex ? 'flex' : 'none'};
  flex-direction: column;
`;
