import styled from '@emotion/styled';

export const MailTo = styled.a`
  color: ${({ theme }) => theme.font.default};
  :hover {
    text-decoration: none;
    opacity: 0.8;
  }
`;

export const Spacer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: ${({ theme }) => theme.defaults.fontSizeXXL}px;
  line-height: 22px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`;
export const CancelButton = styled.button`
  width: 100%;
  font-weight: 700;
  font-size: ${({ theme }) => theme.defaults.fontSizeXL}px;
  max-width: 200px;
  border-radius: 40px;
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  height: 50px;
  cursor: pointer;

  &:hover {
    background-color: rgba(103, 147, 255, 0.05);
  }
  &.max-content {
    width: max-content;
  }
`;
