/* eslint-disable */
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { toggle, hide } from 'slidetoggle';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';
import routes, { MY_NEWS_TYPES } from '@routes';
import authStore from '@root/stores/authStore';
import { history } from '@app/history';

const PARENT_PATHS = ['my-news', 'briefings', 'companies'];

export class SidebarStore {
  expandedItem = '';

  submenusItems = {
    myNews: [
      { label: 'Following', path: routes.myNews(MY_NEWS_TYPES.FOLLOWING) },
      { label: 'My Briefings', path: routes.myNews(MY_NEWS_TYPES.BRIEFINGS) },
      { label: 'My Coverage', path: routes.myNews(MY_NEWS_TYPES.COVERAGES) },
    ],
    sectors: [],
    briefingTypes: [],
  };

  menuCollapsed = false;

  mobileMenuOpened = false;

  isLoading = false;

  briefingTypes = [];

  searchLastPath = null;

  lastPlaceholder = null;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => ({
        isAuthenticated: authStore.isAuthenticated,
      }),
      this.fetchMenuItems,
    );
    if (authStore.isAuthenticated) {
      this.fetchMenuItems();
    }
  }

  setSearchLastPath = (value) => {
    this.searchLastPath = value;
  };

  setLastPlaceholder = (value) => {
    this.lastPlaceholder = value;
  };

  autoOpen = (pathname) => {
    PARENT_PATHS.forEach((key) => {
      if (pathname.includes(`${key}/`) && this.expandedItem !== key) {
        this.expandedItem = key;
      }
    });
  };

  toggleSubmenu = (key, { current: submenu }) => {
    if (this.menuCollapsed) {
      this.menuCollapsed = false;
    }
    this.hideAllSubmenus();

    if (this.expandedItem !== key) {
      toggle(submenu, {
        miliseconds: 300,
        transitionFunction: 'ease-in',
        elementDisplayStyle: 'block',
      });
      this.expandedItem = key;
    } else {
      this.expandedItem = '';
    }
  };

  setMenuCollapsed = (isCollapsed) => {
    this.menuCollapsed = isCollapsed;
    if (isCollapsed) {
      this.expandedItem = '';
      this.hideAllSubmenus();
    }
  };

  hideAllSubmenus = () => {
    const submenus = document.querySelectorAll('[data-toggable]');
    Array.prototype.forEach.call(submenus, (submenu) => {
      hide(submenu, {
        miliseconds: 300,
        transitionFunction: 'ease-in',
        elementDisplayStyle: 'block',
      });
    });
  };

  setMobileMenuOpened = (isOpened) => {
    this.mobileMenuOpened = isOpened;
    if (isOpened) {
      document.querySelector('body').classList.add('scroll-lock');
    } else {
      document.querySelector('body').classList.remove('scroll-lock');
    }
  };

  fetchMenuItems = async () => {
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      if (
        !this.submenusItems.sectors.length ||
        !this.submenusItems.briefingTypes.length
      ) {
        const {
          data: { items: sectors },
        } = await API.get(apiRoutes.sectors);

        const sectorsList = [
          { id: 'following', name: 'Following' },
          { id: 'all', name: 'All Companies' },
          ...sectors,
        ];

        const {
          data: { items: briefingTypes },
        } = await API.get(apiRoutes.briefingTypes);

        const briefingTypesList = [
          { id: 'all', name: 'Select Briefings' },
          ...briefingTypes,
        ];

        runInAction(() => {
          this.briefingTypes = briefingTypes;
          this.submenusItems = {
            ...this.submenusItems,
            briefingTypes: briefingTypesList.map(({ id, name }) => ({
              contentContainerId: id,
              label: name,
              path: `/briefings/${id}`,
            })),
            sectors: sectorsList.map(({ id, name }) => ({
              contentContainerId: id,
              label: name,
              path: `${routes.company(id)}`,
            })),
          };
        });
      }
    } catch (e) {
      console.error(e.message, e.stack);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  logout = async () => {
    this.isAuthenticated = false;
  };
}

export default new SidebarStore();
