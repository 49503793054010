import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useSortable } from '@dnd-kit/sortable';
import {
  ClearIconCompany,
  StyledCompanyButton,
} from '@components/CompaniesDialog/styles';

const CompanyButton = ({
  id,
  label,
  companyColor,
  showXIcon,
  dashboardStore: { toggleCompany, dialogSelectedCompanies },
}) => {
  const selected = dialogSelectedCompanies.has(id);
  const { attributes, listeners, setNodeRef } = useSortable({
    id,
  });
  return (
    <StyledCompanyButton
      onClick={() => (showXIcon ? null : toggleCompany(id))}
      selected={selected}
      companyColor={companyColor}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {label}
      {selected && showXIcon ? (
        <ClearIconCompany onClick={() => toggleCompany(id)} />
      ) : null}
    </StyledCompanyButton>
  );
};

CompanyButton.propTypes = {
  id: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  companyColor: PropTypes.string.isRequired,
  dashboardStore: PropTypes.shape({
    toggleCompany: PropTypes.func.isRequired,
  }).isRequired,
};

export default inject('dashboardStore')(observer(CompanyButton));
