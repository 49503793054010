import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Navigate, useLocation } from 'react-router-dom';
import routes from '@routes';

const ProtectedRoute = inject('authStore')(
  observer(({ authStore, children }) => {
    const location = useLocation();

    if (authStore.isAuthenticated) {
      return children;
    }

    return (
      <Navigate to={routes.auth.signIn} state={{ from: location }} replace />
    );
  }),
);

ProtectedRoute.propTyose = {
  authStore: PropTypes.shape({ isAuthenticated: PropTypes.bool }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ProtectedRoute;
