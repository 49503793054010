import styled from '@emotion/styled';
import briefcaseB from '@assets/images/briefcase-b.svg';

export const PageContainer = styled.div`
  display: flex;
  background: rgb(41, 35, 92);
  background: url(${briefcaseB}) no-repeat 0 0 / contain,
    linear-gradient(
      45deg,
      rgba(41, 35, 92, 1) 42%,
      rgba(51, 53, 118, 1) 79%,
      rgba(69, 86, 166, 1) 100%
    );
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 100vh;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-height: 688px;
  max-width: 640px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -2px 8px 4px rgba(41, 35, 92, 0.03);
  position: relative;
  z-index: 2;
`;

export const Logo = styled.img`
  height: 158px;
  width: 197px;
  margin: 10% 0px 24px 0px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 416px;
  width: 90%;

  & .custom-input-label {
    color: ${({ theme }) => theme.colors.primary} !important;
  }

  input {
    background-color: ${({ theme }) => theme.colors.white} !important;
  }

  button {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const SubmitContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const StyledSubmit = styled.button`
  box-sizing: border-box;
  width: 100%;
  height: 42px;
  font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  font-size: ${({ theme }) => theme.defaults.mediumFontSize}px;
  font-weight: 700;
  border: 0;
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  border-radius: 3px;

  :disabled {
    filter: saturate(0);
  }
`;

export const Header = styled.h2`
  font-weight: ${({ theme }) => theme.defaults.fontWeight.bold};
  font-size: ${({ theme }) => theme.defaults.fontSizeXXL}px;
  line-height: 33px;
  color: ${({ theme }) => theme.colors.primary};
  align-self: center;
  margin-bottom: 20px;
`;
