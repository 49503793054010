import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import {
  SidebarContainer,
  LogoContainer,
  LogoIcon,
  LogoText,
  ScrollableContent,
} from '@components/SideBar/styles';
import logo from '@app/assets/logo.svg';
import MenuItem from '@components/SideBar/components/MenuItem';
import MenuItemToggable from '@components/SideBar/components/MenuItemToggable';
import OpenCloseMenu from '@components/SideBar/components/OpenCloseMenu';

const DesktopSideBar = ({
  menuItems,
  sidebarStore: { autoOpen, menuCollapsed, setMenuCollapsed },
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    autoOpen(pathname);
  }, [pathname]);

  return (
    <SidebarContainer menuCollapsed={menuCollapsed}>
      <LogoContainer>
        <LogoIcon>
          <img alt="logo" src={logo} />
        </LogoIcon>
        <LogoText>Briefcase.news</LogoText>
      </LogoContainer>

      <ScrollableContent autoHeight autoHeightMin={300} autoHeightMax="100%">
        {menuItems.map(({ label, key, icon, path, submenuItemsKey }) =>
          path ? (
            <MenuItem
              key={`menu-item_${key}`}
              path={path}
              label={label}
              icon={icon}
              parent={key}
            />
          ) : (
            <MenuItemToggable
              key={`menu-item_${key}`}
              label={label}
              submenuItemsKey={submenuItemsKey}
              icon={icon}
              parent={key}
            />
          ),
        )}
        <OpenCloseMenu
          setMenuCollapsed={setMenuCollapsed}
          menuCollapsed={menuCollapsed}
        />
      </ScrollableContent>
    </SidebarContainer>
  );
};

DesktopSideBar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      icon: PropTypes.any,
      submenuItemsKey: PropTypes.string,
    }),
  ).isRequired,
  sidebarStore: PropTypes.shape({
    autoOpen: PropTypes.func,
    menuCollapsed: PropTypes.bool,
    setMenuCollapsed: PropTypes.func,
  }).isRequired,
};

export default inject('sidebarStore')(observer(DesktopSideBar));
