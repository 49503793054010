import React, { useState, useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { useForm, Controller } from 'react-hook-form';
import apiRoutes from '@app/apiRoutes';
import API from '@app/api';

import {
  SectionContainer,
  NotificationsForm,
} from '@containers/Settings/styles';

import Switch from '@components/Switch';

const Notifications = ({
  userStore: { user },
  settingsStore: { handleChangeNotification },
}) => {
  const [id, setId] = useState(null);
  const firstRender = useRef(true);
  const { handleSubmit, control, setValue } = useForm();

  const changeStatus = async (data) => {
    await API.post(apiRoutes.updateMe, data);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      changeStatus(id);
    }
  }, [id]);

  const checkId = (i, value) => {
    setId({ [keys[i].key]: !value });
    handleChangeNotification(keys[i].key, value);
  };

  useEffect(() => {
    setValue('allEmailNotificationsEnabled', user.allEmailNotificationsEnabled);
    setValue('allPushNotificationsEnabled', user.allPushNotificationsEnabled);
  }, [user]);

  const keys = [
    {
      key: 'allEmailNotificationsEnabled',
      label: 'All email notifications',
    },
    {
      key: 'allPushNotificationsEnabled',
      label: 'All push notifications',
    },
  ];

  return (
    <SectionContainer>
      <NotificationsForm>
        {keys.map((keyName, i) => (
          <Controller
            key={keyName.key}
            name={keyName.key}
            defaultValue={false}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Switch
                onChange={onChange}
                checkId={() => checkId(i, value)}
                label={keyName.label}
                handleSubmit={handleSubmit}
                name={name}
                value={value}
              />
            )}
          />
        ))}
      </NotificationsForm>
    </SectionContainer>
  );
};

export default inject('userStore', 'settingsStore')(observer(Notifications));
